import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import Modal from "@/components/Modal";
import Spinner from "@/components/Spinner";

export default function GameConnecting({ className, ...props }) {
	return (
		<Modal
			className={tailwindCascade(
				"bg-black md:bg-opacity-0 bg-opacity-80 md:mt-11 mt-9 md:items-end md:justify-end",
				className
			)}
		>
			<div className="md:bg-opacity-70 md:px-4 md:py-4 relative flex flex-row items-center justify-center p-0 space-x-4 bg-black bg-opacity-0 rounded-md">
				<Spinner className="w-8 h-8 border-[0.3334rem] md:w-6 md:h-6 md:border-[0.25rem] " />
				<div className="whitespace-nowrap md:text-base text-xl font-black text-white">
					{trans("Connecting...")}
				</div>
			</div>
		</Modal>
	);
}
