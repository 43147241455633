import { formatPoints } from "@/helpers/stringHelper";
import trans from "@/helpers/trans";
import Header from "@/components/Header";

function nth(n) {
	switch (((((n + 90) % 100) - 10) % 10) - 1) {
		case 0:
			return trans("%dst", n);
		case 1:
			return trans("%dnd", n);
		case 2:
			return trans("%drd", n);
		default:
			return trans("%dth", n);
	}
}

export default function PlayerScore({ name, points, rank, teamName }) {
	const displayName = teamName ? `${name} (${teamName})` : name;

	return (
		<div className="h-14 flex-grow-0 w-full px-4 pt-4">
			<div className="flex flex-row justify-start w-full">
				<Header className="mr-4 text-xl font-black" clip={true}>
					{displayName}
				</Header>
				<Header className="text-xl font-black" clip={true}>
					{(isFinite(points) ? formatPoints(points) : "") +
						(isFinite(rank) && rank !== null ? ` (${nth(rank)})` : "")}
				</Header>
			</div>
		</div>
	);
}
