import { useEffect } from "react";

import Location from "@/components/pages/play/slides/Location";

import { sfx } from "@/helpers/audio";

import useViewportSize from "@/hooks/useViewportSize";

import { SLIDE_TYPE_LOCATION, SLIDE_TYPE_PINPOINT } from "@/app-constants.mjs";
import { PLAY_STATUS_FLUSH, PLAY_STATUS_SHOW_ANSWERS, PLAY_STATUS_SHOW_MEDIA } from "@/constants";

import DesktopSlide from "./DesktopSlide";
import MobileSlide from "./MobileSlide";
import PinpointSlide from "./slides/PinpointSlide";

export default function Slide(props) {
	const viewportSize = useViewportSize();

	useEffect(() => {
		// Some sounds should play on state changes
		switch (props?.statusWithProgress?.name) {
			case PLAY_STATUS_SHOW_MEDIA:
				sfx.play("imageAppear");
				break;
			case PLAY_STATUS_SHOW_ANSWERS:
				sfx.play("answersAppearing");
				break;
			case PLAY_STATUS_FLUSH:
				sfx.stop();
				break;
		}
	}, [props?.statusWithProgress?.name]);

	if (!props.slide || !props.slide.id) {
		return <></>;
	} else if (props.slide.type === SLIDE_TYPE_LOCATION) {
		return <Location {...props} />;
	} else if (props.slide.type === SLIDE_TYPE_PINPOINT) {
		return <PinpointSlide {...props} />;
	} else if (viewportSize.isDesktop) {
		return <DesktopSlide {...props} />;
	} else {
		return <MobileSlide {...props} />;
	}
}
