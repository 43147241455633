import React, { memo, useEffect, useMemo } from "react";
import isEqual from "lodash/isEqual";
import SlideMedia from "@/components/pages/play/SlideMedia";
import FunFactMedia from "@/components/pages/play/FunFactMedia";
import { SLIDE_TYPE_CLASSIC, SLIDE_TYPE_REORDER, SLIDE_TYPE_TYPE_ANSWER } from "@/app-constants.mjs";
import TextVoice from "../TextVoice";
import isArray from "lodash/isArray";

function Prefetch({ slide, isHost, voiceOverride }) {
	let answerText = useMemo(() => {
		if (isHost && isArray(slide?.answers)) {
			if (slide.type === SLIDE_TYPE_CLASSIC || slide.type === SLIDE_TYPE_TYPE_ANSWER) {
				const filtered = slide.answers.filter((answer) => answer.isCorrect);
				if (filtered.length > 0) {
					return filtered[0].text;
				}
			} else if (slide.type === SLIDE_TYPE_REORDER) {
				return slide?.answers.map((ans) => ans.text);
			}
		}
		return null;
	}, [slide, isHost]);

	// useEffect(() => void console.log({ answerText }), [answerText]);

	return (
		<div className="sr-only">
			{slide?.media && <SlideMedia {...slide.media} prefetch={true} />}
			{slide?.funFactMedia && <FunFactMedia {...slide.funFactMedia} prefetch={true} />}
			{slide && isHost && slide?.question && (
				<TextVoice
					isHost={isHost}
					slideId={slide.id}
					play={false}
					savedVoice={slide.questionVoice}
					text={slide.question}
					voiceOverride={voiceOverride}
				/>
			)}
			{answerText && (
				<TextVoice
					isHost={isHost}
					slideId={slide.id}
					play={false}
					savedVoice={slide.answerVoice}
					text={answerText}
					voiceOverride={voiceOverride}
				/>
			)}
		</div>
	);
}

export default memo(Prefetch, isEqual);
