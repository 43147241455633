import { useCallback, useEffect, useRef, useState, memo } from "react";
import useDynamicRefArray from "@/hooks/useDynamicRefArray";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import CorrectIcon from "@/images/icons/icon-correct-multicolor.svg";
import Button, { BUTTON_RADIUS } from "@/components/interactives/Button";
import {
	MAX_ANSWER_LENGTH,
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
	voiceNameToLanguageCode,
} from "@/app-constants.mjs";
import trans from "@/helpers/trans";
import Header from "@/components/Header";
import Input from "@/components/interactives/Input";
import isBoolean from "lodash/isBoolean";
import useConfetti from "../../../hooks/useConfetti";
import { ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE } from "@/colors";
import { formatAnswerString } from "@/helpers/stringHelper";
import TextVoice from "../TextVoice";
import TypeAnswerFragment from "./slides/TypeAnswerFragment";
import isEqual from "lodash/isEqual";
import RangeAnswersFragment from "./slides/RangeAnswersFragment";
import capitalize from "lodash/capitalize";
import isString from "lodash/isString";
import { upperFirst } from "@/helpers/text";
import ReorderAnswersFragment from "./slides/ReorderAnswersFragment";
import CheckboxAnswersFragment from "./slides/CheckboxAnswersFragment";

const BUTTON_COLOR_ORDER = ["answer1", "answer2", "answer3", "answer4"];

export default function MobileAnswers({
	answers,
	className,
	doublePoints,
	haveLocalPlayer,
	onAnswer,
	paused,
	setPaused,
	setPlaylistSkip,
	setSlideTypeAnswer,
	showAnswers,
	showCorrectAnswers,
	slide,
	slideIndex,
	slideType,
	statusWithProgress,
	submittedAnswer,
	typeAnswerState,
	waitForAnswer,
	slideRef,
	slideId,
	answerVoice,
	isHost,
	voiceOverride,
	players,
	reducedMotion,
	noAnimation,
	teams,
	teamMode,
}) {
	const [answerText, setAnswerText] = useState(answers ? formatAnswerString(slideType, answers) : null);

	useEffect(() => {
		setAnswerText(answers ? formatAnswerString(slideType, answers) : null);
	}, [slideType, answers]);

	switch (slideType) {
		default:
			console.error("unsupported slide type", slideType);
			return <div></div>;
		case SLIDE_TYPE_CLASSIC:
			return (
				<>
					<TextVoice
						isHost={isHost}
						slideId={slideId}
						play={showCorrectAnswers}
						savedVoice={answerVoice}
						text={answerText}
						voiceOverride={voiceOverride}
					/>
					<MemorizedClassicFragment
						answers={answers}
						className={className}
						haveLocalPlayer={haveLocalPlayer}
						onAnswer={onAnswer}
						showAnswers={showAnswers}
						showCorrectAnswers={showCorrectAnswers}
						slideIndex={slideIndex}
						slideRef={slideRef}
						slideType={slideType}
						submittedAnswer={submittedAnswer}
						active={showAnswers}
						reducedMotion={reducedMotion}
						noAnimation={noAnimation}
						language={voiceNameToLanguageCode(slide.answerVoice)}
					/>
				</>
			);

		case SLIDE_TYPE_CHECK_BOXES:
			return (
				<>
					<TextVoice
						isHost={isHost}
						slideId={slideId}
						play={showCorrectAnswers}
						savedVoice={answerVoice}
						text={answerText}
						voiceOverride={voiceOverride}
					/>
					<CheckboxAnswersFragment
						active={showAnswers}
						className={className}
						haveLocalPlayer={haveLocalPlayer}
						language={voiceNameToLanguageCode(slide.answerVoice)}
						onAnswer={onAnswer}
						paused={paused}
						reducedMotion={reducedMotion}
						showAnswers={showAnswers}
						showCorrectAnswers={showCorrectAnswers}
						slide={slide}
						slideIndex={slideIndex}
						slideRef={slideRef}
						slideType={slideType}
						statusWithProgress={statusWithProgress}
						submittedAnswer={submittedAnswer}
						visible={showAnswers}
						noAnimation={noAnimation}
					/>
				</>
			);

		case SLIDE_TYPE_TYPE_ANSWER:
			return (
				<>
					<TextVoice
						isHost={isHost}
						slideId={slideId}
						play={showCorrectAnswers}
						savedVoice={answerVoice}
						text={answerText}
						voiceOverride={voiceOverride}
					/>

					<TypeAnswerFragment
						answers={answers}
						disabled={typeAnswerState.isCorrect || !showAnswers}
						haveLocalPlayer={haveLocalPlayer}
						isClose={typeAnswerState.isClose}
						isCorrect={typeAnswerState.isCorrect}
						isNumeric={typeAnswerState.isNumeric}
						onAnswer={onAnswer}
						setValue={setSlideTypeAnswer}
						showCorrectAnswers={showCorrectAnswers}
						slideIndex={slideIndex}
						value={typeAnswerState.value}
						visible={showAnswers}
					/>
				</>
			);

		case SLIDE_TYPE_REORDER:
			return (
				<ReorderAnswersFragment
					className={className}
					isHost={isHost}
					language={voiceNameToLanguageCode(slide.answerVoice)}
					onAnswer={onAnswer}
					paused={paused}
					haveLocalPlayer={haveLocalPlayer}
					showCorrectAnswers={showCorrectAnswers}
					slide={slide}
					slideIndex={slideIndex}
					statusWithProgress={statusWithProgress}
					submittedAnswer={submittedAnswer}
					visible={showAnswers}
					voiceOverride={voiceOverride}
					noAnimation={noAnimation}
				/>
			);

		case SLIDE_TYPE_RANGE:
			return (
				<RangeAnswersFragment
					answers={answers}
					className={className}
					doublePoints={doublePoints}
					disabled={typeAnswerState.isCorrect || !showAnswers}
					haveLocalPlayer={haveLocalPlayer}
					isCorrect={typeAnswerState.isCorrect}
					isNumeric={typeAnswerState.isNumeric}
					onAnswer={onAnswer}
					setValue={setSlideTypeAnswer}
					showCorrectAnswers={showCorrectAnswers}
					slide={slide}
					slideIndex={slideIndex}
					value={typeAnswerState.value}
					visible={showAnswers}
					statusWithProgress={statusWithProgress}
					players={players}
					isHost={isHost}
					voiceOverride={voiceOverride}
					submittedAnswer={submittedAnswer}
					reducedMotion={reducedMotion}
					teamMode={teamMode}
					teams={teams}
				/>
			);
	}
}

function ClassicFragment({
	answers,
	className,
	haveLocalPlayer,
	mute,
	onAnswer,
	showAnswers,
	showCorrectAnswers,
	slideIndex,
	slideRef,
	slideType,
	submittedAnswer,
	active,
	reducedMotion,
	noAnimation,
	language,
}) {
	const [answersRef, setAnswerRef] = useDynamicRefArray();

	const reducedMotionRef = useRef(reducedMotion);
	useEffect(() => void (reducedMotionRef.current = reducedMotion), [reducedMotion]);

	const showConfetti = useConfetti({
		particleCount: 200,
		angle: 90,
		spread: 360,
		startVelocity: 21,
		decay: 0.8,
		gravity: 0.4,
		drift: 0,
		ticks: 200,
		colors: [ANSWER1, ANSWER2, ANSWER3, ANSWER4, WHITE],
		shapes: ["square", "square", "square", "circle"],
		scalar: 4.5,
		mute: mute,
	});
	const showConfettiRef = useRef(showConfetti);
	useEffect(() => void (showConfettiRef.current = showConfetti), [showConfetti]);

	useEffect(() => {
		if (showCorrectAnswers && answersRef.current) {
			for (let i = 0; i < answersRef.current.length; i++) {
				const answerRef = answersRef.current[i];
				const [answerElement, answerIndex, numAnswers, answerIsCorrect] = answerRef.current;
				if (
					answerElement &&
					answerIsCorrect &&
					(!haveLocalPlayer || (haveLocalPlayer && submittedAnswer === i))
				) {
					if (!reducedMotionRef.current && showConfettiRef.current) {
						showConfettiRef.current(slideRef.current, answerElement);
					}
				}
			}
		}
	}, [answersRef, haveLocalPlayer, showCorrectAnswers, slideRef, submittedAnswer]);

	if (answers && slideType === SLIDE_TYPE_CLASSIC) {
		const correctAnswerIndex = answers?.findIndex(({ isCorrect }) => isCorrect);
		return (
			<div
				className={tailwindCascade(
					"gap-1",
					"flex",
					"flex-col",
					"justify-center",
					"w-full max-w-md mx-auto",
					{
						"pointer-events-none": !active,
					},
					className
				)}
			>
				{answers.map((answer, answerIndex, answers) => (
					<div key={answerIndex} className="w-full">
						<div
							className="relative flex flex-row justify-center origin-center"
							ref={(element) =>
								setAnswerRef(
									answerIndex,
									[element, answerIndex, answers.length, answer.isCorrect],
									answers.length
								)
							}
						>
							<Button
								onClick={() => {
									if (onAnswer && submittedAnswer === null) {
										onAnswer(slideIndex, answerIndex);
									}
								}}
								border={true}
								color={BUTTON_COLOR_ORDER[answerIndex % BUTTON_COLOR_ORDER.length]}
								className={tailwindCascade(
									"w-full transition-opacity",
									{
										"opacity-50":
											(submittedAnswer !== null && submittedAnswer !== answerIndex) ||
											(haveLocalPlayer && submittedAnswer === null && showCorrectAnswers) ||
											(!haveLocalPlayer && showCorrectAnswers && !answer.isCorrect),
										"opacity-0": !showAnswers && !showCorrectAnswers,
										"animate-media": showAnswers && !noAnimation,
									},
									"leading-none"
								)}
								style={{
									animationDelay: `${
										((1 - 0.64) / answers.length) * (answerIndex + 1)
									}s` /* +1 because zero delay doesn't seem to play well with ios */,
									animationFillMode: "backwards",
								}}
							>
								{upperFirst(answer.text, language)}
							</Button>
							{haveLocalPlayer && showCorrectAnswers && correctAnswerIndex === answerIndex && (
								<div className="top-1/2 translate-x-1/4 absolute right-0 z-10 p-1 overflow-hidden transform -translate-y-1/2 bg-black rounded-full">
									<CorrectIcon className="fill-white w-8 h-8 text-black" />
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		);
	}

	return null;
}

const MemorizedClassicFragment = memo(ClassicFragment, isEqual);
