import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useRouter } from "next/router";

import Button from "@/components/interactives/Button";
import { FinalReport } from "@/components/pages/play/FinalReport";
import { WinnerView } from "@/components/pages/play/Winner";

import { sfx } from "@/helpers/audio";
import isApp from "@/helpers/isApp";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useRefMounted from "@/hooks/useRefMounted";
import useStopHosting from "@/hooks/useStopHosting";

import { PLAY_STATUS_RATE_DONE, PLAY_STATUS_SHOW_RATE, PLAY_STATUS_SHOW_WINNER } from "@/constants";

export default function GameEnd({
	leaderboard,
	onComplete,
	onLoad,
	sendPlayerPointsAndPos,
	onWinnerRevealed,
	players,
	quickMode,
	isHost = false,
	statusWithProgress,
	setCanRate,
	routeChangeStart,
	reducedMotion,
	hidePlayerCountry = false,
	quiz,
	report,
	...props
}) {
	const [, mountedRef] = useRefMounted();

	const router = useRouter();
	const routerRef = useRef(router);
	useEffect(() => void (routerRef.current = router), [router]);

	const stopHosting = useStopHosting();
	const stopHostingRef = useRef(stopHosting);
	useEffect(() => void (stopHostingRef.current = stopHosting), [stopHosting]);

	const setCanRateRef = useRef(setCanRate);
	useEffect(() => void (setCanRateRef.current = setCanRate), [setCanRate]);

	const onWinnerRevealedRef = useRef(onWinnerRevealed);
	useEffect(() => void (onWinnerRevealedRef.current = onWinnerRevealed), [onWinnerRevealed]);

	useEffect(() => void onLoad && onLoad(), [onLoad]);

	const routeChangeStartRef = useRef(routeChangeStart);
	useEffect(() => void (routeChangeStartRef.current = routeChangeStart), [routeChangeStart]);

	const [showReport, setShowReport] = useState(false);

	const onClickViewReport = useCallback(() => setShowReport(true), []);
	const onClickHideReport = useCallback(() => setShowReport(false), []);

	const onCompleteRef = useRef(onComplete);
	useEffect(() => void (onCompleteRef.current = onComplete), [onComplete]);

	const onDone = useCallback(() => {
		if (routerRef.current) {
			if (isApp) {
				if (onCompleteRef.current) {
					onCompleteRef.current();
				}
			} else {
				if (routeChangeStartRef.current) {
					routerRef.current.events.off("routeChangeStart", routeChangeStartRef.current);
				}
				routerRef.current.push("/play/");
			}
		}
	}, []);

	const onRate = useCallback(() => {
		if (setCanRateRef.current) {
			setCanRateRef.current(true);
		}
		if (onWinnerRevealedRef.current) {
			onWinnerRevealedRef.current();
		}
	}, []);

	return (
		<>
			{[PLAY_STATUS_SHOW_WINNER, PLAY_STATUS_SHOW_RATE, PLAY_STATUS_RATE_DONE].includes(
				statusWithProgress.name
			) && (
				<>
					{report &&
						showReport &&
						[PLAY_STATUS_SHOW_RATE, PLAY_STATUS_RATE_DONE].includes(statusWithProgress.name) && (
							<div className="right-4 bottom-8 fixed">
								<Button
									color="green-light"
									className="lg:px-8 lg:py-3 px-4 py-2 text-white"
									border={true}
									onClick={onClickHideReport}
								>
									{trans("View leaderboard")}
								</Button>
							</div>
						)}

					{!showReport ? (
						<WinnerView
							isHost={isHost}
							leaderboard={leaderboard}
							onRate={onRate}
							sendPlayerPointsAndPos={sendPlayerPointsAndPos}
							onReport={onClickViewReport}
							onDone={onDone}
							reducedMotion={reducedMotion}
							hidePlayerCountry={hidePlayerCountry}
							statusWithProgress={statusWithProgress}
							report={report}
						/>
					) : (
						<div className="md:pt-12 flex flex-row items-center justify-center pt-0">
							<FinalReport className="pt-4" quiz={quiz} report={report} />
						</div>
					)}
				</>
			)}
		</>
	);
}
