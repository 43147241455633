import React, { useState, useEffect, useRef, useCallback, memo, useMemo } from "react";
import { useImmer } from "use-immer";
import ScrollContainer from "react-indiana-drag-scroll";

import useRefMounted from "@/hooks/useRefMounted";

import trans from "@/helpers/trans";
import gsap from "@/helpers/gsap";
import onWindowResize from "@/helpers/onWindowResize";
import { sfx } from "@/helpers/audio";

import usePlayStore from "@/stores/play";
import useWebSocketStore from "@/stores/webSocket";

import Avatar from "@/components/Avatar";
import { isPlayerConnected } from "@/helpers/player";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import useSettingsStore from "@/stores/settings";
import Header from "@/components/Header";
import SyncedAnimatedDots from "@/components/SyncedAnimatedDots";
import AvatarIcon from "@/images/icons/icon-avatar-link.svg";

const RESET_SCROLL_TIMEOUT = 10000; // 10 sec
const AVATAR_SIZE = 128;
const SCROLL_SNAP_WIDTH = 16;

function Player({ player, isHost = false, reducedMotion = false, animateStartPosition = 0 }) {
	const [mounted] = useRefMounted();

	const ref = useRef(null);

	const checkmark = useMemo(() => (player ? (player.created ? true : undefined) : undefined), [player]);

	const onLoad = useCallback(() => {
		//
	}, []);

	const banPlayerOnKick = useSettingsStore((state) => state.banPlayerOnKick);
	const removePlayer = usePlayStore((state) => state.removePlayer);

	const onKick = useCallback(() => {
		if (player && player.connectionId) {
			removePlayer(player.connectionId);
			const { sendRoomMessage } = useWebSocketStore.getState();
			const roomMessage = banPlayerOnKick
				? { ban: { connectionId: player.connectionId } }
				: { kick: { connectionId: player.connectionId } };
			sendRoomMessage(roomMessage, player.connectionId);
		}
	}, [banPlayerOnKick, player, removePlayer]);

	const animateStartPositionRef = useRef(animateStartPosition);
	useEffect(() => void (animateStartPositionRef.current = animateStartPosition), [animateStartPosition]);

	useEffect(() => {
		if (mounted && ref.current) {
			gsap.fromTo(ref.current, { opacity: 0 }, { opacity: 1, duration: reducedMotion ? 0 : 0.25 });
			gsap.fromTo(
				ref.current,
				{ opacity: 1, x: animateStartPositionRef.current },
				{ x: 0, duration: reducedMotion ? 0 : 0.5 }
			);
		}
	}, [mounted, reducedMotion]);

	const tooltipRef = useRef(null);
	const tooltipTweenRef = useRef(null);
	const onMouseEnter = useCallback(() => {
		sfx.play("hoverFeedback", false, 0.75);

		if (tooltipTweenRef.current) {
			tooltipTweenRef.current.kill();
			tooltipTweenRef.current = null;
		}
		if (tooltipRef.current) {
			tooltipTweenRef.current = gsap.to(tooltipRef.current, {
				autoAlpha: 1,
				duration: reducedMotion ? 0 : 0.25,
				delay: reducedMotion ? 0 : 0.25,
				onComplete: () => void (tooltipTweenRef.current = null),
			});
		}
	}, [reducedMotion]);
	const onMouseLeave = useCallback(() => {
		if (tooltipTweenRef.current) {
			tooltipTweenRef.current.kill();
			tooltipTweenRef.current = null;
		}
		if (tooltipRef.current) {
			gsap.to(tooltipRef.current, {
				autoAlpha: 0,
				duration: reducedMotion ? 0 : 0.125,
				onComplete: () => void (tooltipTweenRef.current = null),
			});
		}
	}, [reducedMotion]);

	return (
		<div ref={ref} className="pb-full relative w-full opacity-0" onMouseLeave={onMouseLeave}>
			<Avatar
				className="absolute w-full h-full"
				playerAvatar={player.avatar}
				playerName={player.name}
				playerPoints={player.previousPoints}
				playerConnectionId={player.connectionId}
				checkmark={checkmark}
				showName={true}
				isLobby={true}
				width={AVATAR_SIZE}
				height={AVATAR_SIZE}
				onLoad={onLoad}
				onMouseEnter={onMouseEnter}
			/>

			{isHost && (
				<div className="left-1/2 md:block absolute bottom-0 hidden">
					<div ref={tooltipRef} className="left-1/2 absolute opacity-0">
						<div className="w-3 h-3 rotate-45 -translate-x-1/2 translate-y-1 bg-black"></div>
						<div className="whitespace-nowrap px-3 py-1 text-white -translate-x-1/2 -translate-y-1 bg-black rounded-md">
							<button
								className="hover:underline font-white font-sans font-bold"
								onClick={() => onKick()}
								onMouseEnter={() => {
									sfx.play("hoverFeedback", false, 0.75);
								}}
							>
								{banPlayerOnKick ? trans("Ban player") : trans("Kick player")}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

function PlaceHolder({ index, numberOfVisiblePlayers }) {
	const ref = useRef(null);

	useEffect(() => {
		if (ref.current) {
			gsap.set(ref.current, {
				opacity: 1,
				width: `${(1 / numberOfVisiblePlayers) * 100}%`,
				xPercent: index * 100,
				yPercent: -50,
			});
		}
	}, [index, numberOfVisiblePlayers]);

	return (
		<div ref={ref} className="top-1/2 absolute transform opacity-0">
			<div className="pb-full relative w-full">
				<div className="absolute top-0 left-0 w-full h-full">
					<div className="relative w-full h-full p-4">
						<div className="bg-opacity-10 relative w-full h-full bg-white rounded-full" />
					</div>
				</div>
			</div>
		</div>
	);
}

function PlaceHolders({ onLocalJoin }) {
	return (
		<div className="absolute top-0 left-0 w-full h-full overflow-hidden opacity-100">
			<div className="relative flex flex-col items-center justify-center w-full h-full gap-2">
				<Header className="md:text-2xl text-xl">
					{trans("Waiting for players")}
					<SyncedAnimatedDots />
				</Header>
				<button className="group flex flex-row items-center" onClick={onLocalJoin}>
					<AvatarIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
					<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-normal leading-none text-center text-white text-opacity-50">
						{trans("Join on this device")}
					</div>
				</button>
			</div>
		</div>
	);
}

export default function LobbyPlayers({ players, onLocalJoin, isHost = false, reducedMotion = false }) {
	const scrollContainerRef = useRef(null);
	const playerWrapperRef = useRef(null);

	const [activePlayers, updateActivePlayers] = useImmer([]);
	const [state, updateState] = useImmer({
		numberOfVisiblePlayers: 0,
		playerAnimateStartPosition: 0,
	});
	const [scrollToEndState, updateScrollToEndState] = useImmer({ locked: true, lockTime: null });

	const onStartScroll = useCallback(
		(event) => {
			if (!event?.external) {
				updateScrollToEndState((draft) => void (draft.locked = true));
			}
		},
		[updateScrollToEndState]
	);

	const onEndScroll = useCallback(
		(event) => {
			if (!event?.external) {
				let snap = false;
				if (scrollContainerRef.current) {
					const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
					if (Math.abs(scrollLeft - (scrollWidth - clientWidth)) <= SCROLL_SNAP_WIDTH) {
						snap = true;
					}
				}

				updateScrollToEndState((draft) => {
					draft.locked = !snap;
					draft.lockTime = Date.now();
				});
			}
		},
		[updateScrollToEndState]
	);

	useEffect(() => {
		const resize = () => {
			let playerAnimateStartPosition = 0;
			let numberOfVisiblePlayers = 0;

			if (scrollContainerRef.current) {
				let { innerWidth } = window;

				numberOfVisiblePlayers = 5;
				if (innerWidth >= 1536) {
					numberOfVisiblePlayers = 8;
				} else if (innerWidth >= 1280) {
					numberOfVisiblePlayers = 7;
				} else if (innerWidth >= 1024) {
					numberOfVisiblePlayers = 7;
				} else if (innerWidth >= 768) {
					numberOfVisiblePlayers = 6;
				} else if (innerWidth >= 640) {
					numberOfVisiblePlayers = 6;
				}

				const numberOfActivePlayers = activePlayers.length;
				const { offsetWidth } = scrollContainerRef.current;

				const itemWidth = offsetWidth / numberOfVisiblePlayers;
				const itemHeight = innerWidth >= 640 ? (isHost ? itemWidth + 16 * 2 : itemWidth) : itemWidth;
				scrollContainerRef.current.style.setProperty("--item-width", `${itemWidth}px`);
				scrollContainerRef.current.style.setProperty("--item-height", `${itemHeight}px`);

				const containerWidth = Math.max(itemWidth * numberOfActivePlayers, offsetWidth);
				scrollContainerRef.current.style.setProperty("--container-width", `${containerWidth}px`);

				const itemX = Math.max((offsetWidth - itemWidth * numberOfActivePlayers) / 2, 0);
				scrollContainerRef.current.style.setProperty("--item-x", `${itemX}px`);

				playerAnimateStartPosition =
					Math.max(offsetWidth - itemX - itemWidth * numberOfActivePlayers, 0) + itemWidth;
			}

			updateState((draft) => {
				draft.numberOfVisiblePlayers = numberOfVisiblePlayers;
				draft.playerAnimateStartPosition = playerAnimateStartPosition;
			});
		};

		resize(); // Initial resize

		return onWindowResize(resize);
	}, [activePlayers, isHost, updateState]);

	useIsomorphicLayoutEffect(() => {
		updateActivePlayers((draft) => {
			for (const player of players.values()) {
				let found = false;
				for (let j = 0; j < draft.length; j++) {
					if (player.connectionId === draft[j].connectionId) {
						if (!isPlayerConnected(player)) {
							found = true;
							draft.splice(j, 1);
							break;
						} else {
							found = true;
							draft[j] = player;
							break;
						}
					}
				}

				if (!found && isPlayerConnected(player) && player.avatar) {
					draft.push(player);
				}
			}

			//Remove deleted players
			for (let i = draft.length - 1; i >= 0; i--) {
				if (!players.has(draft[i].connectionId)) {
					draft.splice(i, 1);
				}
			}
		});
	}, [players, updateActivePlayers]);

	const scrollToEnd = useCallback(() => {
		let locked = scrollToEndState.locked;
		if (locked && (!scrollToEndState.lockTime || Date.now() - scrollToEndState.lockTime > RESET_SCROLL_TIMEOUT)) {
			locked = false;
			updateScrollToEndState((draft) => {
				draft.locked = false;
				draft.lockTime = null;
			});
		}

		if (scrollContainerRef.current && !locked) {
			const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
			const target = { scrollLeft };
			const tween = gsap.to(target, {
				scrollLeft: scrollWidth - clientWidth,
				onUpdate: () => {
					if (scrollContainerRef.current) {
						scrollContainerRef.current.scrollLeft = target.scrollLeft;
					}
				},
			});

			return () => void tween.kill();
		}
	}, [scrollToEndState.lockTime, scrollToEndState.locked, updateScrollToEndState]);
	const scrollToEndRef = useRef(scrollToEnd);
	useEffect(() => void (scrollToEndRef.current = scrollToEnd), [scrollToEnd]);

	useEffect(() => scrollToEndRef.current && scrollToEndRef.current(), [activePlayers]);

	return (
		<ScrollContainer
			innerRef={scrollContainerRef}
			className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white-50 relative w-full overflow-y-hidden"
			vertical={false}
			horizontal={true}
			hideScrollbars={true}
			onStartScroll={onStartScroll}
			onEndScroll={onEndScroll}
		>
			<div
				ref={playerWrapperRef}
				className="whitespace-nowrap relative w-0 h-0 overflow-hidden select-none"
				style={{
					width: "var(--container-width)",
					height: "var(--item-height)",
				}}
			>
				{activePlayers.map((player, index) => (
					<div
						key={player.connectionId}
						className={tailwindCascade("relative", "inline-block", {
							"transition-transform duration-500": !reducedMotion,
						})}
						style={{
							transform: "translateX(var(--item-x))",
							width: "var(--item-width)",
							height: "var(--item-height)",
						}}
					>
						<div className="relative w-full h-full">
							<div className="top-2 left-2 right-2 bottom-2 absolute">
								<div className="relative w-full h-full">
									<Player
										player={player}
										isHost={isHost}
										animateStartPosition={state.playerAnimateStartPosition}
										reducedMotion={reducedMotion}
									/>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			{activePlayers.length === 0 && <PlaceHolders onLocalJoin={onLocalJoin} />}
		</ScrollContainer>
	);
}
