import { tailwindCascade } from "@/helpers/tailwindCascade";
import useRefMounted from "@/hooks/useRefMounted";
import Header from "../Header";
import TextToSpeech from "./TextToSpeech";
import trans from "@/helpers/trans";
import MediaView from "@/components/MediaView";

function getFriendlyModelName(generateModel) {
	if (generateModel) {
		if (generateModel.indexOf("gpt-3.5-") === 0) {
			return trans("ChatGPT 3.5");
		}
		if (generateModel.indexOf("gpt-4-") === 0) {
			return trans("ChatGPT 4");
		}
	}
	return trans("ChatGPT");
}

function getModelUpdate(generateModel) {
	if (generateModel) {
		if (generateModel.indexOf("gpt-3.5-") === 0) {
			return trans("Sep 2021");
		}
		if (generateModel.indexOf("gpt-4-") === 0) {
			return trans("Apr 2023");
		}
	}
	return trans("Sep 2021");
}

export default function AIDisclaimer({ quiz, visible, ...props }) {
	const [mounted] = useRefMounted();

	return quiz ? (
		<div
			className={tailwindCascade(
				"absolute flex flex-col items-center justify-center w-full h-full gap-4 p-2 text-center"
			)}
		>
			<Header className="md:text-4xl text-2xl">{quiz.name}</Header>

			<div className="md:max-w-xl flex flex-col w-full max-w-sm">
				<MediaView media={quiz.media} width={688} height={516} className="w-full"></MediaView>
				<img
					src="/images/icons/icon-label-ai-generated.svg"
					alt={trans("AI Generated")}
					className="md:h-16 md:pb-6 z-1 md:-my-4 block w-auto h-10 pb-3 -my-4"
				/>
			</div>

			<div className="flex flex-col w-full space-y-2">
				<Header
					className={tailwindCascade(
						"text-base md:text-lg leading-tight",
						"opacity-0 transition-opacity duration-300",
						{
							"opacity-100": visible && mounted,
						}
					)}
				>
					{quiz.generateModel && (
						<>
							{trans("This AI-generated quiz may contain errors and uses data up to %s", getModelUpdate(quiz.generateModel))}
						</>
					)}
				</Header>
				{quiz.generateModel && (
					<Header
						className={tailwindCascade(
							"text-2xs md:text-xs leading-tight",
							"opacity-0 transition-opacity duration-300",
							{
								"opacity-100": visible && mounted,
							}
						)}
					>
						{trans("AI model used: %s", getFriendlyModelName(quiz.generateModel))}
					</Header>
				)}
			</div>
			<TextToSpeech
				text={"This AI-generated quiz may contain errors"}
				voice="Samantha"
				isHost={true}
				play={visible && mounted}
			/>
		</div>
	) : null;
}
