import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as jsonpatch from "fast-json-patch";
import { enableMapSet } from "immer";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import isFinite from "lodash/isFinite";
import isNull from "lodash/isNull";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import Link from "next/link";
import { useRouter } from "next/router";
import ScrollContainer from "react-indiana-drag-scroll";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useImmer } from "use-immer";

import { apiUploadAvatar } from "@/api/avatar";

import Avatar from "@/components/Avatar";
import Header from "@/components/Header";
import Modal from "@/components/Modal";
import QuizVote, { PLAY_STATUSES_VOTE } from "@/components/QuizVote";
import TimeToGo from "@/components/TimeToGo";
import Button from "@/components/interactives/Button";
import Input from "@/components/interactives/Input";
import AvatarCustomize from "@/components/pages/join/AvatarCustomize";
import BackgroundAnimation from "@/components/pages/play/BackgroundAnimation";
import GameConnecting from "@/components/pages/play/GameConnecting";
import GameEnd, { RateFragments } from "@/components/pages/play/GameEnd";
import GamePaused from "@/components/pages/play/GamePaused";
import GetReady from "@/components/pages/play/GetReady";
import InfoBar from "@/components/pages/play/InfoBar";
import { LeaderboardView } from "@/components/pages/play/Leaderboard";
import Lobby from "@/components/pages/play/Lobby";
import PlayerScore from "@/components/pages/play/PlayerScore";
import Prefetch from "@/components/pages/play/Prefetch";
import ScaleWrapper from "@/components/pages/play/ScaleWrapper";
import Slide from "@/components/pages/play/Slide";

import { firstNames, lastNames } from "@/data/names";

import { sfx, voicePlayer } from "@/helpers/audio";
import getInitialAvatar from "@/helpers/getInitialAvatar";
import getInitialAvatarName from "@/helpers/getInitialAvatarName";
import getInitialAvatarSafeName from "@/helpers/getInitialAvatarSafeName";
import getRandomAvatarName from "@/helpers/getRandomAvatarName";
import getVerifiedAvatarSafeName from "@/helpers/getVerifiedAvatarSafeName";
import gsap from "@/helpers/gsap";
import { googleAnalyticsEvent } from "@/helpers/gtag";
import noSleep from "@/helpers/noSleep";
import { getConnectedPlayers, isPlayerConnected } from "@/helpers/player";
import { formatRoomCode, roomCodeValid, roomExists } from "@/helpers/roomCode";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useGeoFeature from "@/hooks/useGeoFeature";
import useRateQuizzes from "@/hooks/useRateQuizzes";
import useRefImmer from "@/hooks/useRefImmer";
import useRefMounted from "@/hooks/useRefMounted";
import useStatusWithProgress from "@/hooks/useStatusWithProgress";

import IconClear from "@/images/icons/icon-crosscircle.svg";
import DiscordIcon from "@/images/icons/icon-discord.svg";

import useAudioStore from "@/stores/audio";
import usePlayerStore from "@/stores/player";
import useSettingsStore from "@/stores/settings";
import useWebSocketStore from "@/stores/webSocket";

import {
	ROOM_CLOSE,
	ROOM_CLOSED,
	ROOM_CREATE,
	ROOM_FULL,
	ROOM_JOIN,
	ROOM_LEAVE,
	ROOM_MESSAGE,
	sendRoomMessage,
} from "@/webSocket/webSocket";

import { MAX_USER_NAME_LENGTH } from "@/app-constants.mjs";
import {
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	SLIDE_TYPE_TYPE_ANSWER,
} from "@/app-constants.mjs";
import { ERROR } from "@/colors";
import {
	BACKGROUND1,
	BACKGROUND2,
	BACKGROUND3,
	BACKGROUND4,
	BACKGROUND5,
	BACKGROUND6,
	BACKGROUND7,
	BACKGROUND8,
	BLACK,
	GREEN_LIGHT,
	PETROL_DARK,
	PETROL_DARKEST,
	PINK,
	YELLOW,
} from "@/colors";
import {
	DISCORD_LINK,
	PLAY_STATUS_AI_DISCLAIMER,
	PLAY_STATUS_BEFORE_LAST_SLIDE,
	PLAY_STATUS_VOTE_PREP,
	ROOM_CODE_INPUT_PATTERN,
	ROOM_CODE_LENGTH,
	STREET_VIEW_PORTAL_ID,
	VOTE_MODE_NORMAL,
} from "@/constants";
import {
	ANSWER_TIME_NORMAL,
	PLAY_STATUES_SLIDE,
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
	PLAY_STATUS_EXIT,
	PLAY_STATUS_FLUSH,
	PLAY_STATUS_GAME_START,
	PLAY_STATUS_HIDE_GET_READY,
	PLAY_STATUS_LOBBY,
	PLAY_STATUS_RATE_DONE,
	PLAY_STATUS_SHOW_AVATAR_COLORS,
	PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_GET_READY,
	PLAY_STATUS_SHOW_LEADERBOARD,
	PLAY_STATUS_SHOW_RATE,
	PLAY_STATUS_SHOW_WINNER,
	PLAY_STATUS_VOTE_SHOW,
	PLAY_STATUS_VOTE_SHOW_NEXT_QUIZ,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	WEB_SOCKET_URL,
} from "@/constants";

import LayoutBackground from "../LayoutBackground";
import ChevronButton from "../interactives/ChevronButton";
import AIDisclaimer from "./AIDisclaimer";
import Billboard from "./Billboard";
import RateModal from "./play/RateModal";

enableMapSet();

const BACKGROUND_COLORS = [
	BACKGROUND1,
	BACKGROUND2,
	BACKGROUND3,
	BACKGROUND4,
	BACKGROUND5,
	BACKGROUND6,
	BACKGROUND7,
	BACKGROUND8,
];

const BANNED_TIMEOUT = 600000;
const GAME_NOT_FOUND_TIMEOUT = 10000;

const VOTE_THROTTLE_TIMEOUT = 300;

const GAME_STATUS_NONE = 0;
const GAME_STATUS_NOT_FOUND = 1;
const GAME_STATUS_FULL = 2;
const GAME_STATUS_CLOSED = 3;
const GAME_STATUS_END = 4;

function PinInput() {
	const router = useRouter();
	const [pin, setPin] = useState("");

	const onChange = useCallback((event) => void setPin((event?.target?.value || "").replace(/[^0-9]/g, "")), []);
	const onSubmit = useCallback(
		(event) => {
			event.preventDefault();
			router.replace(`/join/${pin}`);
		},
		[pin, router]
	);

	return (
		<form className="md:w-42 flex flex-col w-full gap-4 pt-4 pb-8" onSubmit={onSubmit} action=".">
			<Input
				className="md:text-base w-full h-12 my-auto text-lg font-bold tracking-widest text-center uppercase"
				type="text"
				inputMode="numeric"
				autoComplete="off"
				autoCorrect="off"
				autoCapitalize="none"
				spellCheck="false"
				placeholder={trans("NEW PIN")}
				maxLength={ROOM_CODE_LENGTH + 1}
				pattern={ROOM_CODE_INPUT_PATTERN}
				onChange={onChange}
				value={formatRoomCode(pin)}
			/>
			<Button type="submit" color="green-light" className="w-full text-white" disabled={!roomCodeValid(pin)}>
				{trans("Join")}
			</Button>
		</form>
	);
}

function BrowsingQuizzesFragment() {
	return (
		<div className="md:px-0 flex flex-row items-center justify-center w-full h-full px-4">
			<div className="flex flex-col items-center justify-center h-full">
				<div className="flex flex-col items-center justify-center flex-1 py-8">
					<h1 className="pb-4 font-sans text-2xl font-bold text-center text-white">
						{trans("Please wait while host is selecting a quiz.")}
					</h1>
				</div>

				<div className="flex flex-col justify-end flex-1 flex-grow-0 pb-8">
					<h3 className="pb-2 font-sans text-base font-bold text-center text-white">
						{trans("Join our community:")}
					</h3>

					<Link legacyBehavior href={DISCORD_LINK} prefetch={false}>
						<a target="_blank">
							<Button
								elementType="span"
								border={3}
								color="green-lighter"
								className="whitespace-nowrap md:px-8 block h-10 px-6 py-0 text-base font-bold"
							>
								<div className="flex flex-row items-center justify-center">
									<DiscordIcon className="w-6 h-6 mr-2 text-black" />
									<div className="h-6 leading-6">{trans("Join on Discord")}</div>
								</div>
							</Button>
						</a>
					</Link>
				</div>
			</div>
		</div>
	);
}

function GameStatusFragment({ gameStatus = GAME_STATUS_NONE }) {
	return (
		<div className="md:px-0 flex flex-row items-center justify-center w-full h-full px-4">
			<div className="flex flex-col items-center justify-center h-full">
				<div className="flex flex-col items-center justify-center flex-1 py-8">
					{gameStatus === GAME_STATUS_NOT_FOUND && (
						<>
							<h1 className="pb-4 font-sans text-2xl font-bold text-center text-white">
								{trans("Game not found")}
							</h1>
							<PinInput />
						</>
					)}
					{gameStatus === GAME_STATUS_FULL && (
						<h1 className="pb-4 font-sans text-2xl font-bold text-center text-white">
							{trans("Game is full")}
						</h1>
					)}
					{gameStatus === GAME_STATUS_CLOSED && (
						<h1 className="pb-4 font-sans text-2xl font-bold text-center text-white">
							{trans("Game is not open for joining")}
						</h1>
					)}
					{gameStatus === GAME_STATUS_END && (
						<h1 className="pb-4 font-sans text-2xl font-bold text-center text-white">
							{trans("Your host stopped hosting")}
						</h1>
					)}
					<h2
						className="font-sans text-lg font-bold text-center text-white"
						dangerouslySetInnerHTML={{
							__html: trans("Check out our %sother quizzes%s", `<a class="underline" href="/">`, "</a>"),
						}}
					/>
				</div>

				<div className="flex flex-col justify-end flex-1 flex-grow-0 pb-8">
					<h3 className="pb-2 font-sans text-base font-bold text-center text-white">
						{trans("Join our community:")}
					</h3>

					<Link legacyBehavior href={DISCORD_LINK} prefetch={false}>
						<a target="_blank">
							<Button
								elementType="span"
								border={3}
								color="green-lighter"
								className="whitespace-nowrap md:px-8 block h-10 px-6 py-0 text-base font-bold"
							>
								<div className="flex flex-row items-center justify-center">
									<DiscordIcon className="w-6 h-6 mr-2 text-black" />
									<div className="h-6 leading-6">{trans("Join on Discord")}</div>
								</div>
							</Button>
						</a>
					</Link>
				</div>
			</div>
		</div>
	);
}

function GameEndedFragment() {
	return (
		<div className="md:px-0 flex flex-row items-center justify-center w-full px-4">
			<div className="flex flex-col items-center justify-center gap-8">
				<Header className="mb-16 text-center">{trans("Game ended")}</Header>
				<PinInput />
				<Link legacyBehavior href="/">
					<a className="text-white underline opacity-50">{trans("Return to home")}</a>
				</Link>
			</div>
		</div>
	);
}

function TopMenu({ state, onClickClear, onClickDone }) {
	return (
		<div className="sm:bg-black sm:bg-opacity-50 sm:pb-4 w-full pt-4">
			<div className="h-14 container flex flex-row w-full gap-4 px-4 mx-auto">
				<Button border={true} color="pink" className="px-6 text-white" onClick={onClickClear}>
					{trans("Clear")}
				</Button>
				<div className="flex flex-row gap-4 ml-auto">
					<Button border={true} color={"green-light"} className="px-6 text-white" onClick={onClickDone}>
						{trans("Done")}
					</Button>
				</div>
			</div>
		</div>
	);
}

function AvatarCustomizeTeam({
	onDone,
	onChangeName,
	onChangeSafeName,
	statusWithProgressName,
	name,
	safeName,
	avatar,
	useSafeNames,
}) {
	const [state, updateState] = useImmer({
		hasSearchText: true,
		isDone: false,
	});

	const nameRef = useRef(null);

	const onChange = useCallback(
		(event) => {
			updateState((draft) => void (draft.hasSearchText = event.target.value.length > 0));
			if (onChangeName) {
				onChangeName((event && event.target && event.target.value) || "");
			}
		},
		[onChangeName, updateState]
	);

	const firstNameIndex = useMemo(() => {
		if (safeName) {
			const names = safeName.split(" ");
			const firstName = names.length >= 1 ? names[0] : "";
			const firstNameIndex = firstName ? firstNames.indexOf(firstName) : -1;
			return firstNameIndex !== -1 ? firstNameIndex : 0;
		}
		return 0;
	}, [safeName]);

	const lastNameIndex = useMemo(() => {
		if (safeName) {
			const names = safeName.split(" ");
			const lastName = names.length >= 2 ? names[1] : "";
			const lastNameIndex = lastName ? lastNames.indexOf(lastName) : -1;
			return lastNameIndex !== -1 ? lastNameIndex : 0;
		}
		return 0;
	}, [safeName]);

	const onChangeSafeNameFirstName = useCallback(
		(firstNameIndex) => {
			let lastNameIndex = -1;
			if (safeName) {
				const names = safeName.split(" ");
				const lastName = names.length >= 2 ? names[1] : "";
				lastNameIndex = lastName ? lastNames.indexOf(lastName) : -1;
			}
			if (onChangeSafeName) {
				onChangeSafeName(
					`${firstNameIndex !== -1 ? firstNames[firstNameIndex] : firstNames[0]} ${
						lastNameIndex !== -1 ? lastNames[lastNameIndex] : lastNames[0]
					}`
				);
			}
		},
		[onChangeSafeName, safeName]
	);

	const onChangeSafeNameLastName = useCallback(
		(lastNameIndex) => {
			let firstNameIndex = -1;
			if (safeName) {
				const names = safeName.split(" ");
				const firstName = names.length >= 1 ? names[0] : "";
				firstNameIndex = firstName ? firstNames.indexOf(firstName) : -1;
			}
			if (onChangeSafeName) {
				onChangeSafeName(
					`${firstNameIndex !== -1 ? firstNames[firstNameIndex] : firstNames[0]} ${
						lastNameIndex !== -1 ? lastNames[lastNameIndex] : lastNames[0]
					}`
				);
			}
		},
		[onChangeSafeName, safeName]
	);

	return (
		<div className="relative flex flex-col items-center justify-start w-full pb-4">
			<TopMenu onClickDone={onDone} />
			<div className={`md:container md:mx-auto sm:mt-1 sm:mb-4 w-full px-4 my-3`}>
				<div className="w-full">
					<div className="flex flex-col items-center w-full">
						{useSafeNames ? (
							<div className="md:w-86 flex flex-col w-full space-y-2">
								<ChevronButton
									index={firstNameIndex}
									innerClassName="bg-white h-12"
									buttonClassName="flex-shrink-0 w-12 h-12 px-2"
									buttonIconClassName="w-6 h-6"
									border={true}
									borderRadius={12}
									tight={true}
									white={true}
									max={firstNames.length - 1}
									onChange={onChangeSafeNameFirstName}
								>
									{firstNames[firstNameIndex]}
								</ChevronButton>
								<ChevronButton
									index={lastNameIndex}
									innerClassName="bg-white h-12"
									buttonClassName="flex-shrink-0 w-12 h-12 px-2"
									buttonIconClassName="w-6 h-6"
									border={true}
									borderRadius={12}
									tight={true}
									white={true}
									max={lastNames.length - 1}
									onChange={onChangeSafeNameLastName}
								>
									{lastNames[lastNameIndex]}
								</ChevronButton>
							</div>
						) : (
							<div className="relative mt-12">
								<Input
									ref={nameRef}
									className="mb-0"
									name="name"
									autoComplete="name"
									border={state.hasSearchText ? true : ERROR}
									type="text"
									placeholder={trans("Tap to enter name")}
									value={name}
									onChange={onChange}
									maxLength={MAX_USER_NAME_LENGTH}
								/>
								{state.hasSearchText && (
									<button
										onClick={() => {
											updateState((draft) => {
												draft.hasSearchText = false;
											});
											if (onChangeName) {
												onChangeName("");
											}
											if (nameRef.current) {
												nameRef.current.focus();
											}
										}}
										className="top-1/2 right-2 absolute transform -translate-y-1/2"
									>
										<IconClear className="h-10 opacity-50" />
									</button>
								)}
							</div>
						)}

						<div className="flex-col mt-12 text-lg text-white">
							Team Mode - No personal avatars available
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function TeamSelector({ teamId, setTeamId, teams }) {
	const onClickTeam = useCallback((ev) => void setTeamId(ev.currentTarget.dataset.teamId), [setTeamId]);

	return (
		<div className="items-center self-stretch flex-grow mt-12 mb-12">
			<div className="flex flex-row justify-center w-full mb-12">
				<Header className="text-4xl">Select Team</Header>
			</div>
			<ScrollContainer
				horizontal={true}
				vertical={false}
				hideScrollbars={false}
				className={tailwindCascade(
					"flex flex-row gap-0 justify-evenly",
					"pt-1",
					"scrollbar-thin scrollbar-thumb-black-50 scrollbar-track-transparent"
				)}
			>
				{[...teams.values()].map((team) => (
					<button
						key={team.connectionId}
						className={tailwindCascade("border-transparent border-solid border-4 p-2 rounded-xl my-1", {
							" border-white": team.connectionId === teamId,
						})}
						onClick={onClickTeam}
						data-team-id={team.connectionId}
					>
						<Avatar
							className="flex-shrink-0 w-24 h-24"
							playerAvatar={team.avatar}
							playerName={team.name}
							playerPoints={team.previousPoints}
							checkmark={false}
							showName={true}
							isLobby={true}
							onLoad={() => {}}
							onMouseEnter={() => {}}
						/>
					</button>
				))}
			</ScrollContainer>
		</div>
	);
}

function AvatarCustomizeFragment({
	onDone,
	onChangeAvatar,
	onChangeName,
	onChangeSafeName,
	statusWithProgressName,
	teamMode,
	teams,
	selectTeamEnabled,
	playerTeamId,
	useSafeNames,
	...props
}) {
	const [teamId, setTeamId] = useState(playerTeamId);

	const onDoneInternal = useCallback(() => {
		sfx.play("playerReady");

		const { sendRoomMessage } = useWebSocketStore.getState();
		sendRoomMessage({ joinTeam: teamId });

		if (onDone) {
			onDone();
		}
	}, [onDone, teamId]);

	return teamMode ? (
		<>
			<AvatarCustomizeTeam
				onChangeName={onChangeName}
				onChangeSafeName={onChangeSafeName}
				onDone={onDoneInternal}
				statusWithProgressName={statusWithProgressName}
				teams={teams}
				teamId={teamId}
				useSafeNames={useSafeNames}
				{...props}
			/>
			<TeamSelector onDone={onDoneInternal} teams={teams} teamId={teamId} setTeamId={setTeamId} />
		</>
	) : (
		<AvatarCustomize
			onChangeAvatar={onChangeAvatar}
			onChangeName={onChangeName}
			onChangeSafeName={onChangeSafeName}
			onDone={onDoneInternal}
			statusWithProgressName={statusWithProgressName}
			useSafeNames={useSafeNames}
			{...props}
		/>
	);
}

export default function GuestPage({ requestedRoomId }) {
	const [mounted, mountedRef] = useRefMounted();

	const router = useRouter();

	const updatePlayerName = usePlayerStore((state) => state.updateName);
	const updatePlayerSafeName = usePlayerStore((state) => state.updateSafeName);
	const updatePlayerNameAndSafeName = usePlayerStore((state) => state.updateNameAndSafeName);
	const updatePlayerAvatar = usePlayerStore((state) => state.updateAvatar);
	const updatePlayerCountry = usePlayerStore((state) => state.updateCountry);

	const playerName = usePlayerStore((state) => state.name);
	const playerSafeName = usePlayerStore((state) => state.safeName);
	const playerAvatar = usePlayerStore((state) => state.avatar);
	const playerCountry = usePlayerStore((state) => state.country);

	const [isSpectating, setIsSpectating] = useState(false);
	const isSpectatingRef = useRef(isSpectating);
	useEffect(() => void (isSpectatingRef.current = isSpectating), [isSpectating]);

	const [playState, updatePlayState, playStateRef] = useRefImmer({
		statusWithProgress: { name: PLAY_STATUS_LOBBY, duration: 0, progress: 0, elapsedTime: 0 },
		roomId: null,
		gameStatus: GAME_STATUS_NONE,
		// gameNotFound: false,
		submittedAnswer: null,
		submittedAnswerProgress: null,
		answerTime: ANSWER_TIME_NORMAL, // TODO: not in use?
		typeAnswerIsCorrect: null,
		typeAnswerIsClose: null,
		typeAnswerValue: "",
		canVote: false, // TODO: not in use?
		resetTimer: false,
		browsingQuizzes: false,

		player: {
			name: "",
			country: null,
			avatar: null,
			connecting: true,
			created: isSpectating,
			points: 0, // TODO: can be derived from "players"
			rank: undefined, // TODO: can be derived from "players"
			ratings: {},
		},

		hostState: {
			doublePoints: false,
			isPaused: false,
			leaderboard: null, // TODO: Can be derived from "players"
			nextQuiz: null,
			nonPlayingHostVoteQuizId: null,
			players: [],
			teams: [],
			quiz: null, // name, media, owner, slides (array of empty)
			recommendedQuizzes: null,
			settings: {
				safeNames: false,
				hideIncorrectTypeAnswers: false,
				showCode: true,
				joinOpen: true,
				mute: null,
				voiceOverride: "Default",
				numQuestionsPerRound: null,
				numRoundsPerGame: null,
				backgroundAnimationEnabled: true,
				mutePlayerNames: false,
				reducedMotion: false,
				hidePlayerCountry: false,
				teamMode: false,
			},
			quickMode: undefined,
			slideIndex: -1,
			slideObject: null,
			typeAnswerWrongAnswers: [],
			numberOfVotes: {},
			voteAvatars: {},
			voteMode: VOTE_MODE_NORMAL,
			aiMode: false,
			aiGenerating: false,
			aiGeneratingInfo: null,
			aiGeneratingProgress: 0,
		},
	});

	useEffect(() => {
		if (typeof window !== "undefined") {
			const search = window?.location?.search || "";
			const isSpectating = search.indexOf("spectating=true") !== -1;
			setIsSpectating(isSpectating);
			if (isSpectating) {
				updatePlayState((draft) => void (draft.player.created = true));
			}
			return;
		}
		setIsSpectating(false);
	}, [updatePlayState]);

	useEffect(() => {
		if (playState.hostState.settings.safeNames) {
			let safeName = getInitialAvatarSafeName();
			const name = getInitialAvatarName();
			if (getVerifiedAvatarSafeName(name)) {
				updatePlayerNameAndSafeName(name, name);
			} else {
				if (!isString(safeName)) {
					safeName = getRandomAvatarName();
				}
				updatePlayerSafeName(safeName);
			}
		} else {
			let name = getInitialAvatarName();
			if (getVerifiedAvatarSafeName(name)) {
				updatePlayerNameAndSafeName(name, name);
			} else {
				if (!isString(name)) {
					name = getRandomAvatarName();
				}
				updatePlayerName(name);
			}
		}
	}, [playState.hostState.settings.safeNames, updatePlayerName, updatePlayerNameAndSafeName, updatePlayerSafeName]);

	useEffect(() => {
		updatePlayState(
			(draft) => void (draft.player.name = playState.hostState.settings.safeNames ? playerSafeName : playerName)
		);
	}, [playState.hostState.settings.safeNames, playerName, playerSafeName, updatePlayState]);

	useEffect(() => {
		const avatar = getInitialAvatar();
		updatePlayerAvatar(avatar);
	}, [updatePlayerAvatar]);

	useEffect(
		() => void updatePlayState((draft) => void (draft.player.avatar = playerAvatar)),
		[playerAvatar, updatePlayState]
	);

	const playersArray = playState.hostState.players;
	const players = useMemo(() => new Map(playersArray.map((player) => [player.connectionId, player])), [playersArray]);

	const teams = useMemo(
		() => new Map(playState.hostState.teams.map((team) => [team.connectionId, team])),
		[playState.hostState.teams]
	);

	/*
	// Debug
	useEffect(() => {
		console.log("Players:", [...players.values()]);
	}, [players]);
	*/

	const connected = useWebSocketStore((state) => state.connected);
	const connectedRef = useRef(connected);
	useEffect(() => void (connectedRef.current = connected), [connected]);

	const connectionId = useWebSocketStore((state) => state.connectionId);
	const player = useMemo(() => players.get(connectionId), [players, connectionId]);

	const reconnecting = useWebSocketStore((state) => state.reconnecting);
	const disconnect = useWebSocketStore((state) => state.disconnect);

	const country = useWebSocketStore((state) => state.country);

	useEffect(() => void updatePlayerCountry(country), [country, updatePlayerCountry]);

	useEffect(
		() => void updatePlayState((draft) => void (draft.player.country = playerCountry)),
		[playerCountry, updatePlayState]
	);

	const hostState = playState.hostState;
	const settings = hostState.settings;
	const slideIndex = hostState.slideIndex;
	const slide = hostState.slideObject;
	const isConnecting = playState.player.connecting || reconnecting;
	const isPaused = hostState.isPaused || isConnecting;

	const roomId = useWebSocketStore((state) => state.roomId);
	const roomIdRef = useRef(roomId);
	useEffect(() => void (roomIdRef.current = roomId), [roomId]);

	const slideRef = useRef(slide);
	useEffect(() => void (slideRef.current = slide), [slide]);

	const slideTypeRef = useRef(slide?.type);
	useEffect(() => void (slideTypeRef.current = slide?.type), [slide?.type]);

	useEffect(() => {
		noSleep.initialize();
		const enable = () => void noSleep.enable();
		document.addEventListener("click", enable);
		return () => {
			document.removeEventListener("click", enable);
			noSleep.destroy();
		};
	}, []);

	const correctAnswerFeature = useGeoFeature({
		placeType: hostState.placeType,
		placeCode: hostState.placeCode,
		condition: !!(playState.roomId && connected),
	});

	const loadAudio = useAudioStore((state) => state.loadAudio);
	const stopAudio = useAudioStore((state) => state.stopAudio);

	const soundVolume = useSettingsStore((state) => state.soundVolume);
	const setSoundVolume = useAudioStore((state) => state.setSoundVolume);

	const setMusicVolume = useAudioStore((state) => state.setMusicVolume);
	const musicVolume = useSettingsStore((state) => state.musicVolume);
	const pauseMusic = useAudioStore((state) => state.pauseMusic);
	const resumeMusic = useAudioStore((state) => state.resumeMusic);
	const setLobbyMusic = useAudioStore((state) => state.setLobbyMusic);

	const setVoiceVolume = useAudioStore((state) => state.setVoiceVolume);
	const voiceVolume = useSettingsStore((state) => state.voiceVolume);

	const prevMuteRef = useRef(null);

	useEffect(
		() => void setLobbyMusic(playState.statusWithProgress.name === PLAY_STATUS_LOBBY),
		[playState.statusWithProgress.name, setLobbyMusic]
	);

	const muteAudio = useCallback(() => {
		stopAudio();
		sfx.unload();
		setVoiceVolume(0);
	}, [setVoiceVolume, stopAudio]);
	const muteAudioRef = useRef(muteAudio);
	useEffect(() => void (muteAudioRef.current = muteAudio), [muteAudio]);

	const unmuteAudio = useCallback(
		(aiMode) => {
			setMusicVolume(musicVolume);
			setSoundVolume(soundVolume);
			setVoiceVolume(voiceVolume);
			loadAudio(aiMode);
		},
		[loadAudio, musicVolume, setMusicVolume, setSoundVolume, setVoiceVolume, soundVolume, voiceVolume]
	);
	const unmuteAudioRef = useRef(unmuteAudio);
	useEffect(() => void (unmuteAudioRef.current = unmuteAudio), [unmuteAudio]);

	useEffect(() => {
		if (settings.mute) {
			if (muteAudioRef.current) {
				muteAudioRef.current();
			}
		} else {
			if (unmuteAudioRef.current) {
				unmuteAudioRef.current(hostState.aiMode);
			}
		}
	}, [settings.mute, hostState.aiMode]);

	useEffect(() => {
		return () => {
			if (muteAudioRef.current) {
				muteAudioRef.current();
			}
		};
	}, []);

	useEffect(() => {
		if (isPaused) {
			sfx.pause();
			voicePlayer.pause();
			pauseMusic();
		} else {
			sfx.play();
			voicePlayer.resume();
			resumeMusic();
		}
	}, [pauseMusic, isPaused, resumeMusic]);

	useEffect(() => void setMusicVolume(musicVolume), [musicVolume, setMusicVolume]);
	useEffect(() => void setSoundVolume(soundVolume), [setSoundVolume, soundVolume]);
	useEffect(() => void setVoiceVolume(voiceVolume), [setVoiceVolume, voiceVolume]);

	const updatePlayStatus = useCallback(
		(callback, status = null) => {
			updatePlayState((draft) => {
				switch (status) {
					case PLAY_STATUS_LOBBY:
						draft.player.points = 0;
						draft.player.rank = undefined;
						draft.player.ratings = {};
						break;
					case PLAY_STATUS_GAME_START:
						draft.player.created = true;
						break;
					case PLAY_STATUS_SHOW_WINNER:
						draft.player.ratings = {};
						break;
					case PLAY_STATUS_VOTE_SHOW:
						draft.player.ratings = {};
						break;
					case PLAY_STATUS_SHOW_RATE:
						draft.player.ratings = {};
						break;
					case PLAY_STATUS_EXIT:
						draft.player.points = 0;
						draft.player.rank = undefined;
						draft.player.ratings = {};
						break;
				}
				callback(draft);
			});
		},
		[updatePlayState]
	);

	useEffect(() => {
		if (playState.statusWithProgress.name === PLAY_STATUS_GAME_START) {
			googleAnalyticsEvent("quiz_start", "guest");
		} else if (playState.statusWithProgress.name === PLAY_STATUS_SHOW_WINNER) {
			googleAnalyticsEvent("quiz_end", "guest");
		}
	}, [playState.statusWithProgress.name]);

	useEffect(() => {
		setMyVoteQuizId(null);
	}, [hostState.voteMode]);

	useEffect(() => {
		const history = players.get(connectionId)?.history ?? {};
		const historyEntry = history[slideIndex];

		if (historyEntry) {
			updatePlayState((draft) => {
				if (slideTypeRef.current === SLIDE_TYPE_RANGE) {
					draft.submittedAnswer = parseFloat(historyEntry.selectedAnswer);
				} else if (slideTypeRef.current === SLIDE_TYPE_TYPE_ANSWER) {
					draft.typeAnswerIsCorrect = true;
					draft.submittedAnswer = historyEntry.selectedAnswer;
				} else {
					draft.submittedAnswer = historyEntry.selectedAnswer;
				}

				draft.submittedAnswerProgress = historyEntry.progress;
			});
		}
	}, [connectionId, players, slideIndex, updatePlayState]);

	const onRoomJoin = useCallback(
		(message) => {
			updatePlayState((draft) => {
				draft.gameStatus = GAME_STATUS_NONE;
				draft.roomId = message && message.roomId;
				draft.player.ratings = {};
				draft.player.connecting = false;
			});

			googleAnalyticsEvent("quiz_join", "guest");
		},
		[updatePlayState]
	);
	const onRoomJoinRef = useRef(onRoomJoin);
	useEffect(() => void (onRoomJoinRef.current = onRoomJoin), [onRoomJoin]);

	useEffect(
		() =>
			// populate connectionId and teamId of local player state
			void updatePlayState((draft) => {
				draft.player.connectionId = connectionId;
				draft.player.teamId = players.get(connectionId)?.teamId;
			}),
		[connectionId, players, updatePlayState]
	);

	const onRoomLeave = useCallback(() => {
		updatePlayState((draft) => void (draft.player.connecting = true));
	}, [updatePlayState]);
	const onRoomLeaveRef = useRef(onRoomLeave);
	useEffect(() => void (onRoomLeaveRef.current = onRoomLeave), [onRoomLeave]);

	const onRoomFull = useCallback(
		(message) => {
			if (message && message.roomId && message.roomId === requestedRoomId) {
				updatePlayState((draft) => {
					draft.gameStatus = GAME_STATUS_FULL;
					draft.player.connecting = false;
				});
				disconnect();
			}
		},
		[requestedRoomId, updatePlayState, disconnect]
	);
	const onRoomFullRef = useRef(onRoomFull);
	useEffect(() => void (onRoomFullRef.current = onRoomFull), [onRoomFull]);

	const onRoomClosed = useCallback(
		(message) => {
			if (message && message.roomId && message.roomId === requestedRoomId) {
				updatePlayState((draft) => {
					draft.gameStatus = GAME_STATUS_CLOSED;
					draft.player.connecting = false;
				});
				disconnect();
			}
		},
		[requestedRoomId, updatePlayState, disconnect]
	);
	const onRoomClosedRef = useRef(onRoomClosed);
	useEffect(() => void (onRoomClosedRef.current = onRoomClosed), [onRoomClosed]);

	const onRoomClose = useCallback(
		(message) => {
			updatePlayState((draft) => {
				draft.gameStatus = GAME_STATUS_END;
				draft.hostState.quiz = null;
				draft.statusWithProgress = { name: PLAY_STATUS_LOBBY, duration: 0, progress: 0, elapsedTime: 0 };
				draft.player.connecting = false;
			});
			disconnect();
		},
		[disconnect, updatePlayState]
	);
	const onRoomCloseRef = useRef(onRoomClose);
	useEffect(() => void (onRoomCloseRef.current = onRoomClose), [onRoomClose]);

	useEffect(() => {
		if (playState.gameStatus === GAME_STATUS_FULL || playState.gameStatus === GAME_STATUS_CLOSED) {
			disconnect();
		}
	}, [disconnect, playState.gameStatus]);

	const sendAvatarRoomMessage = useCallback(() => {
		if (connectedRef.current && playStateRef.current.roomId && !playStateRef.current.player.connecting) {
			const roomMessage = {
				player: {
					name: playStateRef.current.player.name,
					created: playStateRef.current.player.created,
					country: playStateRef.current.player.country,
				},
			};

			if (isArray(playStateRef.current?.player?.avatar)) {
				const avatar = cloneDeep(playStateRef.current.player.avatar);
				apiUploadAvatar(avatar)
					.promise.then(({ filename }) => {
						if (mountedRef.current) {
							if (filename) {
								roomMessage.player.avatar = filename;
							} else {
								roomMessage.player.avatar = avatar;
							}
							const { sendRoomMessage } = useWebSocketStore.getState();
							if (!isSpectatingRef.current) {
								sendRoomMessage(roomMessage);
							}
						}
					})
					.catch(() => {
						if (mountedRef.current) {
							roomMessage.player.avatar = avatar;
							const { sendRoomMessage } = useWebSocketStore.getState();
							if (!isSpectatingRef.current) {
								sendRoomMessage(roomMessage);
							}
						}
					});
			}
		}
	}, [mountedRef, playStateRef]);
	const sendAvatarRoomMessageRef = useRef(sendAvatarRoomMessage);
	useEffect(() => void (sendAvatarRoomMessageRef.current = sendAvatarRoomMessage), [sendAvatarRoomMessage]);

	const onClickTryAgain = useCallback(() => {
		if (typeof window !== "undefined") {
			window.location.reload();
		}
	}, []);

	useEffect(() => {
		if (playState.resetTimer) {
			const timeout = setTimeout(() => {
				updatePlayState((draft) => {
					draft.resetTimer = false;
				});
			}, 300);
			return () => void clearTimeout(timeout);
		}
	}, [playState.resetTimer, updatePlayState]);

	const onRoomMessage = useCallback(
		(message) => {
			if (!message) {
				return;
			}

			if ("resetTimer" in message) {
				const sound = !!message.sound;
				if (sound) {
					sfx.play("lbPlayerAppears");
				}

				voteQueueRef.current.clear();
				if (voteQueueTimeoutRef.current) {
					clearTimeout(voteQueueTimeoutRef.current);
					voteQueueTimeoutRef.current = null;
				}

				updatePlayState((draft) => {
					draft.statusWithProgress.progress = 0;
					draft.statusWithProgress.elapsedTime = 0;
					draft.resetTimer = true;
				});
			}

			if ("browsingQuizzes" in message) {
				updatePlayState((draft) => {
					draft.browsingQuizzes = message.browsingQuizzes;
					if (message.browsingQuizzes === true) {
						draft.hostState.quiz = null;
						draft.statusWithProgress = {
							name: PLAY_STATUS_LOBBY,
							duration: 0,
							progress: 0,
							elapsedTime: 0,
						};
					}
				});
			}

			if (message.kick || message.ban) {
				// Clear connectionId & refreshToken
				useWebSocketStore.getState().resetSessionStorage();
				if (message.ban && roomIdRef.current) {
					usePlayerStore.getState().setBannedRoomId(roomIdRef.current);
				}
				if (typeof window !== "undefined") {
					window.location = "/";
				}
				return;
			}

			if ("correctAnswerReveal" in message) {
				return;
			}

			// Always send avatar in lobby
			if (message.status === PLAY_STATUS_LOBBY) {
				if (sendAvatarRoomMessageRef.current) {
					sendAvatarRoomMessageRef.current();
				}
			}

			updatePlayStatus((draft) => {
				draft.player.connecting = false;

				if ("state" in message) {
					draft.hostState = cloneDeep(message.state);
				}

				if ("patch" in message) {
					try {
						draft.hostState = jsonpatch.applyPatch(draft.hostState, message.patch).newDocument;
					} catch (error) {
						console.error(error);
					}
				}

				if (message?.player) {
					if (isString(message?.player?.name)) {
						draft.player.name = message.player.name;
					}

					if (isArray(message?.player?.avatar)) {
						draft.player.avatar = message.player.avatar;
					}

					draft.player.created = !!message?.player?.reconnect;
				}

				if ("status" in message) {
					if (draft.statusWithProgress.name != message.status) {
						draft.statusWithProgress.name = message.status;
						draft.statusWithProgress.progress = 0;
						draft.statusWithProgress.elapsedTime = 0;
					}
				}

				if ("duration" in message) {
					// Duration is passed as string to allow Infinity
					draft.statusWithProgress.duration = parseFloat(message.duration);
				}

				if ("progress" in message) {
					draft.statusWithProgress.progress = message.progress;
				}

				if ("response" in message) {
					if (message.response.slideType === SLIDE_TYPE_TYPE_ANSWER) {
						if (draft.hostState.slideIndex === message.response.slideIndex) {
							const isCorrect = !!message.response.isCorrect;
							const isClose = !!message.response.isClose;
							draft.typeAnswerIsCorrect = isCorrect;
							draft.typeAnswerIsClose = isClose;
							if (isCorrect) {
								draft.submittedAnswer = "correct";
								draft.submittedAnswerProgress = message.response.progress;
							}
						}
					} else if (
						[
							SLIDE_TYPE_CLASSIC,
							SLIDE_TYPE_CHECK_BOXES,
							SLIDE_TYPE_LOCATION,
							SLIDE_TYPE_RANGE,
							SLIDE_TYPE_REORDER,
						].includes(message.response.slideType)
					) {
						if (draft.hostState.slideIndex === message.response.slideIndex) {
							draft.submittedAnswer = message.response.answer;
							draft.submittedAnswerProgress = message.response.progress;
						}
					}
				}

				if ("points" in message) {
					draft.player.points = message.points;
				}

				if ("rank" in message) {
					draft.player.rank = message.rank;
				}
			}, message.status);
		},
		[updatePlayState, updatePlayStatus]
	);

	const onRoomMessageRef = useRef(onRoomMessage);
	useEffect(() => void (onRoomMessageRef.current = onRoomMessage), [onRoomMessage]);

	useEffect(() => {
		updatePlayState((draft) => {
			draft.submittedAnswer = null;
			draft.submittedAnswerProgress = null;
			draft.typeAnswerIsCorrect = null;
			draft.typeAnswerIsClose = null;
			draft.typeAnswerValue = "";
			draft.typeAnswerWrongAnswers = [];
		});
	}, [slideIndex, updatePlayState]);

	useEffect(() => {
		if (requestedRoomId) {
			const { addEventListener, removeEventListener, connect, disconnect } = useWebSocketStore.getState();

			const onRoomMessage = (message) => {
				if (onRoomMessageRef.current) {
					onRoomMessageRef.current(message);
				}
			};

			const onRoomJoin = (message) => {
				if (onRoomJoinRef.current) {
					onRoomJoinRef.current(message);
				}
			};

			const onRoomFull = (message) => {
				if (onRoomFullRef.current) {
					onRoomFullRef.current(message);
				}
			};

			const onRoomClosed = (message) => {
				if (onRoomClosedRef.current) {
					onRoomClosedRef.current(message);
				}
			};

			const onRoomLeave = (message) => {
				if (onRoomLeaveRef.current) {
					onRoomLeaveRef.current(message);
				}
			};

			const onRoomClose = (message) => {
				if (onRoomCloseRef.current) {
					onRoomCloseRef.current(message);
				}
			};

			addEventListener(ROOM_MESSAGE, onRoomMessage);
			addEventListener(ROOM_JOIN, onRoomJoin);
			addEventListener(ROOM_FULL, onRoomFull);
			addEventListener(ROOM_CLOSED, onRoomClosed);
			addEventListener(ROOM_LEAVE, onRoomLeave);
			addEventListener(ROOM_CLOSE, onRoomClose);

			let dispose = null;
			if (roomCodeValid(requestedRoomId)) {
				updatePlayState((draft) => void (draft.player.connecting = true));

				dispose = roomExists(requestedRoomId, (roomExists) => {
					if (roomExists) {
						connect(WEB_SOCKET_URL);
					} else {
						updatePlayState((draft) => void (draft.gameStatus = GAME_STATUS_NOT_FOUND));
					}
				});
			}

			return () => {
				if (dispose) {
					dispose();
				}
				removeEventListener(ROOM_MESSAGE, onRoomMessage);
				removeEventListener(ROOM_JOIN, onRoomJoin);
				removeEventListener(ROOM_FULL, onRoomFull);
				removeEventListener(ROOM_LEAVE, onRoomLeave);
				removeEventListener(ROOM_CLOSE, onRoomClose);
				disconnect(false);
			};
		}
	}, [requestedRoomId, updatePlayState]);

	useEffect(() => {
		if (requestedRoomId && connected) {
			const bannedRoomIds = isObject(usePlayerStore.getState().bannedRoomIds)
				? usePlayerStore.getState().bannedRoomIds
				: {};
			if (
				bannedRoomIds[requestedRoomId] &&
				isFinite(bannedRoomIds[requestedRoomId]) &&
				Date.now() - bannedRoomIds[requestedRoomId] < BANNED_TIMEOUT
			) {
				setBanned(bannedRoomIds[requestedRoomId]);
			} else {
				const { joinRoom } = useWebSocketStore.getState();
				joinRoom(requestedRoomId, isSpectatingRef.current);
			}
		}
	}, [requestedRoomId, connected]);

	const onChangeNameAvatarCustomize = useCallback(
		(name) => {
			if (!isNull(name)) {
				if (getVerifiedAvatarSafeName(name)) {
					updatePlayerNameAndSafeName(name, name);
				} else {
					updatePlayerName(name);
				}
			}
		},
		[updatePlayerName, updatePlayerNameAndSafeName]
	);

	const onChangeTeamAvatarCustomize = useCallback(
		(teamId) => {
			if (teamId) {
				if (getVerifiedAvatarSafeName(name)) {
					updatePlayerNameAndSafeName(name, name);
				} else {
					updatePlayerName(name);
				}
			}
		},
		[updatePlayerName, updatePlayerNameAndSafeName]
	);

	const onChangeSafeNameAvatarCustomize = useCallback(
		(safeName) => {
			if (!isNull(safeName)) {
				if (getVerifiedAvatarSafeName(usePlayerStore.getState().name)) {
					updatePlayerNameAndSafeName(safeName, safeName);
				} else {
					updatePlayerSafeName(safeName);
				}
			}
		},
		[updatePlayerNameAndSafeName, updatePlayerSafeName]
	);

	const onChangeAvatarAvatarCustomize = useCallback(
		(avatar) => {
			if (isArray(avatar)) {
				updatePlayerAvatar(avatar);
			}
		},
		[updatePlayerAvatar]
	);

	useDeepCompareEffect(() => {
		if (
			playStateRef.current &&
			playStateRef.current.gameStatus !== GAME_STATUS_FULL &&
			playStateRef.current.gameStatus !== GAME_STATUS_CLOSED
		) {
			if (sendAvatarRoomMessageRef.current) {
				sendAvatarRoomMessageRef.current();
			}
		}
	}, [
		playState.roomId,
		connected,
		playState.player.connecting,
		playState.player.name,
		playState.player.avatar,
		playState.player.created,
		playStateRef,
	]);

	const isPausedRef = useRef(null);
	useEffect(() => {
		if (isPaused) {
			isPausedRef.current = gsap.exportRoot();
			isPausedRef.current.pause();
		} else if (isPausedRef.current) {
			isPausedRef.current.play();
			isPausedRef.current = null;
		}

		return () => {
			if (isPausedRef.current) {
				isPausedRef.current.play();
				isPausedRef.current = null;
			}
		};
	}, [isPaused]);

	const selectTeamEnabled = useRef(true);

	const onDoneAvatarCustomize = () => {
		updatePlayState((draft) => {
			draft.player.created = true;
		});
		setShowAvatarCustomize(false);

		if (settings.teamMode) {
			selectTeamEnabled.current = false;
		}
	};

	const setSlideTypeAnswer = useCallback(
		(value) =>
			void updatePlayState((draft) => {
				draft.typeAnswerValue = value;
				draft.typeAnswerIsCorrect = null;
				draft.typeAnswerIsClose = null;
			}),
		[updatePlayState]
	);

	const typeAnswerState = useMemo(
		() => ({
			value: playState.typeAnswerValue,
			isCorrect: playState.typeAnswerIsCorrect,
			isClose: playState.typeAnswerIsClose,
			isNumeric: playState.typeAnswerIsNumeric,
			wrongAnswers: hostState.typeAnswerWrongAnswers,
		}),
		[
			playState.typeAnswerIsClose,
			playState.typeAnswerIsCorrect,
			playState.typeAnswerIsNumeric,
			playState.typeAnswerValue,
			hostState.typeAnswerWrongAnswers,
		]
	);

	// Debug
	/*
	useEffect(() => {
		console.log(JSON.parse(JSON.stringify(playState)));
	}, [playState]);
	*/

	const showLobby = useMemo(
		() =>
			playState.roomId &&
			playState.statusWithProgress.name === PLAY_STATUS_LOBBY &&
			(playState.hostState.quiz || playState.hostState.quickMode || playState.hostState.aiGenerating),
		[
			playState.hostState.aiGenerating,
			playState.hostState.quickMode,
			playState.hostState.quiz,
			playState.roomId,
			playState.statusWithProgress.name,
		]
	);

	const [banned, setBanned] = useState(false);

	const [showAvatarCustomize, setShowAvatarCustomize] = useState(
		!banned &&
			!playState.player.created &&
			(playState.hostState.quickMode || playState.hostState.quiz || playState.hostState.aiGenerating)
	);

	useEffect(() => {
		if (
			!playState.player.created &&
			(playState.hostState.quickMode || playState.hostState.quiz || playState.hostState.aiGenerating) &&
			!banned
		) {
			setShowAvatarCustomize(true);
		}
	}, [
		playState.player.created,
		playState.hostState.quiz,
		banned,
		playState.hostState.quickMode,
		playState.hostState.aiGenerating,
	]);

	useEffect(() => {
		if (banned) {
			setShowAvatarCustomize(false);
		}
	}, [banned]);

	const showGameEnded = useMemo(
		() => playState.statusWithProgress.name === PLAY_STATUS_EXIT,
		[playState.statusWithProgress.name]
	);
	const showLeaderboard = useMemo(
		() => playState.statusWithProgress.name === PLAY_STATUS_SHOW_LEADERBOARD && hostState.leaderboard,
		[hostState.leaderboard, playState.statusWithProgress.name]
	);
	const showWinner = useMemo(
		() =>
			[PLAY_STATUS_SHOW_WINNER, PLAY_STATUS_SHOW_RATE, PLAY_STATUS_RATE_DONE].includes(
				playState.statusWithProgress.name
			) && hostState.leaderboard,
		[hostState.leaderboard, playState.statusWithProgress.name]
	);
	const showRecommendation = useMemo(
		() => PLAY_STATUSES_VOTE.includes(playState.statusWithProgress.name),
		[playState.statusWithProgress.name]
	);
	const showPoints = useMemo(
		() =>
			[
				PLAY_STATUS_SHOW_LEADERBOARD,
				PLAY_STATUS_SHOW_WINNER,
				PLAY_STATUS_SHOW_RATE,
				PLAY_STATUS_RATE_DONE,
			].includes(playState.statusWithProgress.name),
		[playState.statusWithProgress.name]
	);
	const showBrowsingQuizzes = useMemo(() => playState.browsingQuizzes, [playState.browsingQuizzes]);

	const [showRateFragment, setShowRateFragment] = useState(false);
	useEffect(() => {
		if (isSpectatingRef.current) {
			return;
		}

		if (![PLAY_STATUS_SHOW_RATE, PLAY_STATUS_RATE_DONE].includes(playState.statusWithProgress.name)) {
			setShowRateFragment(false);
		} else if (playState.statusWithProgress.name === PLAY_STATUS_SHOW_RATE) {
			setShowRateFragment(true);
		}
	}, [playState.statusWithProgress.name]);

	const headerHeight = useMemo(
		() => ((!showLobby || playState.player.created) && showPoints ? 56 : 0),
		[playState.player.created, showLobby, showPoints]
	);

	const statusWithProgress = useMemo(() => playState.statusWithProgress, [playState.statusWithProgress]);
	const updateStatusWithProgress = useCallback(
		(func) => void updatePlayState((draft) => void func(draft.statusWithProgress)),
		[updatePlayState]
	);
	useStatusWithProgress(statusWithProgress, updateStatusWithProgress, isPaused);

	const statusWithProgressRef = useRef(statusWithProgress);
	useEffect(() => void (statusWithProgressRef.current = statusWithProgress), [statusWithProgress]);

	const [myVoteQuizId, setMyVoteQuizId] = useState(null);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_VOTE_PREP) {
			setMyVoteQuizId(null);
		}
	}, [statusWithProgress?.name]);

	const onAnswer = useCallback(
		(answerSlideIndex, answer, callback = null, unsubmitted = false) => {
			if (slide?.type === SLIDE_TYPE_TYPE_ANSWER) {
				answer = answer.trim();
			}
			if (slideIndex === answerSlideIndex) {
				const { sendRoomMessage, getConnectionTimestamp } = useWebSocketStore.getState();
				const serverTimestamp = getConnectionTimestamp();
				const roomMessage = {
					answer: {
						slideIndex: answerSlideIndex,
						answer,
						progress: statusWithProgressRef.current.progress,
						unsubmitted,
						timestamp: Date.now(),
					},
					serverTimestamp,
				};

				updatePlayState((draft) => {
					if (slide?.type !== SLIDE_TYPE_TYPE_ANSWER) {
						draft.submittedAnswer = null;
						draft.submittedAnswerProgress = statusWithProgressRef.current.progress;
					}
				});
				if (!isSpectatingRef.current) {
					sendRoomMessage(roomMessage);
				}
			}
		},
		[slideIndex, slide?.type, updatePlayState]
	);

	const [layoutBackground, updateLayoutBackground] = useImmer({
		color: PETROL_DARK,
		duration: 0,
	});
	useEffect(() => {
		let backgroundColor = null;

		if (slideIndex >= 0) {
			backgroundColor = BACKGROUND_COLORS[Math.max(slideIndex, 0) % BACKGROUND_COLORS.length];
		}

		if (hostState.aiMode) {
			backgroundColor = PETROL_DARKEST;
		} else if (statusWithProgress.name === PLAY_STATUS_LOBBY) {
			backgroundColor = PETROL_DARK;
		} else {
			if (
				slide?.type === SLIDE_TYPE_TYPE_ANSWER &&
				[
					PLAY_STATUS_WAIT_FOR_ANSWER,
					PLAY_STATUS_SHOW_CORRECT_ANSWER,
					PLAY_STATUS_SHOW_AVATAR_COLORS,
					PLAY_STATUS_ALL_ANSWERS_RECEIVED,
					PLAY_STATUS_SHOW_AVATAR_CORRECTNESS,
				].includes(statusWithProgress.name)
			) {
				if (playState.typeAnswerIsCorrect) {
					backgroundColor = GREEN_LIGHT;
				} else if (playState.typeAnswerIsClose) {
					backgroundColor = YELLOW;
				} else if (playState.typeAnswerIsCorrect === false) {
					backgroundColor = PINK;
				}
			}
		}

		if (!backgroundColor) {
			backgroundColor = PETROL_DARK;
		}

		const backgroundTransitionDuration = 0.5;

		updateLayoutBackground((draft) => {
			draft.color = backgroundColor;
			draft.duration = backgroundTransitionDuration;
		});
	}, [
		playState.typeAnswerIsClose,
		playState.typeAnswerIsCorrect,
		updateLayoutBackground,
		slide?.type,
		slideIndex,
		statusWithProgress.name,
		hostState.aiMode,
	]);

	const fadeInMusic = useAudioStore((state) => state.fadeInMusic);
	const fadeOutMusic = useAudioStore((state) => state.fadeOutMusic);

	const [slideMediaIsPlayingWithSound, setSlideMediaIsPlayingWithSound] = useState(false);
	const [funFactMediaIsPlayingWithSound, setFunFactMediaIsPlayingWithSound] = useState(false);

	useEffect(() => {
		if (slideMediaIsPlayingWithSound || funFactMediaIsPlayingWithSound) {
			fadeOutMusic();
		} else {
			fadeInMusic();
		}
	}, [fadeInMusic, fadeOutMusic, funFactMediaIsPlayingWithSound, slideMediaIsPlayingWithSound]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_FLUSH) {
			sfx.stop();
		}
	}, [statusWithProgress.name]);

	const numberOfPlayers = useMemo(() => getConnectedPlayers(playersArray, false).length, [playersArray]);
	const numberOfPlayersAnswered = useMemo(
		() => playersArray.filter((player) => playState.hostState.slideIndex in player.history).length,
		[playState.hostState.slideIndex, playersArray]
	);

	const setRating = useCallback(
		(rating, quizId) => {
			const { sendRoomMessage } = useWebSocketStore.getState();
			if (!isSpectatingRef.current) {
				sendRoomMessage({ rating: { quizId, rating } });
			}

			updatePlayState((draft) => {
				draft.player.ratings[quizId] = rating;
			});
		},
		[updatePlayState]
	);

	const [canRate, setCanRate] = useState(false);

	const rateQuizzes = useRateQuizzes(playState.hostState.quiz?.slides);

	const onStart = useCallback(() => {}, []);
	const onLocalJoin = useCallback(
		() =>
			void updatePlayState((draft) => {
				draft.player.created = false;
			}),
		[updatePlayState]
	);

	const voteQueueRef = useRef(new Map());
	const voteQueueTimeoutRef = useRef(null);
	const voteQueuePreviousRef = useRef(Date.now());
	const onVote = useCallback(
		(quizId) => {
			voteQueueRef.current.set(quizId, (voteQueueRef.current.get(quizId) || 0) + 1);
			if (!voteQueueTimeoutRef.current && mountedRef.current) {
				const timeout = Math.min(
					Math.max(VOTE_THROTTLE_TIMEOUT - (Date.now() - voteQueuePreviousRef.current), 0),
					VOTE_THROTTLE_TIMEOUT
				);
				voteQueuePreviousRef.current = Date.now();

				voteQueueTimeoutRef.current = setTimeout(() => {
					voteQueueTimeoutRef.current = null;
					for (const [quizId, votes] of voteQueueRef.current.entries()) {
						if (!isSpectatingRef.current) {
							useWebSocketStore.getState().sendRoomMessage({ voteQuizId: quizId, voteQuizCount: votes });
						}
					}
					voteQueueRef.current.clear();
				}, timeout);
			}
			setMyVoteQuizId(quizId);
		},
		[mountedRef]
	);

	useEffect(() => {
		voteQueueRef.current.clear();
		if (voteQueueTimeoutRef.current) {
			clearTimeout(voteQueueTimeoutRef.current);
			voteQueueTimeoutRef.current = null;
		}
	}, [hostState.voteMode]);

	const myTeamId = useMemo(() => players.get(connectionId)?.teamId, [connectionId, players]);
	const myTeam = useMemo(() => myTeamId && teams.get(myTeamId), [myTeamId, teams]);

	return (
		<ScaleWrapper className="flex flex-col justify-center flex-1 select-none">
			<LayoutBackground color={layoutBackground.color} duration={layoutBackground.duration} />
			{["local", "dev"].includes(process.env.NEXT_PUBLIC_INSTANCE_NAME) && (
				<StateView name={statusWithProgress.name} progress={statusWithProgress.progress} n={5} />
			)}
			<BackgroundAnimation
				quiz={hostState.quiz}
				slideIndex={slideIndex}
				statusName={statusWithProgress?.name}
				aiMode={hostState.aiMode}
				backgroundAnimationEnabled={settings.backgroundAnimationEnabled}
				reducedMotion={hostState.settings.reducedMotion}
			/>

			{!banned && isPaused && !showLobby && playState.gameStatus === GAME_STATUS_NONE && (
				<>{isConnecting ? <GameConnecting /> : <GamePaused />}</>
			)}

			{banned && (
				<Modal className="sm:p-4 bg-petrol-dark sm:bg-black sm:bg-opacity-80 z-1 p-0 bg-opacity-100">
					<div className="bg-petrol-dark sm:rounded-2xl sm:h-auto sm:max-w-xl w-full h-full m-auto rounded-none">
						<div className="relative flex flex-col items-center justify-center w-full h-full px-4 py-16 space-y-4">
							<Header>{trans("You are banned from this room!")}</Header>
							<div className="flex flex-row items-center justify-center text-base font-bold text-white opacity-50">
								<TimeToGo
									date={banned + BANNED_TIMEOUT}
									prefix="You will be able to rejoin in:"
									suffix=""
								/>
							</div>
							<div className="pt-4">
								<Button onClick={onClickTryAgain} color="green-light" className="w-full text-white">
									{trans("Try again")}
								</Button>
							</div>
						</div>
					</div>
				</Modal>
			)}

			{showAvatarCustomize && (
				<Modal className="sm:p-4 bg-petrol-dark sm:bg-black sm:bg-opacity-80 z-1 p-0 bg-opacity-100">
					<div className="bg-petrol-dark sm:rounded-2xl sm:h-auto sm:max-w-xl w-full h-full m-auto rounded-none">
						<AvatarCustomizeFragment
							roomId={roomId}
							name={playerName}
							safeName={playerSafeName}
							avatar={playerAvatar}
							showDone={true}
							onDone={onDoneAvatarCustomize}
							onChangeName={onChangeNameAvatarCustomize}
							onChangeSafeName={onChangeSafeNameAvatarCustomize}
							onChangeAvatar={onChangeAvatarAvatarCustomize}
							onChangeTeam={onChangeTeamAvatarCustomize}
							useSafeNames={settings.safeNames}
							statusWithProgressName={playState.statusWithProgress.name}
							teamMode={settings.teamMode}
							teams={teams}
							selectTeamEnabled={selectTeamEnabled.current}
							playerTeamId={playState.player.teamId}
						/>
					</div>
				</Modal>
			)}

			<div
				className={tailwindCascade(
					"relative",
					"z-10",
					"flex",
					"flex-col",
					"w-full",
					"h-full",
					"pt-9",
					"md:pt-12"
				)}
			>
				<InfoBar
					connected={true}
					isHost={false}
					playMode={true}
					statusName={statusWithProgress?.name}
					code={playState?.roomId}
					quizName={hostState.quiz?.name}
					slideType={slide?.type}
					slideIndex={slideIndex}
					numberOfPlayers={numberOfPlayers}
					numberOfPlayersAnswered={numberOfPlayersAnswered}
					numberOfSlides={hostState.quiz?.slides.length}
					showCode={settings.showCode}
					joinOpen={settings.joinOpen}
					mute={settings.mute}
					quickMode={hostState.quickMode}
					numQuestionsPerRound={hostState.settings.numQuestionsPerRound}
				/>
				{(!showLobby || playState.player.created) && showPoints && (
					<div className={tailwindCascade("absolute left-0 md:top-10 top-8")}>
						<PlayerScore
							name={playState.player.name}
							points={playState.player.points}
							rank={playState.player.rank}
							teamName={myTeam?.name}
						/>
					</div>
				)}
				<div
					className={tailwindCascade(
						"w-full h-full flex-grow",
						"flex flex-col items-center justify-center",
						"relative"
					)}
				>
					{(() => {
						if (playState.gameStatus !== GAME_STATUS_NONE) {
							return <GameStatusFragment gameStatus={playState.gameStatus} />;
						}

						if (showBrowsingQuizzes) {
							return <BrowsingQuizzesFragment />;
						}

						if (
							[PLAY_STATUS_SHOW_GET_READY, PLAY_STATUS_HIDE_GET_READY].includes(statusWithProgress?.name)
						) {
							return <GetReady visible={statusWithProgress?.name === PLAY_STATUS_SHOW_GET_READY} />;
						}

						if (showLobby) {
							return (
								<div className="md:justify-center flex flex-col items-center justify-start w-full h-full select-text">
									<Lobby
										roomId={roomId}
										onStart={onStart}
										onLocalJoin={onLocalJoin}
										quiz={hostState.quiz}
										newPlaySession={false}
										quickMode={hostState.quickMode}
										isHost={false}
										showCode={settings.showCode}
										joinOpen={settings.joinOpen}
										mutePlayerNames={hostState.settings.mutePlayerNames}
										reducedMotion={hostState.settings.reducedMotion}
										query={hostState.aiQuery}
										aiMode={hostState.aiMode}
										aiGenerating={hostState.aiGenerating}
										aiGeneratingInfo={hostState.aiGeneratingInfo}
										aiProcessTime={hostState.aiProcessTime}
										progress={hostState.aiProgress}
										isSpectating={isSpectating}
										players={players}
										mute={settings.mute}
										teamMode={settings.teamMode}
										teams={teams}
									/>
								</div>
							);
						}
						if (showWinner) {
							return (
								<>
									<RateModal
										canRate={canRate}
										quizzes={rateQuizzes}
										ratings={playState.player.ratings}
										setRating={setRating}
										showRateFragment={showRateFragment}
									/>
									<GameEnd
										isHost={false}
										leaderboard={hostState.leaderboard}
										statusWithProgress={playState.statusWithProgress}
										setCanRate={setCanRate}
										reducedMotion={hostState.settings.reducedMotion}
										hidePlayerCountry={
											hostState.settings.teamMode ? true : hostState.settings.hidePlayerCountry
										}
									/>
								</>
							);
						}
						if (showRecommendation) {
							return (
								<>
									<RateModal
										canRate={canRate}
										quizzes={rateQuizzes}
										ratings={playState.player.ratings}
										setRating={setRating}
										showRateFragment={showRateFragment}
									/>
									<QuizVote
										slideIndex={slideIndex}
										isPaused={isPaused}
										nextQuizId={hostState.nextQuiz}
										nonPlayingHostVoteQuizId={hostState.nonPlayingHostVoteQuizId}
										onVote={onVote}
										players={players}
										quizzes={hostState.recommendedQuizzes}
										statusWithProgress={statusWithProgress}
										voteQuizId={myVoteQuizId}
										numQuestionsPerRound={hostState.settings.numQuestionsPerRound}
										numRoundsPerGame={hostState.settings.numRoundsPerGame}
										numberOfVotes={hostState.numberOfVotes}
										avatars={hostState.voteAvatars}
										voteMode={hostState.voteMode}
										resetTimer={playState.resetTimer}
										reducedMotion={hostState.settings.reducedMotion}
									/>
								</>
							);
						}
						if (showGameEnded) {
							return <GameEndedFragment />;
						}
						if (showLeaderboard) {
							return (
								<LeaderboardView
									entities={hostState.leaderboard}
									hidePlayerCountry={
										hostState.settings.teamMode ? true : hostState.settings.hidePlayerCountry
									}
								/>
							);
						}
						if (PLAY_STATUES_SLIDE.includes(playState.statusWithProgress.name)) {
							return (
								<div className="relative w-full h-full">
									<Slide
										countryCode={hostState.countryCode}
										doublePoints={hostState.doublePoints}
										correctAnswerFeature={correctAnswerFeature}
										haveLocalPlayer={true}
										headerHeight={headerHeight}
										hideIncorrectTypeAnswers={settings.hideIncorrectTypeAnswers}
										isHost={false}
										isPaused={isPaused}
										mute={settings.mute}
										voiceOverride={settings.voiceOverride}
										onAnswer={onAnswer}
										placeType={hostState.placeType}
										player={playState.player}
										players={players}
										revealType={playState.revealType}
										setFunFactMediaIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
										setSlideMediaIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
										setSlideTypeAnswer={setSlideTypeAnswer}
										slide={slide}
										slideIndex={slideIndex}
										statusWithProgress={statusWithProgress}
										showAnswersLocation={playState.submittedAnswer}
										submittedAnswer={playState.submittedAnswer}
										submittedAnswerProgress={playState.submittedAnswerProgress}
										typeAnswerState={typeAnswerState}
										reducedMotion={hostState.settings.reducedMotion}
										teams={teams}
										teamMode={settings.teamMode}
									/>
									<div
										id={STREET_VIEW_PORTAL_ID}
										className="md:fixed absolute top-0 left-0 w-0 h-0 overflow-hidden"
									/>
								</div>
							);
						}

						if (statusWithProgress.name === PLAY_STATUS_AI_DISCLAIMER) {
							return <AIDisclaimer quiz={hostState.quiz} visible={true} />;
						} else if (playState.statusWithProgress.name === PLAY_STATUS_BEFORE_LAST_SLIDE) {
							return <Billboard text1="Final question" text2="Double points!" visible={true} />;
						}

						return <div></div>;
					})()}
				</div>
			</div>
			<Prefetch slide={hostState.nextSlide} isHost={false} voiceOverride={hostState.settings.voiceOverride} />
		</ScaleWrapper>
	);
}

export function StateView({ name, progress, n }) {
	const [history, updateHistory] = useImmer([]);

	useEffect(() => {
		updateHistory((draft) => {
			if (draft.length >= n) {
				draft.shift();
			}
			draft.push(name);
		});
	}, [updateHistory, name, n]);

	return (
		<div className="bottom-1 left-1 z-60 bg-black-50 text-2xs absolute flex flex-col w-24 gap-1 px-1 py-1 overflow-hidden text-white rounded-md">
			{history.map((str, i) => (
				<div key={i} className="relative w-full overflow-hidden">
					<div
						className="bg-white-50 -z-1 absolute h-full"
						style={{ width: `${(i === history.length - 1 ? progress : 1) * 100}%` }}
					></div>
					<div className={tailwindCascade("truncate", { "font-black underline": i === history.length - 1 })}>
						{str}
					</div>
				</div>
			))}
		</div>
	);
}
