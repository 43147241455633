import {
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_TYPE_ANSWER,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
} from "@/app-constants.mjs";
import InfoSlide from "@/components/pages/play/slides/InfoSlide";
import BaseSlide from "./slides/BaseSlide";

export default function DesktopSlide(props) {
	switch (props?.slide?.type) {
		case SLIDE_TYPE_CLASSIC:
		case SLIDE_TYPE_CHECK_BOXES:
		case SLIDE_TYPE_TYPE_ANSWER:
		case SLIDE_TYPE_RANGE:
		case SLIDE_TYPE_REORDER:
			return <BaseSlide {...props} />;
		case SLIDE_TYPE_INFO_SLIDE:
			return <InfoSlide {...props} />;
		default:
			console.error({ props });
			return <></>;
	}
}
