import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Elastic } from "gsap";
import lerp from "lerp";
import clamp from "lodash/clamp";
import cloneDeep from "lodash/cloneDeep";

import ProgressIndicator from "@/components/ProgressIndicator";
import ScaleContainer from "@/components/pages/play/ScaleContainer";
import SlideHeader from "@/components/pages/play/SlideHeader";
import SlideMedia from "@/components/pages/play/SlideMedia";

import gsap from "@/helpers/gsap";
import { tailwindCascade } from "@/helpers/tailwindCascade";

import useBrowser from "@/hooks/useBrowser";
import useStatusSequence from "@/hooks/useStatusSequence";

import { SLIDE_TYPE_INFO_SLIDE } from "@/app-constants.mjs";
import { BLACK } from "@/colors";
import {
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_YOUTUBE_END_1,
} from "@/constants";

import TextVoice from "../../TextVoice";

const MEDIA_WIDTH = 1050;
const MARGIN_WIDTH = 32;
const MARGIN_HEIGHT = 48;

const QUESTION_MAX_HEIGHT = (3.5 / 3) * 16 * 3 * 4; // 2 lines

const elasticEaseOut = Elastic.easeOut.config(1.05, 0.75);

function defaultAnimateIn(element, progress, index = 0, length = 1) {
	if (length > 1) {
		progress = clamp(progress * (1 / 0.64) - (index / length) * 0.64, 0, 1); // Magic numbers from Papa`s Quiz
	}
	const opacity = clamp(lerp(0.0, 8.0, progress), 0.0, 1.0);
	const scale = Math.max(0.5, elasticEaseOut(progress));
	gsap.set(element, { scaleX: scale, scaleY: scale, opacity });
}

export default function InfoSlide({
	type = SLIDE_TYPE_INFO_SLIDE,
	className,
	mute,
	onLoad,
	onComplete,
	onQuestionStart,
	onError,
	slide: currentSlide,
	slideIndex,
	isPaused: paused,
	statusWithProgress,
	isHost,
	setSlideMediaIsPlayingWithSound,
	voiceOverride,
	...props
}) {
	const ref = useRef(null);
	const browser = useBrowser();

	const [mediaBySlide, setMediaBySlide] = useState(null);
	const mediaBySlideRef = useRef(mediaBySlide);
	useEffect(() => void setMediaBySlide(cloneDeep(currentSlide.media)), [currentSlide]);
	useEffect(() => void (mediaBySlideRef.current = mediaBySlide), [mediaBySlide]);

	const hasQuestion = currentSlide && currentSlide.question;
	const hasVoice = currentSlide && currentSlide.id && currentSlide.questionVoice && currentSlide.question;
	const hasMedia = currentSlide && currentSlide.media;

	const mediaRef = useRef();

	const onQuestionStartRef = useRef(onQuestionStart);
	useEffect(() => void (onQuestionStartRef.current = onQuestionStart), [onQuestionStart]);

	const { getVisibility, getProgress } = useStatusSequence(SLIDE_TYPE_INFO_SLIDE, statusWithProgress);

	const showQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);

	// console.log("readQuestion", readQuestion, showQuestion, statusWithProgress.name);

	const questionProgress = useMemo(() => getProgress(PLAY_STATUS_SHOW_QUESTION), [getProgress]);

	const showMedia = useMemo(() => getVisibility(PLAY_STATUS_SHOW_MEDIA, PLAY_STATUS_SHOW_FUN_FACT), [getVisibility]);

	const showProgressBar = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);
	const waitForAnswerProgress = useMemo(() => getProgress(PLAY_STATUS_WAIT_FOR_ANSWER), [getProgress]);

	const [readQuestion, setReadQuestion] = useState(false);
	useEffect(() => {
		if (showQuestion && statusWithProgress.name !== PLAY_STATUS_WAIT_FOR_ANSWER) {
			setReadQuestion(true);
		}
	}, [showQuestion, statusWithProgress.name]);

	const noAnimation = useMemo(
		() => !getVisibility(PLAY_STATUS_SHOW_MEDIA, PLAY_STATUS_WAIT_FOR_ANSWER),
		[getVisibility]
	);

	const slideMediaVolumeEnvelope = 1 - getProgress(PLAY_STATUS_YOUTUBE_END_1);

	const interactiveStreetView = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);

	useEffect(() => {
		if (!hasMedia && onLoad) {
			onLoad();
		}
	}, [hasMedia, onLoad]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_WAIT_FOR_MEDIA) {
			if (mediaRef.current) {
				let canceled = false;
				mediaRef.current
					.play()
					.then(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					})
					.catch(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					});
				return () => void (canceled = true);
			}
		}
	}, [statusWithProgress.name, statusWithProgress.progress]);

	const mediaWrapperRef = useRef(null);
	const mediaContainerRef = useRef(null);

	const mediaContainerHeight = MEDIA_WIDTH * (mediaBySlide && mediaBySlide.type === "youtube" ? 9 / 16 : 3 / 4);
	const [slideMediaContainerStyle, setSlideMediaContainerStyle] = useState({
		height: `${mediaContainerHeight}px`,
		marginBottom: `${MARGIN_WIDTH}px`,
		border: "0.25rem solid #000",
		borderRadius:
			mediaBySlide && mediaBySlide.type === "youtube" && browser && browser.satisfies({ safari: ">=1" })
				? "0"
				: "1.5rem",
	});
	useEffect(
		() =>
			void setSlideMediaContainerStyle({
				height: `${mediaContainerHeight}px`,
				marginBottom: `${MARGIN_WIDTH}px`,
				border: "0.25rem solid #000",
				borderRadius:
					mediaBySlide && mediaBySlide.type === "youtube" && browser && browser.satisfies({ safari: ">=1" })
						? "0"
						: "1.5rem",
			}),
		[mediaContainerHeight, mediaBySlide, browser]
	);

	return (
		<>
			{hasVoice && (
				<TextVoice
					isHost={isHost}
					slideId={currentSlide.id}
					play={readQuestion}
					savedVoice={currentSlide.questionVoice}
					text={currentSlide.question}
					voiceOverride={voiceOverride}
				/>
			)}
			<ScaleContainer ref={ref} marginBottom={false}>
				<div ref={mediaWrapperRef} className="relative flex justify-center w-full h-full">
					<div className="flex flex-col items-center justify-center w-full">
						{hasMedia && (
							<div ref={mediaContainerRef} style={{ width: `${MEDIA_WIDTH}px` }}>
								<div
									className={tailwindCascade(
										"relative",
										"w-full",
										"bg-black",
										"overflow-hidden",
										"rounded-lg",
										{
											"opacity-0 transition-opacity duration-300": !showMedia,
											"animate-media": showMedia && !noAnimation,
											"pointer-events-none": !showMedia,
											"sr-only": !showMedia,
										}
									)}
									style={slideMediaContainerStyle}
								>
									<SlideMedia
										ref={mediaRef}
										paused={paused}
										onLoad={onLoad}
										onError={onError}
										{...mediaBySlide}
										mute={mute}
										setIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
										envelope={slideMediaVolumeEnvelope}
										progress={waitForAnswerProgress}
										visible={showMedia}
										interactiveStreetView={interactiveStreetView}
									/>
								</div>
							</div>
						)}

						{hasQuestion && (
							<div className="flex items-center justify-center w-full">
								<div style={{ width: `${MEDIA_WIDTH}px` }}>
									<div className="relavive flex flex-col items-center justify-center w-full h-full">
										<SlideHeader
											className={tailwindCascade("relative", "w-full", "h-full", {
												"opacity-0 transition-opacity duration-300": !showQuestion,
												"pointer-events-none": !showQuestion,
											})}
											maxHeight={hasMedia ? QUESTION_MAX_HEIGHT : null}
											progress={questionProgress}
										>
											{currentSlide.question}
										</SlideHeader>
									</div>
								</div>
							</div>
						)}

						<div style={{ width: `${MEDIA_WIDTH}px` }}>
							<div className="h-8" style={{ marginTop: hasQuestion ? `${MARGIN_HEIGHT}px` : 0 }}>
								<ProgressIndicator
									progress={waitForAnswerProgress}
									className="w-full h-8"
									paused={paused}
									visible={showProgressBar}
									isQuestion={false}
								/>
							</div>
						</div>
					</div>
				</div>
			</ScaleContainer>
		</>
	);
}
