import { Player, PlayerOrTeam } from "./player";
import { v4 as uuidV4 } from "uuid";
import getRandomAvatarFeatures from "@/helpers/getRandomAvatarFeatures";
import { AVATAR_FEATURE_PROPS, PLAYER_AVATAR_CANVAS_SIZE, PLAYER_AVATAR_HATS } from "@/constants";
import { random } from "lodash";

export interface Team extends PlayerOrTeam {
	avatarLayers?: Array<any>;
	numPlayers?: number;
}

export function createTeam(name: string, sortIndex: number) {
	const team: Team = {
		connectionId: uuidV4(),
		name,
		points: 0,
		previousPoints: 0,
		history: [],
	};

	return team;
}

export function createAvatar() {
	let initialAvatar = null;
	const features = getRandomAvatarFeatures();
	for (let i = 0; i < features.length; i++) {
		const feature = features[i].feature;
		const index = features[i].index;
		let url = null;
		let x = 0;
		let y = 0.5;
		let z = 1;

		if (AVATAR_FEATURE_PROPS.has(feature)) {
			const featureProps = AVATAR_FEATURE_PROPS.get(feature);
			url = featureProps.urls[index];
			z = featureProps.defaultScale;
			x = (PLAYER_AVATAR_CANVAS_SIZE - PLAYER_AVATAR_CANVAS_SIZE * z) / 2;
			y = featureProps.defaultY * PLAYER_AVATAR_CANVAS_SIZE;
		}

		if (url) {
			if (!initialAvatar) {
				initialAvatar = [];
			}

			initialAvatar.push({
				url,
				x: x.toFixed(4),
				y: y.toFixed(4),
				z: z.toFixed(4),
			});
		}
	}

	return initialAvatar;
}

export function createTeamAvatar(omitUrls = []) {
	let filteredHats = PLAYER_AVATAR_HATS.filter((hat) => !omitUrls.includes(hat));
	if (filteredHats.length === 0) {
		filteredHats = PLAYER_AVATAR_HATS;
	}
	const hat = random(filteredHats.length - 1);
	const url = filteredHats[hat];

	const z = 0.75;
	const offset = 0.25;
	return [{
		url,
		x: (PLAYER_AVATAR_CANVAS_SIZE - PLAYER_AVATAR_CANVAS_SIZE * z) / 2,
		y: (PLAYER_AVATAR_CANVAS_SIZE - PLAYER_AVATAR_CANVAS_SIZE * z + PLAYER_AVATAR_CANVAS_SIZE * offset) / 2,
		z,
	}];
}
