const global = typeof window !== "undefined" ? window : null;
const navigator = global ? global.navigator : null;
const document = global ? global.document : null;

// Detect native Wake Lock API support
const nativeWakeLock = navigator && "wakeLock" in navigator;

class NoSleep {
	initialize() {
		this.destroy();

		if (nativeWakeLock) {
			if (document) {
				this._wakeLock = null;

				const self = this;
				const handleVisibilityChange = () => {
					if (self._wakeLock !== null && document.visibilityState === "visible") {
						self.enable();
					}
				};

				document.addEventListener("visibilitychange", handleVisibilityChange);
				document.addEventListener("fullscreenchange", handleVisibilityChange);
			}
		}
	}

	enable() {
		this.disable();

		if (nativeWakeLock) {
			const self = this;
			navigator.wakeLock
				.request("screen")
				.then((wakeLock) => {
					self._wakeLock = wakeLock;
					if (self._wakeLock) {
						self._wakeLock.addEventListener("release", () => {
							// TODO: Potentially emit an event for the page to observe since
							// Wake Lock releases happen when page visibility changes.
							// (https://web.dev/wakelock/#wake-lock-lifecycle)
						});
					}
				})
				.catch((_error) => {
					//
				});
		}
	}

	disable() {
		if (nativeWakeLock) {
			if (this._wakeLock) {
				this._wakeLock.release();
			}
			this._wakeLock = null;
		}
	}

	destroy() {
		this.disable();
	}
}

const noSleep = new NoSleep();

export default noSleep;
