import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	PLAY_STATUS_LOAD_SLIDE,
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_SHOW_ANSWERS,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_YOUTUBE_END_1,
	PLAY_STATUS_YOUTUBE_END_2,
	PLAY_STATUS_SHOW_QUIZNAME,
	PLAY_STATUS_SHOW_RESULTS,
	PLAY_STATUS_HIDE_INPUT,
	PLAY_STATUS_ALL_ANSWERS_RECEIVED,
} from "@/constants";
import MobileAnswers from "./MobileAnswers";
import MobileMedia from "./MobileMedia";
import MobileQuestion from "./MobileQuestion";
import Header from "@/components/Header";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import useViewportSize from "@/hooks/useViewportSize";
import {
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_TYPE_ANSWER,
	SLIDE_TYPES,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_RANGE,
} from "@/app-constants.mjs";
import useStatusSequence from "@/hooks/useStatusSequence";
import ProgressIndicator from "../../ProgressIndicator";
import WrongTypeAnswers from "./slides/WrongTypeAnswers";
import clamp from "lodash/clamp";

import TextVoice from "../TextVoice";

const MobileSlideMedia = forwardRef(function MobileSlideMedia(
	{
		slide,
		statusWithProgress,
		mediaStyle,
		bigMedia,
		setBigMedia,
		isPaused,
		mute,
		setSlideMediaIsPlayingWithSound,
		setFunFactMediaIsPlayingWithSound,
		onLoad,
		onQuestionStart,
		onError,
		onFunFactEnd,
		noAnimation,
	},
	mediaSpaceRef
) {
	const { getVisibility, getProgress } = useStatusSequence(slide.type, statusWithProgress);

	const hasFunFactMedia = useMemo(() => !!slide?.funFactMedia, [slide]);
	const hasFunFactText = useMemo(() => !!slide?.funFact, [slide]);

	const showRangeInput = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_ALL_ANSWERS_RECEIVED),
		[getVisibility]
	);

	const showFunFact = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_FUN_FACT, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);

	const showFunFactMedia = useMemo(
		() => hasFunFactMedia && getVisibility(PLAY_STATUS_SHOW_FUN_FACT, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility, hasFunFactMedia]
	);

	const showMedia = useMemo(
		() =>
			getVisibility(
				PLAY_STATUS_SHOW_MEDIA,
				hasFunFactMedia ? PLAY_STATUS_SHOW_FUN_FACT : PLAY_STATUS_HIDE_FUN_FACT
			),
		[getVisibility, hasFunFactMedia]
	);

	const mountMedia = useMemo(
		() =>
			getVisibility(
				PLAY_STATUS_LOAD_SLIDE,
				hasFunFactMedia ? PLAY_STATUS_SHOW_FUN_FACT : PLAY_STATUS_HIDE_FUN_FACT
			),
		[getVisibility, hasFunFactMedia]
	);

	const waitForAnswerProgress = useMemo(() => getProgress(PLAY_STATUS_WAIT_FOR_ANSWER), [getProgress]);

	const slideMediaVolumeEnvelope =
		1 - getProgress(hasFunFactText && !hasFunFactMedia ? PLAY_STATUS_YOUTUBE_END_2 : PLAY_STATUS_YOUTUBE_END_1);

	const funFactMediaVolumeEnvelope = 1 - getProgress(PLAY_STATUS_HIDE_FUN_FACT);

	const interactiveStreetView = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);

	return (
		<div
			ref={mediaSpaceRef}
			className={tailwindCascade("relative w-full select-none flex flex-col items-center justify-start", {
				"opacity-0": slide.type === SLIDE_TYPE_RANGE && !showRangeInput && !showFunFact,
			})}
		>
			<button
				className={tailwindCascade("relative w-full overflow-hidden cursor-default mx-auto", {
					"transition-[width,height] duration-[0.2s]": mediaStyle,
				})}
				onClick={() => void setBigMedia(!bigMedia)}
				style={mediaStyle}
			>
				{mountMedia || showMedia ? (
					<MobileMedia
						className="absolute top-0 left-0"
						isPaused={isPaused}
						media={slide.media}
						mute={mute}
						visible={slide.type === SLIDE_TYPE_RANGE ? showRangeInput : showMedia}
						onLoad={onLoad}
						onQuestionStart={onQuestionStart}
						statusWithProgress={statusWithProgress}
						onYoutubeClick={() => void setBigMedia(!bigMedia)}
						onError={onError}
						dimMedia={!hasFunFactMedia && showFunFact && !!slide.funFact}
						progress={waitForAnswerProgress}
						setIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
						envelope={slideMediaVolumeEnvelope}
						interactiveStreetView={interactiveStreetView}
						noAnimation={noAnimation}
					/>
				) : showFunFactMedia ? (
					<MobileMedia
						className="absolute top-0 left-0"
						isPaused={isPaused}
						media={slide.funFactMedia}
						mute={mute}
						visible={slide.type === SLIDE_TYPE_RANGE ? showRangeInput : showFunFactMedia}
						onLoad={onLoad}
						onQuestionStart={onQuestionStart}
						onYoutubeEnd={onFunFactEnd}
						statusWithProgress={statusWithProgress}
						onYoutubeClick={() => void setBigMedia(!bigMedia)}
						startEvent={PLAY_STATUS_SHOW_FUN_FACT}
						stopEvents={[PLAY_STATUS_HIDE_FUN_FACT, PLAY_STATUS_YOUTUBE_END_2]}
						dimMedia={!!slide.funFact}
						setIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
						envelope={funFactMediaVolumeEnvelope}
						interactiveStreetView={interactiveStreetView}
					/>
				) : (
					<></>
				)}
			</button>
			<div className="absolute inset-0 pointer-events-none">
				{slide.funFact && showFunFact && (
					<div
						className={tailwindCascade(
							"left-1/2",
							"top-1/2",
							"absolute",
							"w-full",
							"pt-0",
							"transform",
							"-translate-x-1/2",
							"-translate-y-1/2",
							{ "opacity-0": !showFunFact }
						)}
					>
						<div className="relative p-4">
							<Header className={tailwindCascade("text-center", "w-full", "text-xl", "leading-none")}>
								{slide.funFact}
							</Header>
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

export default function MobileSlide({
	doublePoints,
	haveLocalPlayer = true,
	headerHeight = 0,
	hideIncorrectTypeAnswers,
	isHost,
	isPaused,
	mute,
	onComplete, // host only
	onFunFactEnd, // host only
	onLoad, // host only
	onError, // host only
	onAnswer,
	onQuestionStart, // host only
	players = [],
	setFunFactMediaIsPlayingWithSound,
	setPaused, // host only
	setPlaylistSkip, // host only
	setSlideMediaIsPlayingWithSound,
	setSlideTypeAnswer,
	slide,
	slideIndex,
	statusWithProgress,
	submittedAnswer,
	submittedAnswerProgress,
	typeAnswerState,
	voiceOverride,
	reducedMotion,
	teams,
	teamMode,
	...props
}) {
	const slideRef = useRef();
	const mediaSpaceRef = useRef(null);

	const [answer, setAnswer] = useState(null);
	const onAnswerInternal = useCallback(
		(answerSlideIndex, answer) => void setAnswer({ answerSlideIndex, answer }),
		[]
	);
	const onAnswerRef = useRef(onAnswer);
	useEffect(() => void (onAnswerRef.current = onAnswer), [onAnswer]);
	useEffect(() => {
		if (answer && statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER && !submittedAnswer) {
			if (onAnswerRef.current) {
				onAnswerRef.current(answer.answerSlideIndex, answer.answer);
			}
			setAnswer(null);
		}
	}, [answer, statusWithProgress.name, submittedAnswer]);

	useEffect(() => {
		if (slide && !slide.media && onLoad) {
			onLoad();
		}
	}, [slide, onLoad]);

	const { getVisibility, getProgress } = useStatusSequence(slide.type, statusWithProgress);

	const readQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_SHOW_ANSWERS),
		[getVisibility]
	);

	const noAnimation = useMemo(
		() => !getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_WAIT_FOR_ANSWER),
		[getVisibility]
	);

	const noAnimationInfoSlide = useMemo(
		() => !getVisibility(PLAY_STATUS_SHOW_MEDIA, PLAY_STATUS_WAIT_FOR_ANSWER),
		[getVisibility]
	);

	const showQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);
	const questionProgress = useMemo(() => getProgress(PLAY_STATUS_SHOW_QUESTION), [getProgress]);

	const showFunFact = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_FUN_FACT, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);

	const showProgressBar = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);
	const waitForAnswerProgress = useMemo(() => getProgress(PLAY_STATUS_WAIT_FOR_ANSWER), [getProgress]);

	const showAnswers = useMemo(() => getVisibility(PLAY_STATUS_SHOW_ANSWERS, PLAY_STATUS_HIDE_SLIDE), [getVisibility]);

	const showCorrectAnswers = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_CORRECT_ANSWER, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);

	const showRangeResults = useMemo(() => getVisibility(PLAY_STATUS_SHOW_RESULTS), [getVisibility]);
	const showRangeInput = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_ALL_ANSWERS_RECEIVED),
		[getVisibility]
	);

	const showWrongTypeAnswers = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);

	const showQuizName = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUIZNAME, PLAY_STATUS_SHOW_QUESTION),
		[getVisibility]
	);

	const hideContainer = useMemo(() => getVisibility(PLAY_STATUS_HIDE_SLIDE), [getVisibility]);

	const [_bigMedia, setBigMedia] = useState(undefined);
	const bigMedia = useMemo(() => _bigMedia || slide?.media?.type === "street", [_bigMedia, slide?.media?.type]);

	const viewportSize = useViewportSize();

	const mediaStyle = useMemo(() => {
		if (slideRef.current && mediaSpaceRef.current && viewportSize) {
			let maxHeight = viewportSize.height - 32;
			for (const node of slideRef.current.childNodes) {
				// deduct the height (and gap) of other elements in the containing flex column
				if (node !== mediaSpaceRef.current && isFinite(node.offsetHeight)) {
					maxHeight = maxHeight - node.offsetHeight - 32;
				}
			}
			const maxWidth = viewportSize.width - 32;
			const minWidth = maxWidth / 2;

			const medias = [];
			if (slide?.media) {
				medias.push(slide.media);
			}
			if (slide?.funFactMedia) {
				medias.push(slide.funFactMedia);
			}
			const aspectRatio = medias.length > 0 && medias.every(({ type }) => type === "youtube") ? 16 / 9 : 4 / 3;

			const width = bigMedia ? maxWidth : clamp(maxHeight * aspectRatio, minWidth, maxWidth);
			const height = width / aspectRatio;

			return {
				width,
				height,
			};
		} else {
			return null;
		}
	}, [bigMedia, slide?.funFactMedia, slide?.media, viewportSize]);

	useEffect(() => {
		if (slide?.type === SLIDE_TYPE_RANGE && showFunFact) {
			setBigMedia(true);
		}
	}, [showFunFact, slide?.type]);

	return (
		<>
			<div
				ref={slideRef}
				className={tailwindCascade(
					"relative",
					"w-screen",
					"min-h-full",
					"justify-evenly",
					"flex",
					"flex-col",
					"items-stretch",
					"space-y-1",
					"opacity-100",
					"px-4",
					"py-2",
					"gap-3",
					{
						"opacity-0": hideContainer,
						"justify-start mt-4": slide.type === SLIDE_TYPE_TYPE_ANSWER,
						"justify-center": slide.type === SLIDE_TYPE_INFO_SLIDE,
					}
				)}
			>
				{slide?.quiz?.name && (
					<>
						<TextVoice
							isHost={isHost}
							slideId={slide.id}
							play={showQuizName}
							savedVoice={"Charles"}
							voiceOverride={voiceOverride}
							text={slide.quiz.name}
						/>
						<Header
							className={tailwindCascade("flex flex-col items-center justify-center text-center", {
								hidden: !showQuizName,
							})}
						>
							{slide.quiz.name}
						</Header>
					</>
				)}
				{slide.type !== SLIDE_TYPE_TYPE_ANSWER && (
					<MobileSlideMedia
						ref={mediaSpaceRef}
						slide={slide}
						statusWithProgress={statusWithProgress}
						mediaStyle={mediaStyle}
						bigMedia={bigMedia}
						setBigMedia={setBigMedia}
						isPaused={isPaused}
						mute={mute}
						setSlideMediaIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
						setFunFactMediaIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
						onLoad={onLoad}
						onQuestionStart={onQuestionStart}
						onError={onError}
						onFunFactEnd={onFunFactEnd}
						noAnimation={slide.type === SLIDE_TYPE_INFO_SLIDE ? noAnimationInfoSlide : noAnimation}
					/>
				)}
				<>
					{slide.question && (
						<MobileQuestion
							slideId={slide.id}
							progress={questionProgress}
							question={slide.question}
							questionVoice={slide.questionVoice}
							visible={showQuestion && !(slide.type === SLIDE_TYPE_RANGE && !showRangeInput)}
							isHost={isHost}
							voiceOverride={voiceOverride}
							readQuestion={readQuestion}
						/>
					)}
					<ProgressIndicator
						className="px-0"
						isQuestion={slide.type !== SLIDE_TYPE_INFO_SLIDE}
						paused={isPaused}
						progress={waitForAnswerProgress}
						submittedAnswerProgress={submittedAnswerProgress}
						visible={showProgressBar && !(slide.type === SLIDE_TYPE_RANGE && !showRangeInput)}
						showPoints={slide.type !== SLIDE_TYPE_RANGE}
						doublePoints={doublePoints}
					/>
				</>
				{slide.type !== SLIDE_TYPE_INFO_SLIDE && (
					<div
						className={tailwindCascade({
							"absolute  flex flex-col gap-2 items-stretch inset-0 p-4":
								slide.type === SLIDE_TYPE_RANGE && showRangeResults,
							"opacity-0": slide.type === SLIDE_TYPE_RANGE && showFunFact,
						})}
					>
						{slide.type === SLIDE_TYPE_RANGE && (
							<Header
								className={tailwindCascade(
									"md:text-2xl md:leading-normal text-xl leading-none text-center",
									{ "opacity-0": !showRangeResults }
								)}
							>
								{slide.question}
							</Header>
						)}
						<div
							className={tailwindCascade({
								"flex-grow relative": slide.type === SLIDE_TYPE_RANGE && showRangeResults,
							})}
						>
							<MobileAnswers
								answers={slide.answers}
								doublePoints={doublePoints}
								haveLocalPlayer={haveLocalPlayer}
								onAnswer={onAnswerInternal}
								setPaused={setPaused}
								setPlaylistSkip={setPlaylistSkip}
								setSlideTypeAnswer={setSlideTypeAnswer}
								showAnswers={showAnswers}
								showCorrectAnswers={showCorrectAnswers}
								slideIndex={slideIndex}
								slideType={slide.type}
								statusWithProgress={statusWithProgress}
								submittedAnswer={submittedAnswer}
								typeAnswerState={typeAnswerState}
								waitForAnswer={waitForAnswerProgress > 0 && waitForAnswerProgress < 1}
								slideRef={slideRef}
								slideId={slide.id}
								answerVoice={slide.answerVoice}
								isHost={isHost}
								voiceOverride={voiceOverride}
								slide={slide}
								players={players}
								reducedMotion={reducedMotion}
								noAnimation={noAnimation}
								teams={teams}
								teamMode={teamMode}
							/>
						</div>
					</div>
				)}
				{slide.type === SLIDE_TYPE_TYPE_ANSWER && (
					<MobileSlideMedia
						ref={mediaSpaceRef}
						slide={slide}
						statusWithProgress={statusWithProgress}
						mediaStyle={mediaStyle}
						bigMedia={bigMedia}
						setBigMedia={setBigMedia}
						isPaused={isPaused}
						mute={mute}
						setSlideMediaIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
						setFunFactMediaIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
						onLoad={onLoad}
						onQuestionStart={onQuestionStart}
						onError={onError}
						onFunFactEnd={onFunFactEnd}
						noAnimation={noAnimation}
					/>
				)}
				{slide.type === SLIDE_TYPE_TYPE_ANSWER && (
					<WrongTypeAnswers
						isHost={isHost}
						hideIncorrectTypeAnswers={hideIncorrectTypeAnswers}
						haveLocalPlayer={haveLocalPlayer}
						visible={showWrongTypeAnswers}
						wrongAnswers={typeAnswerState?.wrongAnswers}
					/>
				)}
			</div>
		</>
	);
}
