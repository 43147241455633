import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { createPortal } from "react-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { v4 as uuidV4 } from "uuid";

import Avatar from "@/components/Avatar";
import Button from "@/components/interactives/Button";

import { sfx } from "@/helpers/audio";
import { isPlayerConnected } from "@/helpers/player";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import PlusIcon from "@/images/icons/icon-plus.svg";

import usePlayStore from "@/stores/play";
import useSettingsStore from "@/stores/settings";
import useWebSocketStore from "@/stores/webSocket";

import { createAvatar, createTeam, createTeamAvatar } from "@/types/team";

export default function LobbyTeams({ onEditClick, players, teams, isHost = false }) {
	const updateTeams = usePlayStore((state) => state.updateTeams);
	const updatePlayer = usePlayStore((state) => state.updatePlayer);
	const updatePlayers = usePlayStore((state) => state.updatePlayers);

	const banPlayerOnKick = useSettingsStore((state) => state.banPlayerOnKick);
	const removePlayer = usePlayStore((state) => state.removePlayer);

	const onClickKickPlayer = useCallback(
		(ev) => {
			const playerConnectionId = ev.target.dataset.player;
			if (playerConnectionId) {
				removePlayer(playerConnectionId);
				const { sendRoomMessage } = useWebSocketStore.getState();
				const roomMessage = banPlayerOnKick
					? { ban: { connectionId: playerConnectionId } }
					: { kick: { connectionId: playerConnectionId } };
				sendRoomMessage(roomMessage, playerConnectionId);
			}
		},
		[banPlayerOnKick, removePlayer]
	);

	const onClickMovePlayer = useCallback(
		(ev) => {
			const { fromConnectionId, toConnectionId, playerConnectionId } = ev.currentTarget.dataset;

			updatePlayer(playerConnectionId, (player) => void (player.teamId = toConnectionId));
		},
		[updatePlayer]
	);

	const onClickAddTeam = useCallback(() => {
		const team = createTeam(trans("Team %d", teams.size + 1));
		updateTeams((teams) => {
			teams.set(team.connectionId, team);
		});
	}, [teams.size, updateTeams]);

	const connectedPlayersArray = useMemo(
		() => Array.from(players.values()).filter((player) => isPlayerConnected(player)),
		[players]
	);

	return (
		<div className={tailwindCascade("flex-grow self-stretch", "relative")}>
			<ScrollContainer
				horizontal={true}
				vertical={false}
				hideScrollbars={false}
				className={tailwindCascade(
					"flex flex-row",
					"pt-1 overflow-x-auto overflow-y-hidden px-4",
					"scrollbar-thin scrollbar-thumb-black-50 scrollbar-track-transparent"
				)}
			>
				<div className="flex flex-row mx-auto">
					{[...teams.values()]
						.map((team) => ({
							team,
							members: connectedPlayersArray.filter((player) => player.teamId === team.connectionId),
						}))
						.map(({ team, members }) => (
							<div
								className="flex flex-col items-center flex-shrink-0 w-40 h-full gap-4"
								key={team.connectionId}
							>
								<div className={tailwindCascade("w-28 flex flex-col items-center -space-y-1.5")}>
									<Avatar
										className="flex-shrink-0 w-24 h-24"
										playerAvatar={team.avatar}
										playerName={team.name}
										playerPoints={team.previousPoints}
										checkmark={false}
										showName={true}
										isLobby={true}
										onLoad={() => {}}
										onMouseEnter={() => {}}
									/>
									{isHost && (
										<Button
											className="h-11 items-center text-white whitespace-nowrap px-6 min-w-[9rem]"
											color="green-light"
											border={3}
											onClick={onEditClick}
											data-team-id={team.connectionId}
										>
											{trans("Edit team")}
										</Button>
									)}
								</div>
								<div className="flex flex-col items-center gap-2 w-full">
									{members.length > 0 &&
										members.map((player, i) => (
											<PlayerName
												banPlayerOnKick={banPlayerOnKick}
												isHost={isHost}
												key={i}
												onClickKick={onClickKickPlayer}
												onClickMove={onClickMovePlayer}
												player={player}
												teams={teams}
											/>
										))}
								</div>
							</div>
						))}
					{isHost && (
						<div className={tailwindCascade("flex flex-col items-center flex-shrink-0 w-40 h-full")}>
							<div className={tailwindCascade("w-28 flex flex-col items-center -space-y-1.5")}>
								<div className="relative flex-shrink-0">
									<Avatar
										className="w-24 h-24"
										checkmark={false}
										showName={true}
										isLobby={true}
										onLoad={() => {}}
										onMouseEnter={() => {}}
									/>
									<PlusIcon className="left-1/2 top-1/2 text-white-50 absolute w-24 h-24 -translate-x-1/2 -translate-y-1/2" />
								</div>
								<Button
									className="h-11 whitespace-nowrap items-center text-white px-6 min-w-[9rem]"
									color="green-light"
									border={3}
									onClick={onClickAddTeam}
								>
									{trans("Add team")}
								</Button>
							</div>
						</div>
					)}
				</div>
			</ScrollContainer>
		</div>
	);
}

function PlayerName({ banPlayerOnKick, isHost, onClickKick, onClickMove, player, teams, ...props }) {
	const TRANSITION_DURATION_MS = 150;

	const [opaqueTooltip, setOpaqueTooltip] = useState(false);
	const [mountTooltip, setMountTooltip] = useState(false);

	const nameRef = useRef(null);
	const [tooltipPosition, setTooltipPos] = useState({ rect: { top: 0, left: 0 }, scrollY: 0 });

	const showTooltip = useCallback(() => {
		sfx.play("hoverFeedback", false, 0.75);
		setOpaqueTooltip(true);
		const rect = nameRef.current.getBoundingClientRect();
		const scrollY = window.scrollY;
		setTooltipPos({ rect, scrollY });
	}, []);
	const hideTooltip = useCallback(() => void setOpaqueTooltip(false), []);
	const toggleTooltip = opaqueTooltip ? hideTooltip : showTooltip;

	useEffect(() => {
		// Hide tooltip when list changes
		void teams;
		setOpaqueTooltip(false);
	}, [teams]);

	useEffect(() => {
		if (opaqueTooltip) {
			setMountTooltip(true);
		} else {
			const timeout = setTimeout(() => void setMountTooltip(false), TRANSITION_DURATION_MS);
			return () => void clearTimeout(timeout);
		}
	}, [mountTooltip, opaqueTooltip]);

	const { rect, scrollY } = tooltipPosition;

	return (
		<div className="group text-base font-bold text-white leading-none w-full">
			<div
				className="relative w-full text-base font-bold text-white leading-none h-4"
				ref={nameRef}
				onTouchStart={isHost ? toggleTooltip : undefined}
				onMouseEnter={isHost ? showTooltip : undefined}
				onMouseLeave={hideTooltip}
			>
				<div className="absolute w-full h-4 truncate text-center">{player.name}</div>
			</div>
			{mountTooltip &&
				createPortal(
					<div className="absolute inset-0" onTouchStart={hideTooltip}>
						<div
							className={tailwindCascade(
								"absolute z-1",
								"flex flex-col items-center -space-y-2",
								"pointer-events-auto",
								"-translate-x-1/2",
								"text-white",
								"opacity-0 transition-opacity",
								{
									"opacity-100": opaqueTooltip,
								}
							)}
							style={{
								transitionDuration: `${TRANSITION_DURATION_MS}ms`,
								left: rect.left + rect.width / 2,
								top: rect.top + scrollY,
							}}
							onMouseEnter={isHost ? showTooltip : undefined}
							onMouseLeave={hideTooltip}
						>
							<div style={{ width: rect.width, height: rect.height + 6 }} onTouchStart={toggleTooltip} />
							<div className="w-3 h-3 rotate-45 bg-black" />
							<div className="whitespace-nowrap flex flex-col px-3 py-1 bg-black rounded-md">
								{[...teams.values()]
									.filter((team) => team.connectionId !== player.teamId)
									.map((team) => (
										<button
											key={team.connectionId}
											className="md:hover:underline font-black"
											onClick={onClickMove}
											data-player-connection-id={player.connectionId}
											data-from-connection-id={player.teamId}
											data-to-connection-id={team.connectionId}
										>
											{trans("Move to: %s", team.name)}
										</button>
									))}

								<button
									className="md:hover:underline text-error font-black"
									onClick={onClickKick}
									data-player={player.connectionId}
								>
									{banPlayerOnKick ? trans("Ban player") : trans("Kick player")}
								</button>
							</div>
						</div>
					</div>,
					document.getElementById("__next")
				)}
		</div>
	);
}
