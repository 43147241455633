import React from "react";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { isEqual } from "lodash";
import { memo } from "react";

const BarberPole = memo(function BarberPole({ className, colors, ...props }) {
	return (
		<div className={tailwindCascade("relative w-64 h-12", className)}>
			<div
				className="border-3 relative z-0 flex flex-col items-center justify-center w-full h-full px-4 pb-1 overflow-hidden border-black border-solid rounded-full"
				{...props}
			>
				{Array.isArray(colors) && colors.length >= 2 && (
					<div className="animate-scroll absolute inset-0">
						<div style={{ height: `100%`, width: `300%` }}>
							<div
								className="w-full h-full"
								style={{ transform: `translateX(-${100 / 3 / colors.length}%` }}
							>
								<div className="-skew-x-45 flex flex-row w-full h-full">
									{colors
										.concat(colors)
										.concat(colors)
										.map((value, i, arr) => (
											<div
												key={i}
												className="h-full"
												style={{
													backgroundColor: value,
													width: `${100 / colors.length}%`,
												}}
											></div>
										))}
								</div>
							</div>
						</div>
					</div>
				)}
				{props.children}
			</div>
			<div className="absolute left-1 top-[1px] w-[calc(100%-0.5rem)] h-full border-b-[0.5rem] border-black opacity-30 rounded-full z-0"></div>
		</div>
	);
}, isEqual);

export default BarberPole;
