import { useMemo } from "react";
import sampleSize from "lodash/sampleSize";
import uniq from "lodash/uniq";
import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";

export default function useRateQuizzes(slides) {
	const quizzes = useMemo(() => {
		if (!slides) {
			return [];
		}

		return uniq(slides.filter((slide) => slide?.quiz).map((slide) => slide.quiz.id)).map((quizId) => ({
			...slides.find((slide) => slide.quiz.id === quizId).quiz,
			slides: slides.filter((slide) => slide.quiz && slide.quiz.id === quizId),
		}));
	}, [slides]);

	const quizzesWithMedias = useMemo(
		() =>
			quizzes.map((quiz) => {
				// Get 3 random slide medi
				const medias = sampleSize(
					quiz.slides.filter(({ media }) => media).map(({ media }) => media),
					3
				);

				if (medias.length < 3) {
					// Try to fill the blanks with funfact medi
					medias.splice(
						medias.length,
						0,
						...sampleSize(
							quiz.slides
								.filter(({ funFactMedia }) => funFactMedia)
								.map(({ funFactMedia }) => funFactMedia),
							3 - medias.length
						)
					);
				}
				while (medias.length < 3) {
					// Fill remaining blanks with nul
					medias.push(null);
				}
				if (medias[2] === null) {
					// Finally, use quiz media if necessar
					medias[2] = quiz.media;
				}
				return { ...cloneDeep(omit(quiz, ["slides"])), medias };
			}),
		[quizzes]
	);

	return quizzesWithMedias;
}
