import React, { useRef, useEffect } from "react";

import trans from "@/helpers/trans";
import isString from "lodash/isString";

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

function getTimeString(seconds, prefix = undefined) {
	seconds = Math.round(seconds);

	if (prefix === undefined) {
		prefix = "";
	}
	if (isString(prefix)) {
		prefix = prefix.trim();
	}
	if (prefix) {
		prefix = `${prefix} `;
	}

	if (seconds < MINUTE) {
		const value = seconds;
		return value === 1 ? trans("%s%d second", prefix, value) : trans("%s%d seconds", prefix, value);
	}

	if (seconds < HOUR) {
		const value = Math.round(seconds / MINUTE);
		return value === 1 ? trans("%s%d minute", prefix, value) : trans("%s%d minutes", prefix, value);
	}

	if (seconds < DAY) {
		const value = Math.round(seconds / HOUR);
		return value === 1 ? trans("%s%d hour", prefix, value) : trans("%s%d hours", prefix, value);
	}

	if (seconds < WEEK) {
		const value = Math.round(seconds / DAY);
		return value === 1 ? trans("%s%d day", prefix, value) : trans("%s%d days", prefix, value);
	}

	if (seconds < MONTH) {
		const value = Math.round(seconds / WEEK);
		return value === 1 ? trans("%s%d week", prefix, value) : trans("%s%d weeks", prefix, value);
	}

	if (seconds < YEAR) {
		const value = Math.round(seconds / MONTH);
		return value === 1 ? trans("%s%d month", prefix, value) : trans("%s%d months", prefix, value);
	}

	const value = Math.round(seconds / YEAR);
	return value === 1 ? trans("%d year", prefix, value) : trans("%d years", prefix, value);
}

function getTimeToGo(future, prefix, suffix) {
	const now = Date.now();
	const seconds = Math.round(Math.abs(future - now) / 1000);
	return getTimeString(seconds, prefix, suffix);
}

export default function TimeToGo({ prefix, date, ...props }) {
	const ref = useRef();

	useEffect(() => {
		const parsed = new Date(date);
		const future = parsed.getTime();
		const now = Date.now();

		let delay = null;
		if (Math.abs(future - now) < MINUTE * 1000) {
			delay = 1000; // Every second
		} else if (Math.abs(future - now) < HOUR * 1000) {
			delay = MINUTE * 1000; // Every minute
		}

		let interval = null;
		if (delay) {
			interval = setInterval(() => {
				if (ref.current) {
					ref.current.innerText = getTimeToGo(future, prefix);
				}
			}, delay);
		}
		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [date, prefix]);

	return <div ref={ref} {...props} />;
}
