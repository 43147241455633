import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { Linear, Power2 } from "gsap";
import clamp from "lodash/clamp";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import isFinite from "lodash/isFinite";
import ScrollContainer from "react-indiana-drag-scroll";
import { useImmer } from "use-immer";
import { v4 as uuidV4 } from "uuid";

import Header from "@/components/Header";
import Spinner from "@/components/Spinner";
import Button from "@/components/interactives/Button";
import ChevronButton from "@/components/interactives/ChevronButton";
import Input from "@/components/interactives/Input";

import { firstNames, lastNames } from "@/data/names";

import animationLoop from "@/helpers/animationLoop";
import getRandomAvatarFeatures, { getRandomHatFeature } from "@/helpers/getRandomAvatarFeatures";
import gsap from "@/helpers/gsap";
import onWindowResize from "@/helpers/onWindowResize";
import { quantizePath, softenPath } from "@/helpers/paint";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useForwardRef from "@/hooks/useForwardRef";
import useRefCallback from "@/hooks/useRefCallback";
import useRefMounted from "@/hooks/useRefMounted";

import BrushLargeIcon from "@/images/icons/icon-avatar-brush-large.svg";
import BrushMediumIcon from "@/images/icons/icon-avatar-brush-medium.svg";
import IconAvatarDelete from "@/images/icons/icon-avatar-delete.svg";
import IconAvatarFeatures from "@/images/icons/icon-avatar-features.svg";
import IconAvatarUndo from "@/images/icons/icon-avatar-undo.svg";
import IconClear from "@/images/icons/icon-crosscircle.svg";
import EraseIcon from "@/images/icons/icon-erase.svg";
import IconZoomIn from "@/images/icons/icon-zoom-in.svg";
import IconZoomOut from "@/images/icons/icon-zoom-out.svg";
import ShuffleIcon from "@/images/icons/shuffle.svg";

import usePlayerAvatarCustomizeStore from "@/stores/playerAvatarCustomize";
import useSettingsStore from "@/stores/settings";
import useWebSocketStore from "@/stores/webSocket";

import { createTeamAvatar } from "@/types/team";

import { MAX_USER_NAME_LENGTH } from "@/app-constants.mjs";
import { ERROR, WHITE } from "@/colors";
import {
	AVATAR_FEATURE_PROPS,
	AVATAR_LAYER_FEATURE_BODY,
	AVATAR_LAYER_FEATURE_EYE,
	AVATAR_LAYER_FEATURE_HAIR,
	AVATAR_LAYER_FEATURE_HAT,
	AVATAR_LAYER_FEATURE_MOUTH,
	AVATAR_LAYER_LINE,
	AVATAR_LAYER_NONE,
	PAINT_PALETTE,
	PLAYER_AVATAR_BACKGROUND_COLOR,
	PLAYER_AVATAR_BASE_PATH,
	PLAYER_AVATAR_BODYS,
	PLAYER_AVATAR_BODY_DEFAULT_SCALE,
	PLAYER_AVATAR_BODY_DEFAULT_Y,
	PLAYER_AVATAR_CANVAS_SIZE,
	PLAYER_AVATAR_EYES,
	PLAYER_AVATAR_EYE_DEFAULT_SCALE,
	PLAYER_AVATAR_EYE_DEFAULT_Y,
	PLAYER_AVATAR_HAIRS,
	PLAYER_AVATAR_HAIR_DEFAULT_SCALE,
	PLAYER_AVATAR_HAIR_DEFAULT_Y,
	PLAYER_AVATAR_HATS,
	PLAYER_AVATAR_HAT_DEFAULT_SCALE,
	PLAYER_AVATAR_HAT_DEFAULT_Y,
	PLAYER_AVATAR_MOUTHS,
	PLAYER_AVATAR_MOUTH_DEFAULT_SCALE,
	PLAYER_AVATAR_MOUTH_DEFAULT_Y,
	PLAY_STATUS_GAME_START,
	PLAY_STATUS_HIDE_GET_READY,
	PLAY_STATUS_LOBBY,
} from "@/constants";

const ACTION_HISTORY_UPDATE = "history/update";
const ACTION_CLEAR = "clear";
const ACTION_SHUFFLE = "shuffle";
const ACTION_DONE = "done";
const ACTION_UNDO = "undo";

const ACTION_MODE_UPDATE = "mode/update";
const ACTION_TOOL_UPDATE = "tool/update";
const ACTION_TOOL_SCALE = "tool/scale";
const ACTION_TOOL_DELETE = "tool/delete";
const ACTION_TOOL_COLOR_CANCEL = "tool/color/cancel";
const ACTION_BRUSH_UPDATE = "brush/update";
const ACTION_FEATURE_ADD = "feature/add";
const ACTION_FEATURE_CANCEL = "feature/cancel";
const ACTION_FEATURE_POSITION = "feature/position";
const ACTION_DRAW_COLOR = "draw/color";
const ACTION_ACTIVE_UPDATE = "active/update";
const ACTION_ACTIVE_CANCEL = "active/cancel";

const MIN_SCALE = 0.125;
const MAX_SCALE = 8;

const MODE_NONE = 0;
const MODE_MOVE = 1;
const MODE_DRAW = 2;

const TOOL_NONE = 0;
const TOOL_MOVE = 1;
const TOOL_DELETE = 2;
const TOOL_ADD = 3;
const TOOL_COLOR = 4;

const BRUSH_NONE = 0;
const BRUSH_SMALL = 1;
const BRUSH_LARGE = 2;
const BRUSH_DELETE = 3;

const MAX_NUMBER_OF_HISTORY_STEPS = 10;

const MENU_BUTTON_WIDTH = 52;

const DEFAULT_COLOR = "#5d275d";
const DEFAULT_BRUSH = BRUSH_SMALL;

const SVG_NAMESPACE = "http://www.w3.org/2000/svg";
const XHTML_NAMESPACE = "http://www.w3.org/1999/xhtml";

const AVATAR_CUSTOMIZE_TIMEOUT = 5;

function getBrushSize(tool) {
	switch (tool) {
		case BRUSH_SMALL:
			return 32;
		case BRUSH_LARGE:
			return 64;
	}

	return 0;
}

function getSVGMousePosition(element, pageX, pageY) {
	if (element) {
		const boundingClientRect = element.getBoundingClientRect();
		const x =
			((pageX - boundingClientRect.left) / (boundingClientRect.right - boundingClientRect.left)) *
			PLAYER_AVATAR_CANVAS_SIZE;
		const y =
			((pageY - boundingClientRect.top) / (boundingClientRect.bottom - boundingClientRect.top)) *
			PLAYER_AVATAR_CANVAS_SIZE;
		return { x, y };
	}

	return { x: 0, y: 0 };
}

function AvatarContainer({ state, dispatch, className, children }) {
	const onClick = useCallback(
		(tool) => {
			if (dispatch) {
				dispatch({
					type: ACTION_ACTIVE_CANCEL,
				});
			}
		},
		[dispatch]
	);

	return (
		<div
			className={tailwindCascade(
				"container relative flex items-center justify-center w-full h-full sm:pb-8 pb-4 mx-auto",
				className
			)}
		>
			<button className="absolute top-0 left-0 w-full h-full cursor-default" onClick={onClick} />
			<div className="w-full max-w-xs px-4 mx-auto">
				<div className="pb-full relative w-full">
					<div className="inset-2 absolute">
						<div
							className="z-1 relative flex flex-col items-center justify-center w-full h-full overflow-hidden border-4 border-black rounded-full"
							style={{ backgroundColor: PLAYER_AVATAR_BACKGROUND_COLOR }}
						>
							{children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

function ColorIcon({ color = "transparent" }) {
	return <div className="z-1 relative w-6 h-6 m-2 rounded-md" style={{ backgroundColor: color }} />;
}

function ColorChooser({ state, dispatch }) {
	const onClick = useCallback(
		(color) => {
			if (dispatch) {
				dispatch({
					type: ACTION_DRAW_COLOR,
					payload: color,
				});
			}
		},
		[dispatch]
	);

	const buttons = [];
	const palette = [...PAINT_PALETTE];
	let index = 0;
	while (palette.length) {
		const innerButtons = [];
		const colors = palette.splice(0, 5);
		colors.map(
			(color, index) =>
				void innerButtons.push(
					<MenuButton
						key={index}
						color={color.hex}
						active={state.color === color.hex}
						onClick={() => void onClick(color.hex)}
					/>
				)
		);
		buttons.push(
			<div key={index++} className="flex flex-row mx-auto space-x-3">
				{innerButtons}
			</div>
		);
	}

	return (
		<div className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white-50 flex flex-col flex-wrap w-full gap-3 pt-1 overflow-x-auto overflow-y-hidden">
			<div className="flex flex-col flex-wrap w-full gap-3">{buttons}</div>
		</div>
	);
}

function ScrollContainerWrapper({
	className,
	position,
	setPosition,
	disableFeatureButtons,
	enableFeatureButtons,
	children,
}) {
	const scrollContainerRef = useRef(null);
	const scrollContainerTweenRef = useRef(null);
	const scrollButtonLeftRef = useRef(null);
	const scrollButtonLeftTweenRef = useRef(null);
	const scrollButtonRightRef = useRef(null);
	const scrollButtonRightTweenRef = useRef(null);

	const positionRef = useRef(position);
	useEffect(() => void (positionRef.current = position), [position]);
	const setPositionRef = useRef(setPosition);
	useEffect(() => void (setPositionRef.current = setPosition), [setPosition]);

	const disableFeatureButtonsRef = useRef(disableFeatureButtons);
	useEffect(() => void (disableFeatureButtonsRef.current = disableFeatureButtons), [disableFeatureButtons]);
	const enableFeatureButtonsRef = useRef(enableFeatureButtons);
	useEffect(() => void (enableFeatureButtonsRef.current = enableFeatureButtons), [enableFeatureButtons]);

	const [initialScroll, setInitialScroll] = useState(false);

	const onStartScroll = useCallback(({ external }) => {
		if (!external && scrollContainerTweenRef.current) {
			scrollContainerTweenRef.current.kill();
			scrollContainerTweenRef.current = null;
		}
		if (enableFeatureButtonsRef.current) {
			enableFeatureButtonsRef.current();
		}
	}, []);

	const onScroll = useCallback(({ external }) => {
		if (!external && disableFeatureButtonsRef.current) {
			disableFeatureButtonsRef.current();
		}
	}, []);

	const onEndScroll = useCallback(() => {
		if (scrollContainerRef.current) {
			const { offsetWidth, scrollLeft, scrollWidth } = scrollContainerRef.current;

			if (setPositionRef.current) {
				setPositionRef.current(scrollLeft);
			}

			const leftButtonVisible = scrollLeft !== 0;
			const rightButtonVisible = scrollLeft < scrollWidth - offsetWidth;

			if (scrollButtonLeftTweenRef.current) {
				scrollButtonLeftTweenRef.current.kill();
				scrollButtonLeftTweenRef.current = null;
			}

			if (scrollButtonLeftRef.current) {
				gsap.set(scrollButtonLeftRef.current, {
					pointerEvents: leftButtonVisible ? "auto" : "none",
				});
				scrollButtonLeftTweenRef.current = gsap.to(scrollButtonLeftRef.current, {
					opacity: leftButtonVisible ? 1 : 0.25,
					duration: 0.125,
					ease: Linear.easeNone,
					onComplete: () => void (scrollButtonLeftTweenRef.current = null),
				});
			}

			if (scrollButtonRightTweenRef.current) {
				scrollButtonRightTweenRef.current.kill();
				scrollButtonRightTweenRef.current = null;
			}

			if (scrollButtonRightRef.current) {
				gsap.set(scrollButtonRightRef.current, {
					pointerEvents: rightButtonVisible ? "auto" : "none",
				});
				scrollButtonRightTweenRef.current = gsap.to(scrollButtonRightRef.current, {
					opacity: rightButtonVisible ? 1 : 0.25,
					duration: 0.125,
					ease: Linear.easeNone,
					onComplete: () => void (scrollButtonRightTweenRef.current = null),
				});
			}
		}

		if (enableFeatureButtonsRef.current) {
			enableFeatureButtonsRef.current();
		}
	}, []);

	const scroll = useCallback((direction = 1) => {
		if (scrollContainerRef.current) {
			const { offsetWidth, scrollLeft, scrollWidth } = scrollContainerRef.current;

			const padding = 8;

			if (scrollContainerTweenRef.current) {
				scrollContainerTweenRef.current.kill();
				scrollContainerTweenRef.current = null;
			}

			scrollContainerTweenRef.current = gsap.to(scrollContainerRef.current, {
				scrollLeft: clamp(
					Math.floor(scrollLeft - MENU_BUTTON_WIDTH * direction + (offsetWidth + padding) * direction),
					0,
					scrollWidth - offsetWidth
				),
				duration: 0.5,
				ease: Power2.easeOut,
				onComplete: () => void (scrollContainerTweenRef.current = null),
			});
		}
	}, []);

	const resetButtons = useCallback(() => {
		if (scrollContainerRef.current) {
			if (scrollButtonLeftTweenRef.current) {
				scrollButtonLeftTweenRef.current.kill();
				scrollButtonLeftTweenRef.current = null;
			}

			if (scrollButtonRightTweenRef.current) {
				scrollButtonRightTweenRef.current.kill();
				scrollButtonRightTweenRef.current = null;
			}

			const { offsetWidth, scrollLeft, scrollWidth } = scrollContainerRef.current;

			const leftButtonVisible = scrollLeft !== 0;
			const rightButtonVisible = scrollLeft !== scrollWidth - offsetWidth;

			if (scrollButtonLeftRef.current) {
				gsap.set(scrollButtonLeftRef.current, {
					opacity: leftButtonVisible ? 1 : 0.25,
					visibility: "visible",
					pointerEvents: leftButtonVisible ? "auto" : "none",
				});
			}

			if (scrollButtonRightRef.current) {
				gsap.set(scrollButtonRightRef.current, {
					opacity: rightButtonVisible ? 1 : 0.25,
					visibility: "visible",
					pointerEvents: rightButtonVisible ? "auto" : "none",
				});
			}
		}
	}, []);
	const resetButtonsRef = useRef(resetButtons);
	useEffect(() => void (resetButtonsRef.current = resetButtons), [resetButtons]);

	useEffect(() => {
		let mounted = true;
		requestAnimationFrame(() => {
			if (mounted && scrollContainerRef.current) {
				const position = positionRef.current || 0;
				scrollContainerRef.current.scrollLeft = position;
				if (resetButtonsRef.current) {
					resetButtonsRef.current();
				}
				setInitialScroll(true);
			}
		});

		return () => void (mounted = false);
	}, []);

	useEffect(() => {
		if (scrollContainerRef.current) {
			return onWindowResize(onEndScroll);
		}
	}, [onEndScroll]);

	useEffect(() => void resetButtons(), [resetButtons]);

	useEffect(() => {
		if (scrollContainerRef.current) {
			gsap.set(scrollContainerRef.current, { scrollLeft: 0 });
		}

		return () => {
			if (scrollContainerTweenRef.current) {
				scrollContainerTweenRef.current.kill();
				scrollContainerTweenRef.current = null;
			}

			if (scrollButtonLeftTweenRef.current) {
				scrollButtonLeftTweenRef.current.kill();
				scrollButtonLeftTweenRef.current = null;
			}

			if (scrollButtonRightTweenRef.current) {
				scrollButtonRightTweenRef.current.kill();
				scrollButtonRightTweenRef.current = null;
			}
		};
	}, []);

	const onClickLeft = useCallback(() => void scroll(-1), [scroll]);
	const onClickRight = useCallback(() => void scroll(1), [scroll]);

	return (
		<div
			className={tailwindCascade(
				"flex flex-row mx-auto space-x-4 items-start max-w-[21.5rem] md:max-w-[29.5rem] w-full",
				{
					"opacity-0": !initialScroll,
				}
			)}
		>
			<button ref={scrollButtonLeftRef} className="md:block group invisible hidden" onClick={onClickLeft}>
				<div className="bg-white-50 group-hover:bg-white relative w-12 h-12 rounded-full">
					<img
						src="/images/icons/chevron-left-multicolor.svg"
						width="48"
						height="48"
						alt=""
						draggable={false}
						className="absolute top-0 left-0 w-12 h-12"
					/>
				</div>
			</button>
			<ScrollContainer
				className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white-50 w-full overflow-x-auto overflow-y-hidden"
				vertical={false}
				horizontal={true}
				hideScrollbars={true}
				innerRef={scrollContainerRef}
				onStartScroll={onStartScroll}
				onScroll={onScroll}
				onEndScroll={onEndScroll}
			>
				<div className="flex flex-row justify-start pb-2 space-x-3">{children}</div>
			</ScrollContainer>
			<button ref={scrollButtonRightRef} className="md:block group invisible hidden" onClick={onClickRight}>
				<div className="bg-white-50 group-hover:bg-white relative w-12 h-12 rounded-full">
					<img
						src="/images/icons/chevron-right-multicolor.svg"
						width="48"
						height="48"
						alt=""
						draggable={false}
						className="absolute top-0 left-0 w-12 h-12"
					/>
				</div>
			</button>
		</div>
	);
}

function FeatureChooser({ state, dispatch }) {
	const [, mountedRef] = useRefMounted();

	const onClick = useCallback(
		(feature, index) => {
			if (dispatch) {
				dispatch({
					type: ACTION_FEATURE_ADD,
					payload: [feature, index],
				});
			}
		},
		[dispatch]
	);

	const setPosition = useCallback(
		(feature, position) =>
			void (
				dispatch &&
				dispatch({
					type: ACTION_FEATURE_POSITION,
					payload: [feature, position],
				})
			),
		[dispatch]
	);

	const [featureButtonsEnabled, setFeatureButtonsEnabled] = useState(true);

	const enableFeatureButtons = useCallback(
		() =>
			void setTimeout(() => {
				if (mountedRef.current) {
					setFeatureButtonsEnabled(true);
				}
			}, 300),
		[mountedRef]
	);

	return (
		<div className="flex flex-col items-center w-full">
			<div className="flex flex-col flex-wrap w-full gap-1 pt-1">
				<ScrollContainerWrapper
					position={state.featurePositions.hats}
					setPosition={(poisiton) => void setPosition(AVATAR_LAYER_FEATURE_HAT, poisiton)}
					disableFeatureButtons={() => void setFeatureButtonsEnabled(false)}
					enableFeatureButtons={enableFeatureButtons}
				>
					{PLAYER_AVATAR_HATS.map((feature, index) => (
						<MenuButton
							key={index}
							icon={
								<div className="-top-6 absolute left-0 w-10 h-10">
									<img
										alt=""
										draggable={false}
										src={PLAYER_AVATAR_BASE_PATH + feature}
										className="relative block object-contain w-12 h-12"
									/>
								</div>
							}
							onClick={() => void (featureButtonsEnabled && onClick(AVATAR_LAYER_FEATURE_HAT, index))}
						/>
					))}
				</ScrollContainerWrapper>
				<ScrollContainerWrapper
					position={state.featurePositions.hairs}
					setPosition={(poisiton) => void setPosition(AVATAR_LAYER_FEATURE_HAIR, poisiton)}
					disableFeatureButtons={() => void setFeatureButtonsEnabled(false)}
					enableFeatureButtons={enableFeatureButtons}
				>
					{PLAYER_AVATAR_HAIRS.map((feature, index) => (
						<MenuButton
							key={index}
							icon={
								<div className="-top-6 absolute left-0 w-10 h-10">
									<img
										alt=""
										draggable={false}
										src={PLAYER_AVATAR_BASE_PATH + feature}
										className="relative block object-contain w-12 h-12"
									/>
								</div>
							}
							onClick={() => void (featureButtonsEnabled && onClick(AVATAR_LAYER_FEATURE_HAIR, index))}
						/>
					))}
				</ScrollContainerWrapper>
				<ScrollContainerWrapper
					position={state.featurePositions.eyes}
					setPosition={(poisiton) => void setPosition(AVATAR_LAYER_FEATURE_EYE, poisiton)}
					disableFeatureButtons={() => void setFeatureButtonsEnabled(false)}
					enableFeatureButtons={enableFeatureButtons}
				>
					{PLAYER_AVATAR_EYES.map((feature, index) => (
						<MenuButton
							key={index}
							icon={
								<div className="-top-6 absolute left-0 w-10 h-10">
									<img
										alt=""
										draggable={false}
										src={PLAYER_AVATAR_BASE_PATH + feature}
										className="relative block object-contain w-12 h-12"
									/>
								</div>
							}
							onClick={() => void (featureButtonsEnabled && onClick(AVATAR_LAYER_FEATURE_EYE, index))}
						/>
					))}
				</ScrollContainerWrapper>
				<ScrollContainerWrapper
					position={state.featurePositions.mouths}
					setPosition={(poisiton) => void setPosition(AVATAR_LAYER_FEATURE_MOUTH, poisiton)}
					disableFeatureButtons={() => void setFeatureButtonsEnabled(false)}
					enableFeatureButtons={enableFeatureButtons}
				>
					{PLAYER_AVATAR_MOUTHS.map((feature, index) => (
						<MenuButton
							key={index}
							icon={
								<div className="-top-6 absolute left-0 w-10 h-10">
									<img
										alt=""
										draggable={false}
										src={PLAYER_AVATAR_BASE_PATH + feature}
										className="relative block object-contain w-12 h-12"
									/>
								</div>
							}
							onClick={() => void (featureButtonsEnabled && onClick(AVATAR_LAYER_FEATURE_MOUTH, index))}
						/>
					))}
				</ScrollContainerWrapper>
				<ScrollContainerWrapper
					position={state.featurePositions.bodys}
					setPosition={(poisiton) => void setPosition(AVATAR_LAYER_FEATURE_BODY, poisiton)}
					disableFeatureButtons={() => void setFeatureButtonsEnabled(false)}
					enableFeatureButtons={enableFeatureButtons}
				>
					{PLAYER_AVATAR_BODYS.map((feature, index) => (
						<MenuButton
							key={index}
							icon={
								<div className="-top-6 absolute left-0 w-10 h-10">
									<img
										alt=""
										draggable={false}
										src={PLAYER_AVATAR_BASE_PATH + feature}
										className="relative block object-contain w-12 h-12"
									/>
								</div>
							}
							onClick={() => void (featureButtonsEnabled && onClick(AVATAR_LAYER_FEATURE_BODY, index))}
						/>
					))}
				</ScrollContainerWrapper>
			</div>
		</div>
	);
}

function MenuButton({
	icon,
	color,
	onClick,
	roundedLeft = true,
	roundedRight = true,
	disabled = false,
	active = null,
	className,
}) {
	return (
		<Button
			className={tailwindCascade("flex-shrink-0 w-12 h-12 p-0", className)}
			color={color}
			icon={icon}
			roundedLeft={roundedLeft}
			roundedRight={roundedRight}
			border={true}
			borderRadius={12}
			onClick={onClick}
			disabled={disabled}
			outline={active ? WHITE : false}
		>
			<span />
		</Button>
	);
}

function TopMenu({
	state,
	dispatch,
	internalState,
	teamMode = false,
	enableRemoveTeam = false,
	onRemoveTeam,
	isHost,
	roomId,
	showCode,
	joinOpen,
}) {
	const onClickClear = useCallback(() => {
		if (dispatch) {
			dispatch({
				type: ACTION_CLEAR,
			});
		}
	}, [dispatch]);

	const onClickDone = useCallback(() => {
		if (dispatch && state.hasSearchText) {
			dispatch({
				type: ACTION_DONE,
			});
		}
	}, [dispatch, state.hasSearchText]);

	const onClickShuffle = useCallback(() => {
		if (dispatch && state.hasSearchText) {
			dispatch({
				type: ACTION_SHUFFLE,
			});
		}
	}, [dispatch, state.hasSearchText]);

	const onClickRemoveTeam = useCallback(() => {
		if (onRemoveTeam) {
			onRemoveTeam();
		}
	}, [onRemoveTeam]);

	const isShuffle = useMemo(
		() => internalState.layers && internalState.layers.length === 0 && internalState.background === null,
		[internalState.background, internalState.layers]
	);

	const roomIdSplitted = useMemo(() => {
		if (roomId) {
			return [roomId.substring(0, 3), roomId.substring(3, 6)];
		} else {
			return null;
		}
	}, [roomId]);

	return (
		<div className="sm:bg-black sm:bg-opacity-50 sm:pb-4 w-full pt-4">
			<div className="h-14 container flex flex-row w-full gap-4 px-4 mx-auto">
				{teamMode ? (
					<Button
						border={true}
						color="pink"
						className="px-6 text-white flex-grow-0"
						onClick={onClickRemoveTeam}
						disabled={!enableRemoveTeam}
					>
						{trans("Remove team")}
					</Button>
				) : (
					<Button border={true} color="pink" className="px-6 text-white flex-grow-0" onClick={onClickClear}>
						{trans("Clear")}
					</Button>
				)}
				<div className="flex-1 flex flex-col justify-center items-center gap-1">
					{isHost && !teamMode && roomIdSplitted && showCode && joinOpen && (
						<>
							<div className="text-xs md:text-sm font-bold text-center text-white">
								{trans("PIN code:")}
							</div>
							<Header className="text-green-lighter text-xl md:text-2xl font-black leading-none text-center">
								<div className="flex flex-row items-center justify-center flex-1 gap-2">
									<div>{roomIdSplitted[0]}</div>
									<div>{roomIdSplitted[1]}</div>
								</div>
							</Header>
						</>
					)}
				</div>
				<div className="flex-grow-0">
					<Button
						border={true}
						color={`${isShuffle ? "cyan" : "green-light"}`}
						className="px-6 text-white"
						onClick={isShuffle ? onClickShuffle : onClickDone}
						disabled={!state.hasSearchText}
					>
						{isShuffle ? trans("Shuffle") : trans("Done")}
					</Button>
				</div>
			</div>
		</div>
	);
}

function Menu({ state, dispatch }) {
	const onClickMode = useCallback(
		(mode) => {
			if (dispatch) {
				dispatch({
					type: ACTION_MODE_UPDATE,
					payload: mode,
				});
			}
		},
		[dispatch]
	);

	const onClickBrush = useCallback(
		(brush) => {
			if (dispatch) {
				dispatch({
					type: ACTION_BRUSH_UPDATE,
					payload: brush,
				});
			}
		},
		[dispatch]
	);

	const onClickToolAdd = useCallback(
		(tool) => {
			if (dispatch) {
				if (state.tool === TOOL_ADD) {
					dispatch({
						type: ACTION_FEATURE_CANCEL,
					});
				} else {
					dispatch({
						type: ACTION_TOOL_UPDATE,
						payload: TOOL_ADD,
					});
				}
			}
		},
		[state, dispatch]
	);

	const onClickToolColor = useCallback(
		(tool) => {
			if (dispatch) {
				if (state.tool === TOOL_COLOR) {
					dispatch({
						type: ACTION_TOOL_COLOR_CANCEL,
					});
				} else {
					dispatch({
						type: ACTION_TOOL_UPDATE,
						payload: TOOL_COLOR,
					});
				}
			}
		},
		[state, dispatch]
	);

	const onClickDelete = useCallback(() => {
		if (dispatch) {
			dispatch({
				type: ACTION_TOOL_DELETE,
			});
		}
	}, [dispatch]);

	const onClickZoom = useCallback(
		(direction) => {
			if (dispatch) {
				dispatch({
					type: ACTION_TOOL_SCALE,
					payload: direction,
				});
			}
		},
		[dispatch]
	);

	const onClickUndo = useCallback(() => {
		if (dispatch) {
			dispatch({
				type: ACTION_UNDO,
			});
		}
	}, [dispatch]);

	const onClickShuffle = useCallback(() => {
		if (dispatch) {
			dispatch({
				type: ACTION_SHUFFLE,
			});
		}
	}, [dispatch]);

	return (
		<div className="z-3 h-18 sm:h-14 sm:pb-0 container flex flex-row justify-center w-full px-4 pb-4 mx-auto">
			<div className="md:gap-3 relative flex flex-row gap-1 overflow-x-auto overflow-y-hidden">
				<MenuButton
					className="w-11 md:w-12 relative"
					color="white"
					icon={<ShuffleIcon className="w-7 h-7 md:w-8 md:h-8 m-1" />}
					roundedLeft={true}
					roundedRight={true}
					onClick={() => onClickShuffle()}
				/>

				{state.mode === MODE_MOVE && (
					<div className="w-53 md:w-60 relative flex flex-row flex-shrink-0 h-12 overflow-hidden">
						<MenuButton
							className="w-11 md:w-12 relative"
							color={state.tool === TOOL_ADD ? "cyan" : "white"}
							icon={
								<IconAvatarFeatures
									className={tailwindCascade("w-7 h-7 md:w-8 md:h-8 m-1", {
										"text-white": state.tool === TOOL_ADD,
										"text-black": state.tool !== TOOL_ADD,
									})}
								/>
							}
							roundedLeft={true}
							roundedRight={false}
							onClick={onClickToolAdd}
						/>
						<MenuButton
							className="relative -left-[0.25rem] w-11 md:w-12"
							color="white"
							icon={<IconZoomIn className="w-7 h-7 md:w-8 md:h-8 m-1 text-black" />}
							roundedLeft={false}
							roundedRight={false}
							onClick={() => onClickZoom(1)}
						/>
						<MenuButton
							className="relative -left-[0.5rem] w-11 md:w-12"
							color="white"
							icon={<IconZoomOut className="w-7 h-7 md:w-8 md:h-8 m-1 text-black" />}
							roundedLeft={false}
							roundedRight={false}
							onClick={() => onClickZoom(-1)}
						/>
						<MenuButton
							className="relative -left-[0.75rem] w-11 md:w-12"
							color="white"
							icon={<IconAvatarDelete className="w-7 h-7 md:w-8 md:h-8 m-1 text-black" />}
							roundedLeft={false}
							roundedRight={true}
							onClick={() => onClickDelete()}
						/>
						<MenuButton
							className="relative w-11 md:w-12 -left-[0.5rem] md:left-0"
							icon={<IconAvatarUndo className="w-7 h-7 md:w-8 md:h-8 m-1" />}
							roundedLeft={true}
							roundedRight={true}
							onClick={() => onClickUndo()}
						/>
					</div>
				)}

				{state.mode === MODE_DRAW && (
					<div className="w-53 md:w-60 relative flex flex-row flex-shrink-0 h-12 overflow-hidden">
						<MenuButton
							className="w-11 md:w-12 relative"
							color={state.tool === TOOL_COLOR ? "cyan" : "white"}
							icon={<ColorIcon color={state.color} />}
							roundedLeft={true}
							roundedRight={false}
							onClick={() => onClickToolColor()}
						/>
						<MenuButton
							className="relative -left-[0.25rem] w-11 md:w-12"
							color={state.brush === BRUSH_SMALL ? "cyan" : "white"}
							icon={<BrushMediumIcon className="w-7 h-7 md:w-8 md:h-8 m-1 text-black" />}
							roundedLeft={false}
							roundedRight={false}
							onClick={() => onClickBrush(BRUSH_SMALL)}
						/>
						<MenuButton
							className="relative -left-[0.5rem] w-11 md:w-12"
							color={state.brush === BRUSH_LARGE ? "cyan" : "white"}
							icon={<BrushLargeIcon className="w-7 h-7 md:w-8 md:h-8 m-1 text-black" />}
							roundedLeft={false}
							roundedRight={false}
							onClick={() => onClickBrush(BRUSH_LARGE)}
						/>
						<MenuButton
							className="relative -left-[0.75rem] w-11 md:w-12"
							color={state.brush === BRUSH_DELETE ? "cyan" : "white"}
							icon={
								<EraseIcon
									className={tailwindCascade("w-7 h-7 md:w-8 md:h-8 m-1", {
										"text-white": state.brush === BRUSH_DELETE,
										"text-black": state.brush !== BRUSH_DELETE,
									})}
								/>
							}
							roundedLeft={false}
							roundedRight={true}
							onClick={() => onClickBrush(BRUSH_DELETE)}
						/>
						<MenuButton
							className="elative w-11 md:w-12 -left-[0.5rem] md:left-0"
							icon={<IconAvatarUndo className="w-7 h-7 md:w-8 md:h-8 m-1" />}
							roundedLeft={true}
							roundedRight={true}
							onClick={() => onClickUndo()}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

function Overlay({ state, dispatch }) {
	const onCancel = useCallback(() => {
		if (dispatch) {
			dispatch({
				type: ACTION_FEATURE_CANCEL,
			});
		}
	}, [dispatch]);

	return (state.mode === MODE_MOVE && state.tool === TOOL_ADD) ||
		(state.mode === MODE_DRAW && state.tool === TOOL_COLOR) ? (
		<div className="z-2 absolute inset-0 bg-black bg-opacity-50">
			<button className="absolute top-0 left-0 w-full h-full cursor-default" onClick={onCancel} />
			<div className="container relative flex flex-col justify-end w-full h-full pb-24 mx-auto pointer-events-none">
				<div className="relative w-full px-4 pointer-events-auto">
					{state.tool === TOOL_COLOR && <ColorChooser state={state} dispatch={dispatch} />}
					{state.mode === MODE_MOVE && state.tool === TOOL_ADD && (
						<FeatureChooser state={state} dispatch={dispatch} />
					)}
				</div>
			</div>
		</div>
	) : null;
}

function disposableAppendChild(root, element) {
	root.appendChild(element);
	return () => {
		const parentNode = element.parentNode;
		if (parentNode) {
			parentNode.removeChild(element);
		}
	};
}

function Layers({ state, dispatch, internalState, setInternalState }) {
	const ref = useRef(null);

	const dispatchRef = useRef(dispatch);
	useEffect(() => void (dispatchRef.current = dispatch), [dispatch]);

	const background = internalState.background;
	const layers = internalState.layers;

	const startDrag = useCallback(
		(svg, root, layerGroups, layerGroup, index, uuid, transform, x, y, isBackground = false) => {
			const disposables = [];

			// Bring to front
			const parentNode = layerGroup.parentNode;
			if (!isBackground && parentNode) {
				parentNode.removeChild(layerGroup);
				root.appendChild(layerGroup);
			}

			const outlines = root.querySelectorAll(`[data-outline="true"]`);
			if (outlines) {
				for (let i = 0; i < outlines.length; i++) {
					outlines[i].setAttributeNS(null, "visibility", "hidden");
				}
			}

			const outline = layerGroup.querySelector(`[data-outline="true"]`);
			if (outline) {
				outline.setAttributeNS(null, "visibility", "visible");
			}

			let previousPosition = null;
			const updatePosition = (x, y) => {
				const position = getSVGMousePosition(svg, x, y);
				if (!previousPosition) {
					previousPosition = position;
				}

				const dx = position.x - previousPosition.x || 0;
				const dy = position.y - previousPosition.y || 0;

				previousPosition = position;

				transform.x += dx;
				transform.y += dy;

				layerGroup.setAttributeNS(
					null,
					"transform",
					`translate(${transform.x} ${transform.y}) scale(${transform.z} ${transform.z})`
				);
			};

			const stopDrag = () => {
				disposables.forEach((dispose) => void dispose());
				disposables.splice(0); // Empty

				if (dispatchRef.current) {
					dispatchRef.current({
						type: ACTION_ACTIVE_UPDATE,
						payload: uuid,
					});
				}

				if (isBackground) {
					setInternalState((draft) => {
						if (!draft.background) {
							draft.background = {
								path: null,
								transform: {
									x: 0,
									y: 0,
									z: 1,
									width: 0,
									height: 0,
								},
							};
						}

						if (!draft.background.transform) {
							draft.background.transform = {
								x: 0,
								y: 0,
								z: 1,
								width: 0,
								height: 0,
							};
						}

						draft.background.transform.x = Math.round(transform.x);
						draft.background.transform.y = Math.round(transform.y);
						draft.background.transform.z = transform.z;
					});
				} else {
					setInternalState((draft) => {
						if (index >= 0) {
							const layer = draft.layers[index];
							draft.layers.push(draft.layers.splice(index, 1)[0]); // Move to front

							if (!layer.transform) {
								layer.transform = {};
							}
							layer.transform.x = Math.round(transform.x);
							layer.transform.y = Math.round(transform.y);
							layer.transform.z = transform.z;
						}
					});
				}

				if (dispatchRef.current) {
					dispatchRef.current({
						type: ACTION_HISTORY_UPDATE,
					});
				}
			};

			updatePosition(x, y); // Initial position

			const onMouseMove = (event) => {
				if (event) {
					event.preventDefault();
					updatePosition(event.pageX, event.pageY);
				}
			};

			const onTouchMove = (event) => {
				if (event && event.touches && event.touches.length === 1) {
					event.preventDefault();
					updatePosition(event.touches[0].pageX, event.touches[0].pageY);
				}
			};

			const onMouseUp = () => {
				stopDrag();
			};

			const onTouchEnd = () => {
				stopDrag();
			};

			document.addEventListener("mousemove", onMouseMove);
			disposables.push(() => void document.removeEventListener("mousemove", onMouseMove));

			document.addEventListener("touchmove", onTouchMove);
			disposables.push(() => void document.removeEventListener("touchmove", onTouchMove));

			document.addEventListener("mouseup", onMouseUp);
			disposables.push(() => void document.removeEventListener("mouseup", onMouseUp));

			document.addEventListener("touchend", onTouchEnd);
			disposables.push(() => void document.removeEventListener("touchend", onTouchEnd));

			return () => void disposables.forEach((dispose) => void dispose());
		},
		[setInternalState]
	);

	const initalizeLayerMove = useCallback(
		(svg, root, layerGroups, layerGroup, index, uuid, transform, isBackground) => {
			const disposables = [];

			const paths = [...layerGroup.querySelectorAll("rect, circle, ellipse, line, polyline, polygon, path")];
			if (paths) {
				paths.forEach((path) => {
					const onMouseDown = (event) => {
						if (event && (!event.detail || event.detail === 1)) {
							disposables.push(
								startDrag(
									svg,
									root,
									layerGroups,
									layerGroup,
									index,
									uuid,
									transform,
									event.pageX,
									event.pageY,
									isBackground
								)
							);
						} else if (event && event.detail === 2) {
							// console.log("dblclick");
						}
					};

					const onTouctStart = (event) => {
						if (event && event.touches && event.touches.length === 1) {
							disposables.push(
								startDrag(
									svg,
									root,
									layerGroups,
									layerGroup,
									index,
									uuid,
									transform,
									event.touches[0].pageX,
									event.touches[0].pageY,
									isBackground
								)
							);
						}
					};

					path.addEventListener("mousedown", onMouseDown);
					disposables.push(() => void path.removeEventListener("mousedown", onMouseDown));

					path.addEventListener("touchstart", onTouctStart);
					disposables.push(() => void path.removeEventListener("touchstart", onTouctStart));
				});
			}

			return () => void disposables.forEach((dispose) => void dispose());
		},
		[startDrag]
	);

	const startDraw = useCallback(
		(svg, root, color, thickness, x, y) => {
			const disposables = [];

			const layerGroup = document.createElementNS(SVG_NAMESPACE, "g");
			layerGroup.setAttributeNS(null, "stroke", color);
			layerGroup.setAttributeNS(null, "stroke-width", thickness);
			layerGroup.setAttributeNS(null, "stroke-linecap", "round");
			layerGroup.setAttributeNS(null, "stroke-linejoin", "round");

			const path = document.createElementNS(SVG_NAMESPACE, "path");
			layerGroup.appendChild(path);

			disposables.push(disposableAppendChild(root, layerGroup));

			let paths = [];

			let previousPosition = null;
			const updatePosition = (x, y, force = false) => {
				const position = getSVGMousePosition(svg, x, y);
				if (!previousPosition) {
					previousPosition = position;
				}

				const dx = position.x - previousPosition.x || 0;
				const dy = position.y - previousPosition.y || 0;

				previousPosition = position;

				if (force || Math.abs(dx) > 0 || Math.abs(dy) > 0) {
					paths.push({ x: position.x, y: position.y });
					path.setAttributeNS(null, "d", `M ${paths.map(({ x, y }) => `${x} ${y}`).join(" L ")}`);
				}
			};

			const stopDraw = () => {
				disposables.forEach((dispose) => void dispose());
				disposables.splice(0); // Empty

				paths = softenPath(paths);
				paths = quantizePath(paths);

				setInternalState((draft) => {
					draft.layers.push({
						type: AVATAR_LAYER_LINE,
						color,
						thickness,
						path: paths,
					});
				});

				if (dispatchRef.current) {
					dispatchRef.current({
						type: ACTION_HISTORY_UPDATE,
					});
				}
			};

			updatePosition(x, y, true); // Initial update twice for single point
			updatePosition(x, y, true); // Initial update twice for single point

			const onMouseMove = (event) => {
				if (event) {
					event.preventDefault();
					updatePosition(event.pageX, event.pageY);
				}
			};

			const onTouchMove = (event) => {
				if (event && event.touches && event.touches.length === 1) {
					event.preventDefault();
					updatePosition(event.touches[0].pageX, event.touches[0].pageY);
				}
			};

			const onMouseUp = () => {
				stopDraw();
			};

			const onTouchEnd = () => {
				stopDraw();
			};

			document.addEventListener("mousemove", onMouseMove);
			disposables.push(() => void document.removeEventListener("mousemove", onMouseMove));

			document.addEventListener("touchmove", onTouchMove);
			disposables.push(() => void document.removeEventListener("touchmove", onTouchMove));

			document.addEventListener("mouseup", onMouseUp);
			disposables.push(() => void document.removeEventListener("mouseup", onMouseUp));

			document.addEventListener("touchend", onTouchEnd);
			disposables.push(() => void document.removeEventListener("touchend", onTouchEnd));

			return () => void disposables.forEach((dispose) => void dispose());
		},
		[setInternalState]
	);

	const initalizeLayerDraw = useCallback(
		(svg, root, brush, color) => {
			const disposables = [];

			const thickness = getBrushSize(brush);

			const onMouseDown = (event) => {
				if (event) {
					disposables.push(startDraw(svg, root, color, thickness, event.pageX, event.pageY));
				}
			};

			const onTouctStart = (event) => {
				if (event && event.touches && event.touches.length === 1) {
					disposables.push(
						startDraw(svg, root, color, thickness, event.touches[0].pageX, event.touches[0].pageY)
					);
				}
			};

			svg.addEventListener("mousedown", onMouseDown);
			disposables.push(() => void svg.removeEventListener("mousedown", onMouseDown));

			svg.addEventListener("touchstart", onTouctStart);
			disposables.push(() => void svg.removeEventListener("touchstart", onTouctStart));

			return () => void disposables.forEach((dispose) => void dispose());
		},
		[startDraw]
	);

	const initalizeLayerDrawDelete = useCallback(
		(svg, root, layerGroup, index) => {
			const disposables = [];

			const deleteLayer = () => {
				setInternalState((draft) => {
					draft.layers.splice(index, 1); // Delete

					if (dispatchRef.current) {
						dispatchRef.current({
							type: ACTION_HISTORY_UPDATE,
						});
					}
				});
			};

			const paths = [...layerGroup.querySelectorAll("path")];
			if (paths) {
				paths.forEach((path) => {
					const onMouseDown = (event) => {
						if (event) {
							deleteLayer();
						}
					};

					const onTouctStart = () => {
						if (event && event.touches && event.touches.length === 1) {
							deleteLayer();
						}
					};

					path.addEventListener("mousedown", onMouseDown);
					disposables.push(() => void path.removeEventListener("mousedown", onMouseDown));

					path.addEventListener("touchstart", onTouctStart);
					disposables.push(() => void path.removeEventListener("touchstart", onTouctStart));
				});
			}

			return () => void disposables.forEach((dispose) => void dispose());
		},
		[setInternalState]
	);

	const initalizeLayerPointerEventsNone = useCallback((layerGroup) => {
		layerGroup.setAttributeNS(null, "pointer-events", "none");
		return () => void layerGroup.removeAttributeNS(null, "pointer-events");
	}, []);

	useEffect(() => {
		if (ref.current) {
			const root = ref.current;
			const svg = root.parentNode;

			const disposables = [];
			const layerGroups = [];
			const isOutsideLayerGroups = [];

			if (background && background.path) {
				const layerGroup = document.createElementNS(SVG_NAMESPACE, "g");

				const x = background?.transform?.x || 0;
				const y = background?.transform?.y || 0;
				const z = isFinite(background?.transform?.z) ? background?.transform?.z : 1;

				layerGroup.setAttributeNS(null, "transform", `translate(${x} ${y}) scale(${z} ${z})`);
				layerGroup.innerHTML = background.path || "";

				const outline = layerGroup.querySelector(`[data-outline="true"]`);
				if (outline) {
					outline.setAttributeNS(null, "visibility", state.active === background.uuid ? "visible" : "hidden");
				}

				layerGroups.push(layerGroup);
				isOutsideLayerGroups.push([background, layerGroup]);

				if (state.mode === MODE_MOVE) {
					disposables.push(
						initalizeLayerMove(svg, root, layerGroups, layerGroup, -1, background?.uuid, { x, y, z }, true)
					);
				} else {
					disposables.push(initalizeLayerPointerEventsNone(layerGroup));
				}
			}

			if (layers) {
				layers.forEach((layer, index) => {
					if (
						layer.type === AVATAR_LAYER_FEATURE_EYE ||
						layer.type === AVATAR_LAYER_FEATURE_MOUTH ||
						layer.type === AVATAR_LAYER_FEATURE_HAIR ||
						layer.type === AVATAR_LAYER_FEATURE_HAT
					) {
						const layerGroup = document.createElementNS(SVG_NAMESPACE, "g");
						const x = layer?.transform?.x || 0;
						const y = layer?.transform?.y || 0;
						const z = isFinite(layer?.transform?.z) ? layer?.transform?.z : 1;

						layerGroup.setAttributeNS(null, "transform", `translate(${x} ${y}) scale(${z} ${z})`);
						layerGroup.innerHTML = layer.path || "";

						const outline = layerGroup.querySelector(`[data-outline="true"]`);
						if (outline) {
							outline.setAttributeNS(
								null,
								"visibility",
								state.active === layer.uuid ? "visible" : "hidden"
							);
						}

						layerGroups.push(layerGroup);
						isOutsideLayerGroups.push([layer, layerGroup]);

						if (state.mode === MODE_MOVE) {
							disposables.push(
								initalizeLayerMove(
									svg,
									root,
									layerGroups,
									layerGroup,
									index,
									layer.uuid,
									{ x, y, z },
									false
								)
							);
						} else {
							disposables.push(initalizeLayerPointerEventsNone(layerGroup));
						}
					} else if (layer.type === AVATAR_LAYER_LINE) {
						const layerGroup = document.createElementNS(SVG_NAMESPACE, "g");
						layerGroup.setAttributeNS(null, "stroke", layer.color);
						layerGroup.setAttributeNS(null, "stroke-width", layer.thickness);
						layerGroup.setAttributeNS(null, "stroke-linecap", "round");
						layerGroup.setAttributeNS(null, "stroke-linejoin", "round");

						const path = document.createElementNS(SVG_NAMESPACE, "path");
						path.setAttributeNS(null, "d", `M ${layer.path.map(({ x, y }) => `${x} ${y}`).join(" L ")}`);
						layerGroup.appendChild(path);

						layerGroups.push(layerGroup);

						if (state.mode === MODE_DRAW && state.brush === BRUSH_DELETE) {
							disposables.push(initalizeLayerDrawDelete(svg, root, layerGroup, index));
						}
					}
				});
			}

			if (state.mode === MODE_DRAW) {
				if (state.brush === BRUSH_SMALL || state.brush === BRUSH_LARGE) {
					disposables.push(initalizeLayerDraw(svg, root, state.brush, state.color));
				}
			}

			layerGroups.forEach((layerGroup, index) => {
				disposables.push(disposableAppendChild(root, layerGroup));
			});

			const isInside = (svg, element) => {
				const svgBoundingClientRect = svg.getBoundingClientRect();
				if (svgBoundingClientRect.width <= 0 || svgBoundingClientRect.height <= 0) {
					return true; // Not attached
				}

				const cr = svgBoundingClientRect.width / 2;
				const cx = svgBoundingClientRect.left + cr;
				const cy = svgBoundingClientRect.top + cr;

				const elementBoundingClientRect = element.getBoundingClientRect();
				if (elementBoundingClientRect.width <= 0 || elementBoundingClientRect.height <= 0) {
					return true; // Not attached
				}

				const rx = elementBoundingClientRect.left;
				const ry = elementBoundingClientRect.top;
				const rw = elementBoundingClientRect.width;
				const rh = elementBoundingClientRect.height;

				const distX = Math.abs(cx - rx - rw / 2);
				const distY = Math.abs(cy - ry - rh / 2);

				if (distX > rw / 2 + cr) {
					return false;
				}
				if (distY > rh / 2 + cr) {
					return false;
				}

				if (distX <= rw / 2) {
					return true;
				}
				if (distY <= rh / 2) {
					return true;
				}

				const dx = distX - rw / 2;
				const dy = distY - rh / 2;
				return dx * dx + dy * dy <= cr * cr;
			};

			const outsideLayers = [];
			isOutsideLayerGroups.forEach(([layer, layerGroup], index) => {
				const inside = isInside(svg, layerGroup);
				if (!inside) {
					outsideLayers.push(layer.uuid);
				}
			});
			isOutsideLayerGroups.splice(0); // Clear

			if (outsideLayers.length > 0) {
				setInternalState((draft) => {
					const active = draft.active;
					for (let i = outsideLayers.length - 1; i >= 0; i--) {
						const uuid = outsideLayers[i];
						if (draft.background && draft.background.uuid === uuid) {
							if (active === uuid) {
								draft.active = null;
							}
							draft.background = null;
						} else {
							for (let j = 0; j < draft.layers.length; j++) {
								if (draft.layers[j].uuid === uuid) {
									if (active === uuid) {
										draft.active = null;
									}
									draft.layers.splice(j, 1);
									break;
								}
							}
						}
					}
				}, []);
			}

			return () => {
				disposables.forEach((dispose) => void dispose());
			};
		}
	}, [
		background,
		layers,
		state.active,
		state.mode,
		state.brush,
		state.color,
		initalizeLayerMove,
		initalizeLayerDraw,
		initalizeLayerDrawDelete,
		initalizeLayerPointerEventsNone,
		setInternalState,
	]);

	return <g ref={ref}></g>;
}

const SVG = forwardRef(function SVG({ children, ...props }, forwardedRef) {
	const ref = useForwardRef(forwardedRef);

	return (
		<svg
			ref={ref}
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`0 0 ${PLAYER_AVATAR_CANVAS_SIZE} ${PLAYER_AVATAR_CANVAS_SIZE}`}
			fill="none"
			strokeLinecap="round"
			strokeLinejoin="round"
			width="100%"
			height="100%"
			{...props}
		>
			{children}
		</svg>
	);
});

const AvatarCustomize = forwardRef(function AvatarCustomize(
	{
		name,
		safeName,
		avatar,
		useSafeNames,
		onDone,
		onChangeAvatar,
		onChangeName,
		onChangeSafeName,
		statusWithProgressName,
		teamMode = false,
		enableRemoveTeam = false,
		onRemoveTeam,
		isHost,
		roomId,
		showCode,
		joinOpen,
	},
	forwardedRef
) {
	const ref = useForwardRef(forwardedRef);
	const [mounted, mountedRef] = useRefMounted();

	const playerAvatar = usePlayerAvatarCustomizeStore.getState();
	const updatePlayerAvatarStore = usePlayerAvatarCustomizeStore((state) => state.update);

	const [internalState, setInternalState] = useImmer(
		teamMode
			? {
					name: null,
					background: {
						uuid: null,
						path: null,
						transform: {
							x: 0,
							y: 0,
							z: 1,
							width: 0,
							height: 0,
						},
					},
					layers: [],
					history: [],
					created: false,
			  }
			: {
					name: playerAvatar.name,
					background: playerAvatar.background,
					layers: playerAvatar.layers,
					history: playerAvatar.history,
					created: playerAvatar.created,
			  }
	);

	useEffect(() => {
		updatePlayerAvatarStore((draft) => {
			draft.background = internalState.background;
			draft.layers = internalState.layers;
			draft.history = internalState.history;
			draft.created = internalState.created;
		});
	}, [internalState, updatePlayerAvatarStore]);

	const svgRef = useRef(null);
	const trailsRef = useRef(null);
	const nameRef = useRef(null);

	const onDoneRef = useRef(onDone);
	useEffect(() => void (onDoneRef.current = onDone), [onDone]);

	const onChangeNameRef = useRef(onChangeName);
	useEffect(() => void (onChangeNameRef.current = onChangeName), [onChangeName]);

	const onChangeSafeNameRef = useRef(onChangeSafeName);
	useEffect(() => void (onChangeSafeNameRef.current = onChangeSafeName), [onChangeSafeName]);

	const onChangeAvatarRef = useRef(onChangeAvatar);
	useEffect(() => void (onChangeAvatarRef.current = onChangeAvatar), [onChangeAvatar]);

	const [state, updateState] = useImmer({
		avatar: isArray(avatar) ? avatar : null,

		hasSearchText: true,
		isLoading: false,
		isDone: false,

		color: DEFAULT_COLOR,
		brush: DEFAULT_BRUSH,
		mode: MODE_MOVE,
		tool: TOOL_MOVE,
		active: false,
		featurePositions: {
			hats: 0,
			hairs: 0,
			eyes: 0,
			mouths: 0,
			bodys: 0,
		},
	});

	useEffect(() => void updateState((draft) => void (draft.hasSearchText = true)), [updateState, useSafeNames]);

	useEffect(
		() =>
			void setInternalState((draft) => {
				if (draft.background && draft.background.path && !draft.background.uuid) {
					draft.background.uuid = uuidV4();
				}
			}),
		[setInternalState]
	);

	useEffect(
		() =>
			void setInternalState((draft) => {
				if (draft.layers) {
					for (let i = 0; i < draft.layers.length; i++) {
						if (draft.layers[i].path && !draft.layers[i].uuid) {
							draft.layers[i].uuid = uuidV4();
						}
					}
				}
			}),
		[setInternalState]
	);

	const [, shuffleRef] = useRefCallback(() => {
		setInternalState((draft) => {
			draft.created = true;
			draft.background = null;
			draft.layers = [];
			draft.history = [];
		});

		if (addFeatureRef.current) {
			const features = teamMode ? [getRandomHatFeature()] : getRandomAvatarFeatures();
			addFeatureRef.current(features, 0, teamMode);
		}

		updateState((draft) => {
			draft.mode = MODE_MOVE;
			draft.tool = TOOL_MOVE;
		});

		setInternalState((draft) => {
			draft.history = [];
		});
	}, [setInternalState, updateState]);

	useEffect(() => {
		if (state.isDone) {
			updateState((draft) => void (draft.isDone = false));

			if (internalState.layers && internalState.length === 0 && internalState === null) {
				shuffleRef.current();
			} else if (onDoneRef.current) {
				onDoneRef.current();
			}
		}
	}, [internalState, shuffleRef, state.isDone, updateState]);

	const [updateAvatar, updateAvatarRef] = useRefCallback(
		(callback) => {
			if (mountedRef.current) {
				updateState((draft) => {
					draft.avatar = null;
					if (internalState.background) {
						const x = internalState.background?.transform?.x || 0;
						const y = internalState.background?.transform?.y || 0;
						const z = isFinite(internalState.background?.transform?.z)
							? internalState.background?.transform?.z
							: 1;

						if (!draft.avatar) {
							draft.avatar = [];
						}

						draft.avatar.push({
							url: internalState.background?.url || null,
							x: x.toFixed(4),
							y: y.toFixed(4),
							z: z.toFixed(4),
						});
					}

					if (internalState.layers) {
						internalState.layers.forEach((layer, index) => {
							if (
								layer.type === AVATAR_LAYER_FEATURE_EYE ||
								layer.type === AVATAR_LAYER_FEATURE_MOUTH ||
								layer.type === AVATAR_LAYER_FEATURE_HAIR ||
								layer.type === AVATAR_LAYER_FEATURE_HAT
							) {
								const x = layer?.transform?.x || 0;
								const y = layer?.transform?.y || 0;
								const z = isFinite(layer?.transform?.z) ? layer?.transform?.z : 1;

								if (!draft.avatar) {
									draft.avatar = [];
								}

								draft.avatar.push({
									url: layer?.url || null,
									x: x.toFixed(4),
									y: y.toFixed(4),
									z: z.toFixed(4),
								});
							}
						});
					}

					if (callback) {
						callback(draft);
					}
				});
			}
		},
		[internalState.background, internalState.layers, mountedRef, updateState]
	);

	useEffect(() => {
		if (onChangeAvatarRef.current) {
			onChangeAvatarRef.current(isArray(state.avatar) ? cloneDeep(state.avatar) : []);
		}
	}, [state.avatar]);

	useEffect(() => {
		if (mountedRef && updateAvatarRef.current) {
			updateAvatarRef.current();
		}
	}, [internalState.background, internalState.layers, mountedRef, updateAvatarRef]);

	const [dispatch, dispatchRef] = useRefCallback(
		(action) => {
			switch (action.type) {
				case ACTION_HISTORY_UPDATE:
					if (updateHistoryRef.current) {
						updateHistoryRef.current();
					}
					break;
				case ACTION_CLEAR:
					if (clearRef.current) {
						clearRef.current();
					}
					if (mountedRef.current) {
						updateState((draft) => void (draft.active = null));
					}
					break;
				case ACTION_SHUFFLE:
					if (shuffleRef.current) {
						shuffleRef.current();
					}
					if (mountedRef.current) {
						updateState((draft) => void (draft.active = null));
					}

					break;
				case ACTION_DONE:
					if (updateAvatarRef.current) {
						updateAvatarRef.current((draft) => void (draft.isDone = true));
					}
					break;
				case ACTION_UNDO:
					if (undoRef.current) {
						undoRef.current();
					}
					break;
				case ACTION_MODE_UPDATE:
					if (mountedRef.current) {
						updateState((draft) => {
							draft.mode = action.payload;
							if (draft.mode === MODE_DRAW) {
								draft.tool = BRUSH_SMALL;
							} else {
								draft.tool = TOOL_MOVE;
							}
							draft.active = null;
						});
					}
					break;
				case ACTION_BRUSH_UPDATE:
					if (mountedRef.current) {
						updateState((draft) => void (draft.brush = action.payload));
					}
					break;
				case ACTION_TOOL_UPDATE:
					if (mountedRef.current) {
						updateState((draft) => void (draft.tool = action.payload));
					}
					break;
				case ACTION_TOOL_SCALE:
					if (scaleActiveRef.current) {
						scaleActiveRef.current(action.payload);
					}
					if (mountedRef.current) {
						updateState((draft) => {
							draft.mode = MODE_MOVE;
							draft.tool = TOOL_MOVE;
						});
					}
					break;
				case ACTION_TOOL_DELETE:
					if (deleteActiveRef.current) {
						deleteActiveRef.current();
					}
					if (mountedRef.current) {
						updateState((draft) => {
							draft.mode = MODE_MOVE;
							draft.tool = TOOL_MOVE;
						});
					}
					break;
				case ACTION_DRAW_COLOR:
					if (mountedRef.current) {
						updateState((draft) => {
							draft.mode = MODE_DRAW;
							draft.tool = BRUSH_SMALL;
							draft.color = action.payload;
							draft.active = null;
						});
					}
					break;
				case ACTION_FEATURE_ADD:
					if (addFeatureRef.current) {
						addFeatureRef.current(action.payload[0], action.payload[1]);
					}
					if (mountedRef.current) {
						updateState((draft) => {
							draft.mode = MODE_MOVE;
							draft.tool = TOOL_MOVE;
						});
					}
					break;
				case ACTION_FEATURE_CANCEL:
					if (mountedRef.current) {
						updateState((draft) => {
							draft.mode = MODE_MOVE;
							draft.tool = TOOL_MOVE;
						});
					}
					break;
				case ACTION_FEATURE_POSITION:
					if (mountedRef.current) {
						updateState((draft) => {
							const feature = action.payload[0];
							const position = action.payload[1];
							if (feature === AVATAR_LAYER_FEATURE_HAT) {
								draft.featurePositions.hats = position;
							} else if (feature === AVATAR_LAYER_FEATURE_HAIR) {
								draft.featurePositions.hairs = position;
							} else if (feature === AVATAR_LAYER_FEATURE_EYE) {
								draft.featurePositions.eyes = position;
							} else if (feature === AVATAR_LAYER_FEATURE_MOUTH) {
								draft.featurePositions.mouths = position;
							} else if (feature === AVATAR_LAYER_FEATURE_BODY) {
								draft.featurePositions.bodys = position;
							}
						});
					}
					break;
				case ACTION_TOOL_COLOR_CANCEL:
					if (mountedRef.current) {
						updateState((draft) => {
							draft.mode = MODE_DRAW;
							draft.tool = BRUSH_SMALL;
							draft.active = null;
						});
					}
					break;
				case ACTION_ACTIVE_UPDATE:
					if (mountedRef.current) {
						updateState((draft) => void (draft.active = action.payload));
					}
					break;
				case ACTION_ACTIVE_CANCEL:
					if (mountedRef.current) {
						updateState((draft) => void (draft.active = null));
					}
					break;
			}
		},
		[updateState]
	);

	useImperativeHandle(
		ref,
		() => ({
			done() {
				if (dispatchRef.current) {
					dispatchRef.current({
						type: ACTION_DONE,
					});
				}
			},
		}),
		[dispatchRef]
	);

	const fetchFeature = useCallback((url, callback) => {
		if (url) {
			fetch(PLAYER_AVATAR_BASE_PATH + url)
				.then((response) => response.text())
				.then((text) => {
					if (callback) {
						callback(`<g>${text}</g>`);
					}
				})
				.catch(() => {
					if (callback) {
						callback(null);
					}
				});
		} else {
			if (callback) {
				setTimeout(() => void callback(null));
			}
		}
	}, []);

	useEffect(() => {
		if (state.mode === MODE_DRAW && (state.brush === BRUSH_SMALL || state.brush === BRUSH_LARGE)) {
			const svg = svgRef.current;
			const trails = trailsRef.current;

			if (svg && trails) {
				const positions = [];

				const onMouseMove = (event) => {
					if (event) {
						const position = getSVGMousePosition(svg, event.pageX, event.pageY);
						positions.unshift({ t: Date.now(), ...position });
					}
				};

				const onMouseLeave = () => {
					positions.splice(0); // Empty
				};

				const dispose = animationLoop((delta) => {
					const path = trails.firstElementChild;

					if (positions && positions.length >= 1) {
						let i = positions.findIndex((p) => p.t < Date.now() - 100);
						if (i >= 1) {
							positions.splice(i);
						}

						const d =
							`M ${positions[0].x} ${positions[0].y} ` +
							positions.map((p) => `L ${p.x} ${p.y}`).join(" ");

						path.setAttributeNS(null, "d", d);
					} else {
						path.setAttributeNS(null, "d", "");
					}
				});

				svg.addEventListener("mousemove", onMouseMove);
				svg.addEventListener("mouseleave", onMouseLeave);

				return () => {
					dispose();

					svg.removeEventListener("mousemove", onMouseMove);
					svg.removeEventListener("mouseleave", onMouseLeave);

					if (trails) {
						const path = trails.firstElementChild;
						path.setAttributeNS(null, "d", "");
					}
				};
			}
		}
	}, [state.brush, state.mode]);

	const [, addFeatureRef] = useRefCallback(
		(feature, index, teamMode = false) => {
			const features = isArray(feature) ? feature : [{ feature, index }];
			setInternalState((draft) => {
				let activeUuid = null;
				let updated = false;

				for (let i = 0; i < features.length; i++) {
					const feature = features[i].feature;
					const index = features[i].index;
					const transform = features[i].transform;
					let url = null;
					let x = 0;
					let y = 0.5;
					let z = 1;

					if (AVATAR_FEATURE_PROPS.has(feature)) {
						const featureProps = AVATAR_FEATURE_PROPS.get(feature);
						url = featureProps.urls[index];
						z = transform ? transform.z : featureProps.defaultScale;
						x = transform ? transform.x : (PLAYER_AVATAR_CANVAS_SIZE - PLAYER_AVATAR_CANVAS_SIZE * z) / 2;
						y = transform ? transform.y : featureProps.defaultY * PLAYER_AVATAR_CANVAS_SIZE;
					}

					if (teamMode && feature === AVATAR_LAYER_FEATURE_HAT) {
						z = 0.75;
						const offset = 0.25;
						x = (PLAYER_AVATAR_CANVAS_SIZE - PLAYER_AVATAR_CANVAS_SIZE * z) / 2;
						y =
							(PLAYER_AVATAR_CANVAS_SIZE -
								PLAYER_AVATAR_CANVAS_SIZE * z +
								PLAYER_AVATAR_CANVAS_SIZE * offset) /
							2;
					}

					if (url) {
						const uuid = uuidV4();
						if (feature === AVATAR_LAYER_FEATURE_BODY) {
							draft.background = {
								uuid: uuid,
								outside: false,
								url: url,
								path: null,
								transform: {
									x: x,
									y: y,
									z: z,
									width: PLAYER_AVATAR_CANVAS_SIZE,
									height: PLAYER_AVATAR_CANVAS_SIZE,
								},
							};

							fetchFeature(url, (path) => {
								if (mountedRef.current) {
									setInternalState((draft) => {
										if (draft.background.uuid === uuid) {
											if (!path) {
												draft.background.url = draft.background.path = null;
											} else {
												draft.background.path = path;
											}
										}
									});
								}
							});

							activeUuid = uuid;
							updated = true;
						} else {
							const layer = {
								uuid: uuid,
								outside: false,
								type: feature,
								index: index,
								url: url,
								path: null,
								transform: {
									x: x,
									y: y,
									z: z,
									width: PLAYER_AVATAR_CANVAS_SIZE,
									height: PLAYER_AVATAR_CANVAS_SIZE,
								},
							};
							draft.layers.push(layer);

							(() => {
								fetchFeature(url, (path) => {
									if (mountedRef.current) {
										setInternalState((draft) => {
											for (let i = draft.layers.length - 1; i >= 0; i--) {
												if (draft.layers[i].uuid === uuid) {
													if (path) {
														draft.layers[i].url = url;
														draft.layers[i].path = path;
													} else {
														draft.layers.splice(i, 1);
													}
													break;
												}
											}
										});
									}
								});
							})(uuid);

							activeUuid = uuid;
							updated = true;
						}
					}
				}

				if (activeUuid && mountedRef.current) {
					updateState((draft) => void (draft.active = activeUuid));
				}

				if (updated && dispatchRef.current) {
					dispatchRef.current({
						type: ACTION_HISTORY_UPDATE,
					});
				}
			});
		},
		[fetchFeature, mountedRef, setInternalState]
	);

	const [, scaleActiveRef] = useRefCallback(
		(direction) => {
			if (state.active) {
				setInternalState((draft) => {
					const scale = (layer) => {
						const x = layer?.transform?.x || 0;
						const y = layer?.transform?.y || 0;
						const z = isFinite(layer?.transform?.z) ? layer?.transform?.z : 0;
						let nz = z * 2 ** (0.25 * direction);
						nz = clamp(nz, MIN_SCALE, MAX_SCALE);

						const width = layer.transform.width;
						const height = layer.transform.height;

						if (!layer.transform) {
							layer.transform = {};
						}
						layer.transform.x -= (width * nz - width * z) / 2;
						layer.transform.y -= (height * nz - height * z) / 2;

						layer.transform.z = nz;
					};

					if (draft.background && state.active === draft.background.uuid) {
						scale(draft.background);
						return;
					}
					for (let i = 0; i < draft.layers.length; i++) {
						if (state.active === draft.layers[i].uuid) {
							scale(draft.layers[i]);
							return;
						}
					}
				}, []);

				if (dispatch) {
					dispatch({
						type: ACTION_HISTORY_UPDATE,
					});
				}
			}
		},
		[state.active, dispatch, setInternalState]
	);

	const [, deleteActiveRef] = useRefCallback(() => {
		if (state.active) {
			setInternalState((draft) => {
				if (draft.background && state.active === draft.background.uuid) {
					draft.background = null;

					updateState((draft) => void (draft.active = null));
					return;
				}
				for (let i = 0; i < draft.layers.length; i++) {
					if (state.active === draft.layers[i].uuid) {
						draft.layers.splice(i, 1);
						return;
					}
				}
			}, []);

			if (dispatchRef.current) {
				dispatchRef.current({
					type: ACTION_HISTORY_UPDATE,
				});
			}
		}
	}, [state.active, setInternalState, updateState]);

	const [, clearRef] = useRefCallback(() => {
		setInternalState((draft) => {
			draft.created = true;
			draft.background = null;
			draft.layers = [];
			draft.history = [];
		});
		updateState((draft) => {
			draft.mode = MODE_MOVE;
			draft.tool = TOOL_MOVE;
		});
	}, [setInternalState, updateState]);

	useEffect(() => {
		if (!internalState.created && !teamMode) {
			if (shuffleRef.current) {
				shuffleRef.current();
			}
		}
	}, [internalState.created, shuffleRef, teamMode]);

	const [, createTeamAvatarRef] = useRefCallback(() => {
		setInternalState((draft) => {
			draft.created = true;
			draft.background = null;
			draft.layers = [];
			draft.history = [];
		});

		if (addFeatureRef.current) {
			let feat = [];
			avatar.forEach((part) => {
				const feature = part.url.split("-")[0].replace("eyes", "eye");

				const featureProps = AVATAR_FEATURE_PROPS.get(feature);
				const index = featureProps.urls.findIndex((s) => s === part.url);
				const transform = { x: Number(part.x), y: Number(part.y), z: Number(part.z) };

				feat.push({ feature, index, transform });
			});

			addFeatureRef.current(feat);
		}

		updateState((draft) => {
			draft.mode = MODE_MOVE;
			draft.tool = TOOL_MOVE;
		});

		setInternalState((draft) => {
			draft.history = [];
		});
	}, [setInternalState, updateState]);

	useEffect(() => {
		if (!internalState.created && teamMode) {
			createTeamAvatarRef.current();
		}
	}, [internalState.created, createTeamAvatarRef, teamMode]);

	const firstNameIndex = useMemo(() => {
		if (safeName) {
			const names = safeName.split(" ");
			const firstName = names.length >= 1 ? names[0] : "";
			const firstNameIndex = firstName ? firstNames.indexOf(firstName) : -1;
			return firstNameIndex !== -1 ? firstNameIndex : 0;
		}
		return 0;
	}, [safeName]);

	const lastNameIndex = useMemo(() => {
		if (safeName) {
			const names = safeName.split(" ");
			const lastName = names.length >= 2 ? names[1] : "";
			const lastNameIndex = lastName ? lastNames.indexOf(lastName) : -1;
			return lastNameIndex !== -1 ? lastNameIndex : 0;
		}
		return 0;
	}, [safeName]);

	const onChangeSafeNameFirstName = useCallback(
		(firstNameIndex) => {
			if (mountedRef.current) {
				let lastNameIndex = -1;
				if (safeName) {
					const names = safeName.split(" ");
					const lastName = names.length >= 2 ? names[1] : "";
					lastNameIndex = lastName ? lastNames.indexOf(lastName) : -1;
				}
				if (onChangeSafeNameRef.current) {
					onChangeSafeNameRef.current(
						`${firstNameIndex !== -1 ? firstNames[firstNameIndex] : firstNames[0]} ${
							lastNameIndex !== -1 ? lastNames[lastNameIndex] : lastNames[0]
						}`
					);
				}
			}
		},
		[mountedRef, safeName]
	);

	const onChangeSafeNameLastName = useCallback(
		(lastNameIndex) => {
			if (mountedRef.current) {
				let firstNameIndex = -1;
				if (safeName) {
					const names = safeName.split(" ");
					const firstName = names.length >= 1 ? names[0] : "";
					firstNameIndex = firstName ? firstNames.indexOf(firstName) : -1;
				}
				if (onChangeSafeNameRef.current) {
					onChangeSafeNameRef.current(
						`${firstNameIndex !== -1 ? firstNames[firstNameIndex] : firstNames[0]} ${
							lastNameIndex !== -1 ? lastNames[lastNameIndex] : lastNames[0]
						}`
					);
				}
			}
		},
		[mountedRef, safeName]
	);

	const [, updateHistoryRef] = useRefCallback(() => {
		setInternalState((draft) => {
			draft.history.push({
				name: cloneDeep(name),
				layers: cloneDeep(internalState.layers),
				background: cloneDeep(internalState.background),
			});
			draft.history.splice(0, draft.history.length - MAX_NUMBER_OF_HISTORY_STEPS);
		});
	}, [name, setInternalState]);

	const [, undoRef] = useRefCallback(() => {
		let name = null;
		let layers = [];
		let background = null;
		if (history.length > 0) {
			const previousHistory = cloneDeep(history[history.length - 1]);
			if (previousHistory) {
				name = cloneDeep(previousHistory.name);
				layers = cloneDeep(previousHistory.layers);
				background = cloneDeep(previousHistory.background);
			}
		}

		setInternalState((draft) => {
			draft.name = name;
			draft.layers = layers;
			draft.background = background;

			draft.history.pop();
		});
	}, [history]);

	useEffect(() => {
		const onKeyDown = (event) => {
			if (event.metaKey) {
				if (event.metaKey && event.shiftKey && event.key === "z") {
					//
				} else if (event.metaKey && event.key === "z") {
					if (undoRef.current) {
						undoRef.current();
					}
				}
			} else if (event.ctrlKey) {
				if (event.ctrlKey && event.key === "z") {
					if (undoRef.current) {
						undoRef.current();
					}
				} else if (event.ctrlKey && event.key === "y") {
					//
				}
			} else {
				if (event.key === "f") {
					// bucket
				} else if (event.key === "d") {
					// brush
				} else if (event.key === "[") {
					// palette - 1
				} else if (event.key === "]") {
					// palette + 1
				}
			}
		};

		document.addEventListener("keydown", onKeyDown);
		return () => void document.removeEventListener("keydown", onKeyDown);
	}, [undoRef]);

	const [counter, setCounter] = useState(0);
	const [gameStarted, setGameStarted] = useState(false);

	useEffect(() => {
		if (statusWithProgressName === PLAY_STATUS_GAME_START) {
			setGameStarted(true);
		} else if (statusWithProgressName === PLAY_STATUS_LOBBY) {
			setGameStarted(false);
		} else if (statusWithProgressName === PLAY_STATUS_HIDE_GET_READY) {
			onDone();
		}
	}, [statusWithProgressName, onDone]);

	useEffect(() => {
		if (counter === AVATAR_CUSTOMIZE_TIMEOUT) {
			onDone();
		}
	}, [counter, onDone]);

	useEffect(() => {
		let interval = null;
		if (gameStarted) {
			interval = setInterval(() => {
				setCounter((counter) => counter + 1);
			}, 1000);
		}

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	}, [gameStarted]);

	return (
		<div className="relative flex flex-col items-center justify-start w-full h-full pb-4">
			<TopMenu
				state={state}
				dispatch={dispatch}
				internalState={internalState}
				teamMode={teamMode}
				enableRemoveTeam={enableRemoveTeam}
				onRemoveTeam={onRemoveTeam}
				isHost={isHost}
				roomId={roomId}
				showCode={showCode}
				joinOpen={joinOpen}
			/>
			<div className={`sm:mb-1 mt-3 text-lg font-medium text-white ${gameStarted ? "opacity-100" : "opacity-0"}`}>
				{trans("Game started! Closing in %d sec.", AVATAR_CUSTOMIZE_TIMEOUT - counter)}
			</div>
			<div className={`md:container md:mx-auto sm:mt-1 sm:mb-4 w-full px-4 my-3`}>
				<div className="w-full">
					<div className="flex flex-col items-center w-full">
						{useSafeNames ? (
							<div className="md:w-86 flex flex-col w-full space-y-2">
								<ChevronButton
									index={firstNameIndex}
									innerClassName="bg-white h-12"
									buttonClassName="flex-shrink-0 w-12 h-12 px-2"
									buttonIconClassName="w-6 h-6"
									border={true}
									borderRadius={12}
									tight={true}
									white={true}
									max={firstNames.length - 1}
									onChange={onChangeSafeNameFirstName}
								>
									{firstNames[firstNameIndex]}
								</ChevronButton>
								<ChevronButton
									index={lastNameIndex}
									innerClassName="bg-white h-12"
									buttonClassName="flex-shrink-0 w-12 h-12 px-2"
									buttonIconClassName="w-6 h-6"
									border={true}
									borderRadius={12}
									tight={true}
									white={true}
									max={lastNames.length - 1}
									onChange={onChangeSafeNameLastName}
								>
									{lastNames[lastNameIndex]}
								</ChevronButton>
							</div>
						) : (
							<div className="relative">
								<Input
									ref={nameRef}
									className="mb-0"
									name="name"
									autoComplete="name"
									border={state.hasSearchText ? true : ERROR}
									type="text"
									placeholder={trans("Tap to enter name")}
									value={name}
									onChange={(event) => {
										updateState(
											(draft) => void (draft.hasSearchText = event.target.value.length > 0)
										);
										if (onChangeNameRef.current) {
											onChangeNameRef.current(
												(event && event.target && event.target.value) || ""
											);
										}
									}}
									maxLength={MAX_USER_NAME_LENGTH}
								/>
								{state.hasSearchText && (
									<button
										onClick={() => {
											updateState((draft) => {
												draft.hasSearchText = false;
											});
											if (onChangeNameRef.current) {
												onChangeNameRef.current("");
											}
											if (nameRef.current) {
												nameRef.current.focus();
											}
										}}
										className="top-1/2 right-2 absolute transform -translate-y-1/2"
									>
										<IconClear className="h-10 opacity-50" />
									</button>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			<AvatarContainer state={state} dispatch={dispatch}>
				<SVG ref={svgRef} className="absolute top-0 left-0 w-full h-full">
					<Layers
						state={state}
						dispatch={dispatch}
						internalState={internalState}
						setInternalState={setInternalState}
					/>
				</SVG>
				<SVG className="absolute top-0 left-0 w-full h-full pointer-events-none">
					<g
						ref={trailsRef}
						stroke={state.color}
						strokeWidth={getBrushSize(state.brush)}
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path d="" />
					</g>
				</SVG>
				{state.isLoading && (
					<div className="left-1/2 top-1/2 absolute transform -translate-x-1/2 -translate-y-1/2">
						<Spinner className="w-12 h-12" />
					</div>
				)}
			</AvatarContainer>
			<Overlay state={state} dispatch={dispatch} />
			<Menu state={state} dispatch={dispatch} />
		</div>
	);
});

export default AvatarCustomize;
