import create from "zustand";
import { persist } from "zustand/middleware";
import produce from "immer";

import localStorageGuard from "@/helpers/localStorageGuard";

const usePlayerAvatarCustomizeStore = create(
	persist(
		(set) => {
			return {
				name: null,
				background: {
					uuid: null,
					path: null,
					transform: {
						x: 0,
						y: 0,
						z: 1,
						width: 0,
						height: 0,
					},
				},
				layers: [],
				history: [],
				created: false,

				update: (recipe) => set(produce(recipe)),
				reset: () =>
					set(
						produce((draft) => {
							draft.name = null;
							draft.background = {
								uuid: null,
								path: null,
								transform: {
									x: 0,
									y: 0,
									z: 1,
									width: 0,
									height: 0,
								},
							};
							draft.layers = [];
							draft.history = [];
							draft.created = false;
						})
					),
			};
		},
		{
			name: "playerAvatarCustomize",
			getStorage: () => localStorageGuard,
		}
	)
);

export default usePlayerAvatarCustomizeStore;
