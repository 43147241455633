import React, { useRef, useEffect, useState, useCallback, useMemo, forwardRef } from "react";
import StarEmpty from "@/images/icons/icon-star-empty.svg";
import StarFull from "@/images/icons/icon-star-full.svg";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";
import CoverImage from "@/components/CoverImage";
import ImagePlaceHolder from "@/images/icons/icon-photos.svg";
import gsap from "@/helpers/gsap";
import { ScrollToPlugin } from "gsap/dist/ScrollToPlugin";
import useRefMounted from "@/hooks/useRefMounted";
import useAuthStore from "@/stores/auth";
import getQuizName from "@/helpers/getQuizName";

export function RateFragments({ quizzes, ratings, setRating, onCancel = () => {} }) {
	const [, mountedRef] = useRefMounted();
	const user = useAuthStore((state) => state.user);

	useEffect(() => {
		gsap.registerPlugin(ScrollToPlugin);
	}, []);

	const itemsRef = useRef(quizzes.map((quiz) => null));
	const containerRef = useRef(null);

	const itemWidth = 304; // width of quiz item
	const itemOverlap = 0.05 * itemWidth; // how much quizzes on the side extends into view

	const [containerWidth, setContainerWidth] = useState(null);

	useEffect(() => {
		const container = containerRef.current;
		setContainerWidth(container ? container.clientWidth : null);

		if (container) {
			const resizeObserver = new ResizeObserver(
				(entries) => void setContainerWidth(entries[0].target.clientWidth)
			);

			resizeObserver.observe(container);
			return () => void resizeObserver.unobserve(container);
		}
	}, []);

	const [pos, setPos] = useState(0);

	useEffect(() => {
		const gap = 0.5 * containerWidth - (0.5 * itemWidth + itemOverlap);

		const tween = gsap.to(containerRef.current, {
			duration: 0.5,
			scrollTo: { x: pos * (itemWidth + gap) },
		});

		return () => void tween.kill();
	}, [containerWidth, pos, itemOverlap]);

	return (
		<div
			className={tailwindCascade(
				"md:w-160 md:h-auto md:py-8 w-full h-full md:rounded-lg",
				"justify-evenly flex flex-col md:gap-8",
				"bg-purple-dark text-white"
			)}
		>
			<div className="text-lg font-black leading-none text-center">
				{trans("How many stars do you give these questions?")}
			</div>
			<div
				ref={containerRef}
				className={tailwindCascade(
					`flex flex-row`,
					"overflow-auto",
					"scrollbar-none scrollbar-track-transparent scrollbar-thumb-white-50",
					// "snap-x snap-mandatory",
					"w-full"
				)}
				style={{ gap: `calc(50% - ${0.5 * itemWidth + itemOverlap}px)` }}
			>
				<div className="shrink-0" style={{ width: `${itemOverlap}px` }}></div>
				<>
					{quizzes.map((quiz, i) => (
						<RateFragment
							id={`quiz${i}`}
							// className={`snap-center`}
							width={itemWidth}
							key={i}
							ref={(el) => {
								itemsRef.current[i] = el;
							}}
							quiz={quiz}
							rating={ratings && ratings[quiz.id]}
							setRating={(value) => {
								setRating(value, quiz.id);
								sleep(500).then(() => {
									if (mountedRef.current) {
										setPos(pos + 1);
									}
								});
							}}
						/>
					))}
				</>
				<div className="shrink-0" style={{ width: `${itemOverlap}px` }}></div>
			</div>
			{onCancel && (
				<>
					<div></div>
					<button className="text-base font-black underline opacity-50" onClick={() => onCancel()}>
						{trans("Cancel")}
					</button>
				</>
			)}
		</div>
	);
}

const RateFragment = forwardRef(function RateFragment(
	{ width, className, quiz, rating, setRating, style, ...props },
	forwardedRef
) {
	const user = useAuthStore((state) => state.user);

	return (
		<div
			ref={forwardedRef}
			className={tailwindCascade(
				"flex flex-col items-center gap-6",
				"overflow-visible bg-black bg-opacity-25 border-4 border-black border-solid rounded-lg p-2 box-border",
				className
			)}
			style={{ ...style, width: `${width}px` }}
			{...props}
		>
			<div className="relative w-full" style={{ height: `${(216 * width) / 312}px` }}>
				{quiz.medias &&
					quiz.medias.map((media, i) => (
						<div key={i} className="top-1/2 left-1/2 absolute transform -translate-x-1/2 -translate-y-1/2">
							<div
								className="overflow-hidden bg-black border-4 border-black border-solid rounded-lg"
								style={{
									width: `${(160 * width) / 312}px`,
									height: `${(120 * width) / 312}px`,
									transform: `rotate(${[-12, 7, -3][i]}deg) translateX(${
										[-45, 45, 0][i]
									}%) translateY(${[-25, -30, 30][i]}%)`,
								}}
							>
								{media ? (
									<CoverImage media={media} width={160} height={120} />
								) : (
									<ImagePlaceHolder className="w-14 h-full m-auto text-white opacity-50" />
								)}
							</div>
						</div>
					))}
			</div>
			<div className="h-24 text-xl font-black text-center">{getQuizName(quiz)}</div>
			<div className="relative flex-grow-0">
				<div className={tailwindCascade("flex flex-row -space-x-6")}>
					{[1, 2, 3, 4, 5].map((i) => (
						<div className="w-20 h-20" key={i}>
							<button disabled={rating > 0 ? true : undefined} type="button" onClick={() => setRating(i)}>
								{i <= rating ? (
									<StarFull className="text-cyan w-full h-full" />
								) : (
									<StarEmpty className="text-cyan w-full h-full" />
								)}
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
	);
});

function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}
