import {
	SLIDE_TYPE_CHECK_BOXES,
	SLIDE_TYPE_CLASSIC,
	SLIDE_TYPE_INFO_SLIDE,
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_PINPOINT,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_TYPE_ANSWER,
} from "@/app-constants.mjs";
import { deserialize } from "@/helpers/map";
import isString from "lodash/isString";
import isFinite from "lodash/isFinite";

export function isSlideValid(slide) {
	switch (slide?.type) {
		case SLIDE_TYPE_INFO_SLIDE:
			// InfoSlide is valid if it has question or media
			return slide?.question || slide?.media?.source;

		case SLIDE_TYPE_RANGE:
			try {
				const [value] = slide.answers[0].text.split(" ", 1).map(parseFloat);
				return isFinite(value);
			} catch (err) {
				return false;
			}

		case SLIDE_TYPE_LOCATION:
			try {
				const options = slide.answers;
				if (options && options.length >= 2) {
					const { target } = deserialize(options);
					if (
						(target?.placeType && target?.placeCode) ||
						(isFinite(target?.lat) && isFinite(target?.lng) && isFinite(target?.radius))
					) {
						return true;
					}
				}
			} catch (error) {
				//
			}

			return false;

		case SLIDE_TYPE_PINPOINT:
			return !!slide?.media && (slide?.answers || []).filter(({ text }) => text).length > 0;

		case SLIDE_TYPE_CHECK_BOXES:
			return (slide?.answers || []).filter((ans) => ans.isCorrect).length >= 1;

		case SLIDE_TYPE_CLASSIC:
			return (
				(slide?.answers || []).filter((ans) => ans.text && ans.isCorrect).length === 1 && // one correct answer
				(slide?.answers || []).filter((ans) => ans.text && !ans.isCorrect).length >= 1 // one or more incorrect
			);

		case SLIDE_TYPE_TYPE_ANSWER:
			return (
				(slide?.answers || []).filter((ans) => isString(ans.text) && ans.text.length > 0 && ans.isCorrect)
					.length >= 1
			);

		default:
			return true;
	}
}

export function isQuizValid(quiz) {
	return !!quiz?.slides && quiz.slides.some(isSlideValid);
}
