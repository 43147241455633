import isArray from "lodash/isArray";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuidV4 } from "uuid";

import usePlayerStore from "@/stores/player";
import usePlayerAvatarCustomizeStore from "@/stores/playerAvatarCustomize";
import getRandomAvatarFeatures from "./getRandomAvatarFeatures";

import {
	PLAYER_AVATAR_CANVAS_SIZE,
	PLAYER_AVATAR_HAT_DEFAULT_SCALE,
	PLAYER_AVATAR_HAT_DEFAULT_Y,
	PLAYER_AVATAR_HATS,
	PLAYER_AVATAR_HAIR_DEFAULT_SCALE,
	PLAYER_AVATAR_HAIR_DEFAULT_Y,
	PLAYER_AVATAR_HAIRS,
	PLAYER_AVATAR_EYE_DEFAULT_SCALE,
	PLAYER_AVATAR_EYE_DEFAULT_Y,
	PLAYER_AVATAR_EYES,
	PLAYER_AVATAR_MOUTH_DEFAULT_SCALE,
	PLAYER_AVATAR_MOUTH_DEFAULT_Y,
	PLAYER_AVATAR_MOUTHS,
	PLAYER_AVATAR_BODY_DEFAULT_SCALE,
	PLAYER_AVATAR_BODY_DEFAULT_Y,
	PLAYER_AVATAR_BODYS,
	AVATAR_LAYER_NONE,
	AVATAR_LAYER_FEATURE_EYE,
	AVATAR_LAYER_FEATURE_MOUTH,
	AVATAR_LAYER_FEATURE_HAIR,
	AVATAR_LAYER_FEATURE_HAT,
	AVATAR_LAYER_FEATURE_BODY,
	AVATAR_LAYER_LINE,
	AVATAR_FEATURE_PROPS,
} from "@/constants";

export default function getInitialAvatar() {
	let initialAvatar = cloneDeep(usePlayerStore.getState().avatar);
	if (!isArray(initialAvatar)) {
		// User not created an avatar?
		initialAvatar = null;

		// Check if there is an avatar in playerAvatarCustomizeStore
		const background = cloneDeep(usePlayerAvatarCustomizeStore.getState().background);
		if (background && background.url) {
			if (!initialAvatar) {
				initialAvatar = [];
			}

			const x = background?.transform?.x || 0;
			const y = background?.transform?.y || 0;
			const z = isFinite(background?.transform?.z) ? background?.transform?.z : 1;

			initialAvatar.push({
				url: background.url,
				x: x.toFixed(4),
				y: y.toFixed(4),
				z: z.toFixed(4),
			});
		}

		const layers = cloneDeep(usePlayerAvatarCustomizeStore.getState().layers);
		if (layers) {
			layers.forEach((layer, index) => {
				if (layer.url) {
					if (
						layer.type === AVATAR_LAYER_FEATURE_EYE ||
						layer.type === AVATAR_LAYER_FEATURE_MOUTH ||
						layer.type === AVATAR_LAYER_FEATURE_HAIR ||
						layer.type === AVATAR_LAYER_FEATURE_HAT
					) {
						const x = layer?.transform?.x || 0;
						const y = layer?.transform?.y || 0;
						const z = isFinite(layer?.transform?.z) ? layer?.transform?.z : 1;

						if (!initialAvatar) {
							initialAvatar = [];
						}

						initialAvatar.push({
							url: layer.url,
							x: x.toFixed(4),
							y: y.toFixed(4),
							z: z.toFixed(4),
						});
					}
				}
			});
		}

		// No avatar in playerAvatarCustomizeStore, create a ranom one
		if (!isArray(initialAvatar)) {
			initialAvatar = null;
			const features = getRandomAvatarFeatures();
			for (let i = 0; i < features.length; i++) {
				const feature = features[i].feature;
				const index = features[i].index;
				let url = null;
				let x = 0;
				let y = 0.5;
				let z = 1;

				if (AVATAR_FEATURE_PROPS.has(feature)) {
					const featureProps = AVATAR_FEATURE_PROPS.get(feature);
					url = featureProps.urls[index];
					z = featureProps.defaultScale;
					x = (PLAYER_AVATAR_CANVAS_SIZE - PLAYER_AVATAR_CANVAS_SIZE * z) / 2;
					y = featureProps.defaultY * PLAYER_AVATAR_CANVAS_SIZE;
				}

				if (url) {
					if (!initialAvatar) {
						initialAvatar = [];
					}

					initialAvatar.push({
						url,
						x: x.toFixed(4),
						y: y.toFixed(4),
						z: z.toFixed(4),
					});
				}
			}
		}
	}

	return initialAvatar;
}
