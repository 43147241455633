import React, { useState, useEffect, useCallback } from "react";
import Button from "@/components/interactives/Button";

import ChevronLeftIcon from "@/images/icons/chevron-left.svg";
import ChevronRightIcon from "@/images/icons/chevron-right.svg";

import { tailwindCascade } from "@/helpers/tailwindCascade";

const loop = (value, min, max) => ((((value - min) % (max - min + 1)) + (max - min + 1)) % (max - min + 1)) + min;

export default function ChevronButton({
	children,
	border = false,
	tight = false,
	white = false,
	borderRadius = undefined,
	index,
	min,
	max,
	onChange,
	innerClassName,
	buttonClassName,
	buttonIconClassName,
	className,
	...props
}) {
	const [indexState, setIndexState] = useState(loop(index || 0, min || 0, max || 0));
	const [minState, setMinState] = useState(min);
	const [maxState, setMaxState] = useState(max);

	useEffect(() => void setIndexState(loop(index || 0, minState || 0, maxState || 0)), [index, minState, maxState]);
	useEffect(() => void setMinState(min), [min]);
	useEffect(() => void setMaxState(max), [max]);

	useEffect(() => {
		if (onChange) {
			onChange(indexState);
		}
	}, [indexState]);

	const onClickLeft = useCallback(() => {
		setIndexState((indexState) => {
			return loop(indexState - 1, minState || 0, maxState || 0);
		});
	}, [indexState, minState, maxState]);

	const onClickRight = useCallback(() => {
		setIndexState((indexState) => {
			return loop(indexState + 1, minState || 0, maxState || 0);
		});
	}, [indexState, minState, maxState]);

	return (
		<div className={tailwindCascade("relative", className)} {...props}>
			<div className="flex flex-row items-center justify-center w-full">
				<Button
					className={tailwindCascade(
						"h-14 py-0 text-white",
						{
							"px-6": !tight,
							"px-4": tight,
						},
						buttonClassName
					)}
					color={white ? "white" : "pink-light"}
					border={border}
					borderRadius={borderRadius}
					roundedLeft={true}
					roundedRight={false}
					onClick={onClickLeft}
				>
					<ChevronLeftIcon
						className={tailwindCascade(
							"w-8 h-8",
							{
								"text-black": white,
								"text-white": !white,
							},
							buttonIconClassName
						)}
					/>
				</Button>
				<div
					className={tailwindCascade(
						"h-14",
						"bg-beige",
						"relative",
						"flex",
						"items-center",
						"justify-center",
						"w-full",
						"text-xl",
						"font-bold",
						"leading-6",
						"text-black",
						{
							"shadow-hard-0.5": !border,
						},
						innerClassName
					)}
					style={{
						borderTop: border ? "0.25rem solid #000" : 0,
						borderBottom: border ? "0.25rem solid #000" : 0,
						borderLeft: !border ? "0.125rem solid #000" : 0,
						borderRight: !border ? "0.125rem solid #000" : 0,
					}}
				>
					<div className="opacity-30 absolute bottom-0 left-0 w-full h-1 bg-black" />
					{border && (
						<div
							className="absolute bg-black"
							style={{ left: "-0.25rem", width: "0.25rem", height: "calc(100% + 0.5rem)" }}
						/>
					)}
					<div className="flex flex-col items-center justify-center w-full h-full mb-1 overflow-hidden leading-none text-center select-none">
						<div>{children}</div>
					</div>
					{border && (
						<div
							className="absolute bg-black"
							style={{ right: "-0.25rem", width: "0.25rem", height: "calc(100% + 0.5rem)" }}
						/>
					)}
				</div>
				<Button
					className={tailwindCascade(
						"h-14 py-0 text-white",
						{
							"px-6": !tight,
							"px-4": tight,
						},
						buttonClassName
					)}
					color={white ? "white" : "cyan"}
					border={border}
					borderRadius={borderRadius}
					roundedLeft={false}
					roundedRight={true}
					onClick={onClickRight}
				>
					<ChevronRightIcon
						className={tailwindCascade(
							"w-8 h-8",
							{
								"text-black": white,
								"text-white": !white,
							},
							buttonIconClassName
						)}
					/>
				</Button>
			</div>
		</div>
	);
}
