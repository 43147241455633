import isString from "lodash/isString";
import { firstNames, lastNames } from "@/data/names";

export default function getVerifiedAvatarSafeName(safeName) {
	if (!isString(safeName)) {
		return null;
	}

	const names = safeName.split(" ");
	if (names.length !== 2) {
		return null;
	}

	if (!firstNames.includes(names[0])) {
		return null;
	}

	if (!lastNames.includes(names[1])) {
		return null;
	}

	return safeName;
}
