import React, { useMemo, useEffect, useRef } from "react";
import sample from "lodash/sample";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import Header from "@/components/Header";
import ScaleContainer from "@/components/pages/play/ScaleContainer";
import TextToSpeech from "@/components/pages/TextToSpeech";
import { DISCORD_LINK, MAX_NR_OF_PLAYERS } from "@/constants";
import useSettingModalStore from "@/stores/settingsModal";
import Link from "next/link";

/*
const RANDOM_TEXTS = [
	trans("Host tip: Kick unwanted players in Settings."),
	trans("Point your camera at the QR code for easy joining."),
	trans("Avoid inappropriate names? Turn on 'Safe player names' in Settings."),
	trans("Don't want more players to join? Turn off 'Joining is open' in Settings."),
	trans("Hide the PIN code in Settings if you don't want everybody to join."),
	trans("Adjust the sound volume in the Settings menu."),
	trans("Pause the game whenever you want from the top bar."),
	trans("Adjust the settings during the game from the top right corner."),
	trans(`Invite and play with up to %d people.`, MAX_NR_OF_PLAYERS),
	trans("Report inappropriate content? Look for the 'Report this quiz' link."),
	trans("Got feedback? info@quiz.com"),
	trans("Join our community on Discord."),
];
*/

function InfoText({ visible }) {
	const show = useSettingModalStore((state) => state.show);
	const showRef = useRef(show);
	useEffect(() => void (showRef.current = show), [show]);

	const text = useMemo(() => {
		const onClickSettings = () => {
			if (showRef.current) {
				showRef.current();
			}
		};

		return sample([
			<>
				{trans("Host tip: Kick unwanted players in")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>
				{trans(".")}
			</>,
			<>{trans("Point your camera at the QR code for easy joining.")}</>,
			<>
				{trans("Avoid inappropriate names? Turn on 'Safe player names' in")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>
				{trans(".")}
			</>,
			<>
				{trans("Don't want more players to join? Turn off 'Joining is open' in")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>
				{trans(".")}
			</>,
			<>
				{trans("Hide the PIN code in")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>{" "}
				{trans("if you don't want everybody to join.")}
			</>,
			<>
				{trans("Adjust the sound volume in the")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>{" "}
				{trans("menu.")}
			</>,
			<>{trans("Pause the game whenever you want from the top bar.")}</>,
			<>
				{trans("Hide the PIN code in")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>{" "}
				{trans("if you don't want everybody to join.")}
			</>,
			<>
				{trans("Adjust the")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>{" "}
				{trans("during the game from the top right corner.")}
			</>,
			<>{trans(`Invite and play with up to %d people.`, MAX_NR_OF_PLAYERS)}</>,
			<>{trans("Report inappropriate content? Look for the 'Report this quiz' link.")}</>,
			<>
				{trans("Got feedback?")}{" "}
				<Link legacyBehavior href="mailto:info@quiz.com" prefetch={false}>
					<a
						className="font-sans text-base font-bold text-white underline cursor-pointer"
						title={trans("Contact")}
					>
						{trans("Contact")}
					</a>
				</Link>
				{trans(".")}
			</>,
			<>
				{trans("Join our community on")}{" "}
				<Link legacyBehavior href={DISCORD_LINK} prefetch={false} target="_blank">
					<a
						className="font-sans text-base font-bold text-white underline cursor-pointer"
						title={trans("Discord")}
					>
						{trans("Discord")}
					</a>
				</Link>
				{trans(".")}
			</>,
			<>
				{trans("Don't like flags beside username? Use 'Hide country flags' in")}{" "}
				<button
					className="font-sans text-base font-bold text-white underline cursor-pointer"
					onClick={onClickSettings}
				>
					{trans("Settings")}
				</button>
				{trans(" to disable them.")}
			</>,
		]);
	}, []);

	if (!text) {
		return null;
	}
	/*
	<>
	{trans("Avoid inappropriate names? Turn on 'Safe player names' in ")}
	<button
		className="font-sans text-base font-bold text-white underline cursor-pointer"
		onClick={onClickSettings}
	>
		{trans("Settings")}
	</button>
	{trans(".")}
</>
*/
	return (
		<div
			className={tailwindCascade(
				"absolute bottom-8 left-0 w-full text-center font-sans font-bold text-base text-white opacity-50 px-8 py-4",
				"duration-150 transition-opacity",
				{
					"opacity-0": !visible,
				}
			)}
		>
			{text}
		</div>
	);
}

export default function GetReady({ onLoad, visible, isHost = false }) {
	useEffect(() => void (onLoad && onLoad()), [onLoad]);

	/*
		const [text, setText] = useState(null);
		useEffect(() => void setText(sample(RANDOM_TEXTS)), []);
	*/

	return (
		<div className="absolute top-0 left-0 w-full h-full">
			<ScaleContainer className="md:block hidden" marginBottom={false}>
				<div
					className={tailwindCascade(
						"relative flex flex-col items-center justify-center w-full h-full py-8",
						"duration-150 transition-opacity",
						{
							"opacity-0": !visible,
						}
					)}
				>
					<img
						src="/images/logo/quiz-multicolor.svg"
						width="700"
						height="188"
						alt={trans("Quiz.com")}
						draggable={false}
						className="block w-full h-auto object-contain max-w-[44rem] mb-6"
					/>
					<Header className="text-6xl text-center">{trans("Play & Create")}</Header>
				</div>
			</ScaleContainer>
			<div
				className={tailwindCascade(
					"relative md:hidden flex flex-col items-center justify-center w-full h-full max-w-screen-sm mx-auto px-16 py-8",
					"duration-150 transition-opacity",
					{
						"opacity-0": !visible,
					}
				)}
			>
				<img
					src="/images/logo/quiz-multicolor.svg"
					width="374"
					height="101"
					alt={trans("Quiz.com")}
					draggable={false}
					className="block object-contain w-full h-auto mb-4"
				/>
				<Header className="text-2xl text-center">{trans("Play & Create")}</Header>
			</div>

			<InfoText visible={visible} />

			<TextToSpeech voice="Samantha" text="Quiz.com - Play & Create" isHost={isHost} play={visible} />
		</div>
	);
}
