import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Avatar from "@/components/Avatar";
import ProgressIndicator from "@/components/ProgressIndicator";
import Button, { BUTTON_BORDER_RADIUS } from "@/components/interactives/Button";
import ScaleContainer from "@/components/pages/play/ScaleContainer";
import SlideHeader from "@/components/pages/play/SlideHeader";
import SlideMedia from "@/components/pages/play/SlideMedia";

import { getConnectedPlayers } from "@/helpers/player";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import useBrowser from "@/hooks/useBrowser";
import useElementSize from "@/hooks/useElementSize";
import useStatusSequence from "@/hooks/useStatusSequence";
import useViewportSize from "@/hooks/useViewportSize";

import { SLIDE_TYPE_PINPOINT } from "@/app-constants.mjs";
import {
	PLAY_STATUS_HIDE_FUN_FACT,
	PLAY_STATUS_HIDE_SLIDE,
	PLAY_STATUS_SHOW_CORRECT_ANSWER,
	PLAY_STATUS_SHOW_FUN_FACT,
	PLAY_STATUS_SHOW_MEDIA,
	PLAY_STATUS_SHOW_QUESTION,
	PLAY_STATUS_WAIT_FOR_ANSWER,
	PLAY_STATUS_WAIT_FOR_MEDIA,
	PLAY_STATUS_YOUTUBE_END_1,
} from "@/constants";

import TextVoice from "../../TextVoice";
import { getPinpointPathString, getPinpointPathsFromString } from "../../edit/PinpointSlideEditor";
import FunFactMedia from "../FunFactMedia";
import { SlidePlayers } from "../Players";
import { FUNFACT_MAX_HEIGHT, FUN_FACT_MEDIA_LOAD_TIMEOUT } from "./BaseSlide";

const SVG_WIDTH = 400;
const SVG_HEIGHT = 300;

const QUESTION_MAX_HEIGHT = (3.5 / 3) * 16 * 3 * 4; // 2 lines

export default function PinpointSlide({
	className,
	haveLocalPlayer,
	isHost,
	isPaused,
	mute,
	onAnswer,
	onComplete,
	onError,
	onFunFactEnd,
	onLoad,
	onLocalJoin,
	onQuestionStart,
	player,
	players,
	statusWithProgress,
	setFunFactMediaIsPlayingWithSound,
	setSlideMediaIsPlayingWithSound,
	slide,
	submittedAnswer,
	type = SLIDE_TYPE_PINPOINT,
	voiceOverride,
	teams,
	teamMode,
	doublePoints,
	...props
}) {
	const ref = useRef(null);
	const browser = useBrowser();

	const { isDesktop } = useViewportSize();

	const hasQuestion = slide && slide.question;
	const hasVoice = slide && slide.id && slide.questionVoice && slide.question;
	const hasMedia = slide && slide.media;

	const pathString = useMemo(() => getPinpointPathString(slide.answers), [slide.answers]);
	const paths = useMemo(
		() =>
			getPinpointPathsFromString({
				pathString,
				merge: true,
				svgWidth: SVG_WIDTH,
				svgHeight: SVG_HEIGHT,
			}),
		[pathString]
	);

	const [pendingAnswer, setPendingAnswer] = useState(null);

	const mediaRef = useRef();

	const onQuestionStartRef = useRef(onQuestionStart);
	useEffect(() => void (onQuestionStartRef.current = onQuestionStart), [onQuestionStart]);

	const { getVisibility, getProgress } = useStatusSequence(type, statusWithProgress);

	const showQuestion = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_QUESTION, PLAY_STATUS_HIDE_FUN_FACT),
		[getVisibility]
	);

	const answerPlayers = useMemo(
		() => getConnectedPlayers([...players.values()]).filter((player) => player.selectedAnswer),
		[players]
	);

	const questionProgress = useMemo(() => getProgress(PLAY_STATUS_SHOW_QUESTION), [getProgress]);

	const showMedia = useMemo(() => getVisibility(PLAY_STATUS_SHOW_MEDIA, PLAY_STATUS_SHOW_FUN_FACT), [getVisibility]);

	const showProgressBar = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);
	const waitForAnswerProgress = useMemo(() => getProgress(PLAY_STATUS_WAIT_FOR_ANSWER), [getProgress]);

	const [readQuestion, setReadQuestion] = useState(false);
	useEffect(() => {
		if (showQuestion && statusWithProgress.name !== PLAY_STATUS_WAIT_FOR_ANSWER) {
			setReadQuestion(true);
		}
	}, [showQuestion, statusWithProgress.name]);

	const noAnimation = useMemo(
		() => !getVisibility(PLAY_STATUS_SHOW_MEDIA, PLAY_STATUS_WAIT_FOR_ANSWER),
		[getVisibility]
	);

	const slideMediaVolumeEnvelope = 1 - getProgress(PLAY_STATUS_YOUTUBE_END_1);

	const interactiveStreetView = useMemo(
		() => getVisibility(PLAY_STATUS_WAIT_FOR_ANSWER, PLAY_STATUS_SHOW_FUN_FACT),
		[getVisibility]
	);

	useEffect(() => {
		if (!hasMedia && onLoad) {
			onLoad();
		}
	}, [hasMedia, onLoad]);

	useEffect(() => {
		if (statusWithProgress.name === PLAY_STATUS_WAIT_FOR_MEDIA) {
			if (mediaRef.current) {
				let canceled = false;
				mediaRef.current
					.play()
					.then(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					})
					.catch(() => {
						if (!canceled && onQuestionStartRef.current) {
							onQuestionStartRef.current();
						}
					});
				return () => void (canceled = true);
			}
		}
	}, [statusWithProgress.name, statusWithProgress.progress]);

	const getMediaBorderRadius = useCallback(
		(isYoutube) => (isYoutube && browser?.satisfies({ safari: ">=1" }) ? "0" : "1.5rem"),
		[browser]
	);

	const slideMediaIsYoutube = slide?.media?.type === "youtube";

	const slideMediaContainerStyle = useMemo(
		() => ({ borderRadius: getMediaBorderRadius(slideMediaIsYoutube) }),
		[getMediaBorderRadius, slideMediaIsYoutube]
	);

	const funfactMediaIsYoutube = slide?.funFactMedia?.type === "youtube";
	const funfactMediaContainerStyle = useMemo(
		() => ({ borderRadius: getMediaBorderRadius(funfactMediaIsYoutube) }),
		[funfactMediaIsYoutube, getMediaBorderRadius]
	);

	const showCorrectAnswer = useMemo(() => getVisibility(PLAY_STATUS_SHOW_CORRECT_ANSWER), [getVisibility]);
	const svgRef = useRef(null);

	const slideIsWaitingForAnswers = statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER;

	const onClickSvg = useMemo(() => {
		if (!slideIsWaitingForAnswers || submittedAnswer) {
			return undefined;
		}

		return (ev) => {
			const clientRect = svgRef.current.getBoundingClientRect();
			const x = (SVG_WIDTH * (ev.clientX - clientRect.x)) / clientRect.width;
			const y = (SVG_HEIGHT * (ev.clientY - clientRect.y)) / clientRect.height;
			setPendingAnswer({ x, y });
		};
	}, [slideIsWaitingForAnswers, submittedAnswer]);

	const onClickSubmit = useMemo(() => {
		if (!slideIsWaitingForAnswers || submittedAnswer || !pendingAnswer) {
			return false;
		}

		return () => {
			onAnswer(slide.index, pendingAnswer);
		};
	}, [onAnswer, pendingAnswer, slide, slideIsWaitingForAnswers, submittedAnswer]);

	const hasSlideMedia = !!slide.media;
	const hasFunFactText = !!slide.funFact;
	const hasFunFactMedia = !!slide?.funFactMedia;

	const showFunFact = useMemo(
		() => getVisibility(PLAY_STATUS_SHOW_FUN_FACT, PLAY_STATUS_HIDE_SLIDE),
		[getVisibility]
	);
	const showFunFactMedia = hasFunFactMedia && showFunFact;

	const [funFactMediaLoaded, setFunFactMediaLoaded] = useState(false);
	useEffect(() => {
		if (!showFunFactMedia) {
			setFunFactMediaLoaded(false);
		} else {
			const timeout = setTimeout(() => setFunFactMediaLoaded(true), FUN_FACT_MEDIA_LOAD_TIMEOUT);
			return () => void clearTimeout(timeout);
		}
	}, [showFunFactMedia]);
	const onLoadFunFactMedia = useCallback(() => void setFunFactMediaLoaded(true), []);

	const funFactMediaElementRef = useRef();

	useEffect(() => {
		if (
			statusWithProgress.name === PLAY_STATUS_SHOW_FUN_FACT &&
			funFactMediaElementRef.current &&
			funfactMediaIsYoutube
		) {
			funFactMediaElementRef.current.play();
		}
	}, [statusWithProgress.name, funfactMediaIsYoutube]);

	const onFunFactEndRef = useRef(onFunFactEnd);
	useEffect(() => void (onFunFactEndRef.current = onFunFactEnd), [onFunFactEnd]);
	const onYoutubeEnd = useCallback(() => {
		if (onFunFactEndRef.current) {
			onFunFactEndRef.current();
		}
	}, []);

	// const slideMediaVolumeEnvelope =
	// 	1 - getProgress(hasFunFactText && !hasFunFactMedia ? PLAY_STATUS_YOUTUBE_END_2 : PLAY_STATUS_YOUTUBE_END_1);
	const funFactMediaVolumeEnvelope = 1 - getProgress(PLAY_STATUS_HIDE_FUN_FACT);

	const mediaContainerRef = useRef();
	const mediaContainerSize = useElementSize(mediaContainerRef.current);

	const {
		mediaContainerHeight,
		mediaContainerWidth,
		slideMediaWidth,
		slideMediaHeight,
		funfactMediaWidth,
		funfactMediaHeight,
	} = useMemo(() => {
		if (isDesktop) {
			// on desktop, height limits media size
			const mediaHeight = mediaContainerSize.clientHeight;
			const slideMediaWidth = mediaHeight * (slideMediaIsYoutube ? 16 / 9 : 4 / 3);
			const funfactMediaWidth = mediaHeight * (funfactMediaIsYoutube ? 16 / 9 : 4 / 3);
			const mediaContainerWidth = Math.max(slideMediaWidth, funfactMediaWidth);

			return {
				mediaContainerWidth,
				mediaContainerHeight: mediaHeight,
				slideMediaWidth,
				slideMediaHeight: mediaHeight,
				funfactMediaWidth,
				funfactMediaHeight: mediaHeight,
			};
		} else {
			// on mobile, width limits media size
			const mediaWidth = mediaContainerSize.clientWidth;
			const slideMediaHeight = mediaWidth / (slideMediaIsYoutube ? 16 / 9 : 4 / 3);
			const funfactMediaHeight = mediaWidth / (funfactMediaIsYoutube ? 16 / 9 : 4 / 3);
			const mediaContainerHeight = Math.max(slideMediaHeight, funfactMediaHeight);

			return {
				mediaContainerWidth: mediaWidth,
				mediaContainerHeight,
				slideMediaWidth: mediaWidth,
				slideMediaHeight,
				funfactMediaWidth: mediaWidth,
				funfactMediaHeight,
			};
		}
	}, [
		funfactMediaIsYoutube,
		isDesktop,
		mediaContainerSize.clientHeight,
		mediaContainerSize.clientWidth,
		slideMediaIsYoutube,
	]);

	const { viewBox: pinSvgViewBox, pathD: pinSvgPathD } = useMemo(() => {
		const a = 0.7; // angle at which pin extension begins
		const r = 0.95; // scale to account for stroke width

		const A = Math.sin(a);
		const B = Math.cos(a);

		const [p1, p2, p3] = [
			[-B, A],
			[B, A],
			[0, 1 / A],
		].map((p) => p.map((x) => Math.round(x * 100 * r)));

		const viewBox = `-100 -100 200 ${p3[1] + 100}`;
		const pathD = `M ${p1[0]} ${p1[1]} L ${p2[0]} ${p2[1]} L ${p3[0]} ${p3[1]} L ${p1[0]} ${p1[1]}`;

		return { viewBox, pathD };
	}, []);

	const showClickPrompt = slideIsWaitingForAnswers && !pendingAnswer && !submittedAnswer;
	const showSubmitted = submittedAnswer && !showCorrectAnswer;

	useEffect(() => {
		// auto-submit answer when time's up

		if (
			haveLocalPlayer &&
			statusWithProgress.name === PLAY_STATUS_WAIT_FOR_ANSWER &&
			statusWithProgress.progress === 1 &&
			onAnswer &&
			submittedAnswer === null &&
			pendingAnswer !== null
		) {
			onAnswer(slide.index, pendingAnswer, null, true);
		}
	}, [
		haveLocalPlayer,
		onAnswer,
		pendingAnswer,
		slide.index,
		statusWithProgress.name,
		statusWithProgress.progress,
		submittedAnswer,
	]);

	return (
		<>
			{hasVoice && (
				<TextVoice
					isHost={isHost}
					slideId={slide.id}
					play={readQuestion}
					savedVoice={slide.questionVoice}
					text={slide.question}
					voiceOverride={voiceOverride}
				/>
			)}
			<ScaleContainer ref={ref} noeffect={!isDesktop} className="w-full h-full">
				<div className="md:pt-0 pt-9 relative flex flex-col items-stretch w-full h-full gap-0">
					<div className="flex flex-col md:flex-row md:h-[832px] items-stretch gap-4 md:gap-8">
						<div className="md:items-center md:flex-grow md:px-0 md:gap-8 relative flex flex-col items-stretch justify-start gap-4 px-8">
							{hasQuestion && (
								<SlideHeader
									className={tailwindCascade("h-auto", {
										"opacity-0 transition-opacity duration-300": !showQuestion,
										"pointer-events-none": !showQuestion,
									})}
									innerClassName={tailwindCascade({ "text-sm": !isDesktop })}
									maxHeight={isDesktop ? (hasMedia ? QUESTION_MAX_HEIGHT : null) : 32}
									progress={questionProgress}
								>
									{slide.question}
								</SlideHeader>
							)}
							<ProgressIndicator
								progress={waitForAnswerProgress}
								className="w-full h-8"
								paused={isPaused}
								visible={showProgressBar}
								isQuestion={true}
								doublePoints={doublePoints}
							/>
							<SubmitSection
								className="md:hidden flex"
								onClickSubmit={onClickSubmit}
								showClickPrompt={showClickPrompt}
								showSubmitted={showSubmitted}
							/>
						</div>
						<div className="flex flex-col items-stretch gap-8">
							<div ref={mediaContainerRef} className="relative flex-grow">
								{hasMedia && slideMediaWidth > 0 && slideMediaHeight > 0 && (
									<div style={{ width: slideMediaWidth, height: slideMediaHeight }}>
										<div
											className={tailwindCascade(
												"relative w-full h-full bg-black overflow-hidden",
												"border-4 border-black border-solid",
												{
													"opacity-0 transition-opacity duration-300":
														!showMedia &&
														!(hasFunFactText && !hasFunFactMedia && showFunFact),
													"animate-media": showMedia && !noAnimation,
													"pointer-events-none": !showMedia,
													"sr-only": !showMedia,
												}
											)}
											style={slideMediaContainerStyle}
										>
											<div
												className={tailwindCascade(
													"absolute inset-0 transition-filter duration-1000",
													{
														grayscale: showCorrectAnswer,
													}
												)}
											>
												<SlideMedia
													ref={mediaRef}
													paused={!showMedia || isPaused}
													onLoad={onLoad}
													onError={onError}
													{...slide.media}
													mute={mute}
													setIsPlayingWithSound={setSlideMediaIsPlayingWithSound}
													envelope={slideMediaVolumeEnvelope}
													progress={waitForAnswerProgress}
													visible={showMedia}
													interactiveStreetView={interactiveStreetView}
												/>
											</div>
											<div
												className={tailwindCascade(
													"bg-white-30 absolute inset-0",
													"opacity-0 transition-opacity duration-1000",
													{
														"opacity-100": showCorrectAnswer,
													}
												)}
											/>
											<svg
												ref={svgRef}
												xmlns="http://www.w3.org/2000/svg"
												viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
												width="100%"
												height="100%"
												className={tailwindCascade(
													"absolute inset-0",
													"opacity-0 transition-opacity duration-1000",
													{
														"opacity-100": showCorrectAnswer,
													}
												)}
												onClick={onClickSvg}
												strokeWidth={6}
												strokeLinejoin="round"
												strokeLinecap="round"
											>
												{paths.map((d, i) => (
													<path
														key={i}
														className="fill-green-lighter-30 stroke-black-70 stroke-[6px]"
														d={d}
													/>
												))}
												{paths.map((d, i) => (
													<path
														key={i}
														className="stroke-green-lighter fill-transparent stroke-[3px]"
														d={d}
													/>
												))}
											</svg>
											{pendingAnswer /*&& !submittedAnswer*/ && (
												<Pin
													x={pendingAnswer?.x}
													y={pendingAnswer?.y}
													player={player}
													viewBox={pinSvgViewBox}
													pathD={pinSvgPathD}
													teams={teams}
													teamMode={teamMode}
												/>
											)}
											{(submittedAnswer || showCorrectAnswer) &&
												answerPlayers?.map((player, i) => (
													<Pin
														key={i}
														x={player.selectedAnswer?.x}
														y={player.selectedAnswer?.y}
														player={player}
														viewBox={pinSvgViewBox}
														pathD={pinSvgPathD}
														teams={teams}
														teamMode={teamMode}
													/>
												))}
										</div>
										{hasFunFactText && !hasFunFactMedia && (
											<div
												className={tailwindCascade(
													"absolute",
													"inset-0",
													"z-20",
													"flex",
													"flex-col",
													"justify-center",
													"w-full",
													"h-full",
													"p-8",
													"text-center",
													"bg-black",
													"bg-opacity-50",
													"pointer-events-none",
													{ "opacity-0": !showFunFact }
												)}
											>
												<SlideHeader
													className="flex flex-col justify-center"
													maxHeight={isDesktop ? FUNFACT_MAX_HEIGHT : 32}
													maxFontSize={2.25}
												>
													{slide.funFact}
												</SlideHeader>
											</div>
										)}
									</div>
								)}
								{!(hasSlideMedia && !hasFunFactMedia && hasFunFactText) && (
									<div
										className={tailwindCascade(
											"left-1/2 absolute top-0 -translate-x-1/2 aspect-[4/3]",
											{
												"aspect-[16/9]]": funfactMediaIsYoutube,
												"sr-only": !showFunFact,
											}
										)}
										style={{ height: funfactMediaHeight, width: funfactMediaWidth }}
									>
										<div
											className={tailwindCascade(
												"relative w-full h-full",
												"border-solid border-4 border-black",
												"overflow-hidden",
												"z-1",
												{
													"bg-black": hasFunFactMedia,
													"bg-transparent": !hasFunFactMedia,
												},
												{
													"opacity-0 pointer-events-none transition-opacity duration-300": !(
														showFunFact &&
														(funFactMediaLoaded || !hasFunFactMedia)
													),
													"animate-media":
														showFunFact && (funFactMediaLoaded || !hasFunFactMedia),
												}
											)}
											style={funfactMediaContainerStyle}
										>
											{hasFunFactMedia && (
												<FunFactMedia
													ref={funFactMediaElementRef}
													paused={!showFunFact || isPaused}
													onLoad={onLoadFunFactMedia}
													onError={onLoadFunFactMedia}
													onYoutubeEnd={onYoutubeEnd}
													{...slide.funFactMedia}
													setIsPlayingWithSound={setFunFactMediaIsPlayingWithSound}
													envelope={funFactMediaVolumeEnvelope}
												/>
											)}
											{hasFunFactText && (
												<div
													className={tailwindCascade(
														"absolute",
														"inset-0",
														"z-20",
														"flex",
														"flex-col",
														"justify-center",
														"w-full",
														"h-full",
														"p-8",
														"text-center",
														"bg-black",
														"bg-opacity-50",
														"pointer-events-none",
														{ "opacity-0": !showFunFact }
													)}
												>
													<SlideHeader
														className="flex flex-col justify-center"
														maxHeight={isDesktop ? FUNFACT_MAX_HEIGHT : 48}
														maxFontSize={2.25}
													>
														{slide.funFact}
													</SlideHeader>
												</div>
											)}
										</div>
									</div>
								)}
							</div>
							<SubmitSection
								className="md:flex hidden"
								onClickSubmit={onClickSubmit}
								showClickPrompt={showClickPrompt}
								showSubmitted={showSubmitted}
							/>
						</div>
					</div>
					{isDesktop && (
						<div
							className={tailwindCascade("w-full h-[248px]", {
								"opacity-0 transition-opacity duration-300":
									!showQuestion && !showMedia && !showProgressBar,
							})}
						>
							<SlidePlayers
								status={statusWithProgress.name}
								slideType={slide.type}
								slideIndex={slide.index}
								players={players}
								allowLocalJoin={isHost}
								haveLocalPlayer={haveLocalPlayer}
								onLocalJoin={onLocalJoin}
								teams={teams}
								teamMode={teamMode}
							/>
						</div>
					)}
				</div>
			</ScaleContainer>
		</>
	);
}

function Pin({ player, x, y, viewBox, pathD, teams, teamMode }) {
	const avatar = useMemo(
		() => (teamMode && teams.get(player.teamId)?.avatar) || player.avatar,
		[player, teamMode, teams]
	);

	return (
		<div
			className="md:w-16 absolute w-12 -translate-x-1/2 -translate-y-full pointer-events-none"
			style={{
				left: `${(x * 100) / SVG_WIDTH}%`,
				top: `${(y * 100) / SVG_HEIGHT}%`,
			}}
		>
			<div className="relative">
				<div className="aspect-square absolute w-full">
					<Avatar
						className="w-full h-full"
						playerAvatar={avatar}
						player={player}
						showName={false}
						width={128}
						height={128}
						hostLabel={false}
						hostBorder={false}
					/>
				</div>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox={viewBox}
					className="fill-white stroke-black md:stroke-[10px] stroke-[5px] w-full"
					overflow="visible"
				>
					<path d={pathD} strokeLinejoin="round" strokeLinecap="round" />
				</svg>
			</div>
		</div>
	);
}

function SubmitSection({ className, onClickSubmit, showClickPrompt, showSubmitted, ...props }) {
	return (
		<div className={tailwindCascade("flex flex-row self-center md:h-[60px] h-11", className)}>
			{onClickSubmit && (
				<Button
					className="md:text-2xl md:py-3 py-1 text-base text-white"
					color="green-light"
					border={true}
					onClick={onClickSubmit}
				>
					{trans("Submit")}
				</Button>
			)}
			{(showClickPrompt || showSubmitted) && (
				<div
					className={tailwindCascade(
						"bg-pink flex items-center justify-center w-auto font-black text-white",
						"h-full md:px-6 px-3",
						{ "bg-black-50": showSubmitted }
					)}
					style={{ borderRadius: BUTTON_BORDER_RADIUS }}
				>
					<span className="md:text-lg text-sm">
						{showClickPrompt
							? trans("Click on the image to set a location")
							: showSubmitted
							? trans("Answer submitted")
							: ""}
					</span>
				</div>
			)}
		</div>
	);
}
