import React, {
	forwardRef,
	memo,
	use,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from "react";

import { deepClone } from "fast-json-patch/module/core";
import { clone, set } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isFinite from "lodash/isFinite";
import Link from "next/link";
import QRCode from "qrcode.react";
import { sprintf } from "sprintf-js";
import { useImmer } from "use-immer";

import { apiTranslateQuiz } from "@/api/generator";
import { apiPatchAudioClip } from "@/api/quiz";

import BarberPole from "@/components/BarberPole";
import ConfirmDialog from "@/components/ConfirmDialog";
import Header from "@/components/Header";
import MediaView from "@/components/MediaView";
import Modal from "@/components/Modal";
import Spinner from "@/components/Spinner";
import SyncedAnimatedDots from "@/components/SyncedAnimatedDots";
import Button from "@/components/interactives/Button";
import CheckboxSettings from "@/components/interactives/CheckboxSettings";
import FlatSelector from "@/components/interactives/FlatSelector";
import ClassicPreview from "@/components/library/ClassicPreview";
import LobbyPlayers from "@/components/pages/play/LobbyPlayers";
import Settings from "@/components/pages/play/Settings";

import { languages } from "@/data/languages";

import { voicePlayer } from "@/helpers/audio";
import { sfx } from "@/helpers/audio";
import isApp from "@/helpers/isApp";
import isTouchDevice from "@/helpers/isTouchDevice";
import isUUID from "@/helpers/isUUID";
import onWindowResize from "@/helpers/onWindowResize";
import { getConnectedPlayers } from "@/helpers/player";
import { isQuizValid } from "@/helpers/quiz";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import { upperFirst } from "@/helpers/text";
import trans from "@/helpers/trans";

import useBoostGuard from "@/hooks/useBoostGuard";
import useRefMounted from "@/hooks/useRefMounted";

import AvatarIcon from "@/images/icons/icon-avatar-link.svg";
import GlobeIcon from "@/images/icons/icon-globe.svg";
import HideIcon from "@/images/icons/icon-hide-link.svg";
import LockedIcon from "@/images/icons/icon-locked-link.svg";
import ImagePlaceHolder from "@/images/icons/icon-photos.svg";
import QRIcon from "@/images/icons/icon-qr-link.svg";
import SearchIcon from "@/images/icons/icon-search.svg";
import ShareIcon from "@/images/icons/icon-share-link.svg";
import ShowIcon from "@/images/icons/icon-show-link.svg";
import UnlockedIcon from "@/images/icons/icon-unlocked-link.svg";

import useAuthStore from "@/stores/auth";
import usePlayStore from "@/stores/play";
import usePlaySessionStore from "@/stores/playSession";
import useSettingsStore from "@/stores/settings";

import {
	SLIDE_TYPE_LOCATION,
	SLIDE_TYPE_PINPOINT,
	SLIDE_TYPE_RANGE,
	SLIDE_TYPE_REORDER,
	VOICES,
	languageCodeToVoiceName,
} from "@/app-constants.mjs";
import { PETROL_DARKER as COLOR_PETROL_DARKER, WHITE as COLOR_WHITE, WHITE } from "@/colors";
import { GREEN, GREEN_DARK, GREEN_LIGHT, PETROL_DARKER } from "@/colors";
import {
	API_WEB_SOCKET_GATEWAY_URL,
	BOOST_STATUS_FREE,
	BOOST_STATUS_SUBSCRIBED,
	BOOST_TYPE_TRANSLATE_QUIZ,
	CDN_BASE_URL,
	JOIN_URL,
	MAX_NR_OF_PLAYERS,
	PLAYER_NAME_TTS_TRANSFORM,
} from "@/constants";

import LobbyTeams from "./LobbyTeams";

const API_WEB_SOCKET_PING_TIMEOUT = 10000;

const QRCODE_STYLE = { width: "100%", height: "100%" };
const HIDDEN_PIN = "BAD PIN";
const HIDDEN_ROOM_ID = HIDDEN_PIN.split(" ").join("");
const QUIZ_URL_PATTERN = "/edit/%s/";

function PreviewQuiz({ quiz, onCancel, user }) {
	const [showAnswers, setShowAnswers] = useState(false);
	const showAnswersCheckboxRef = useRef(null);
	const onChangeShowAnswers = useCallback((event) => void setShowAnswers(showAnswersCheckboxRef.current.checked), []);
	return (
		<Modal onCancel={onCancel}>
			<div className="container relative h-full px-4 py-2 mx-auto">
				<div className="bg-petrol-darker relative z-0 flex flex-col w-full h-full p-4 overflow-hidden rounded-lg">
					<div className="absolute top-0 left-0 w-full h-full">
						<div className="relative flex flex-col w-full h-full py-4 space-y-4">
							{user && quiz && (user.id === quiz.owner?.id || user.role === "admin") && (
								<div className="whitespace-nowrap flex flex-row flex-shrink-0 w-full px-4 text-base font-bold text-white">
									<label className="flex flex-row items-center space-x-2">
										<CheckboxSettings
											ref={showAnswersCheckboxRef}
											checked={showAnswers}
											onChange={onChangeShowAnswers}
										/>
										<div>{trans("Show answers")}</div>
									</label>
									<div className="flex flex-col pr-2 ml-auto">
										<Link legacyBehavior href={sprintf(QUIZ_URL_PATTERN, quiz.id)} prefetch={false}>
											<a className="underline">{trans("Go to editor")}</a>
										</Link>
									</div>
								</div>
							)}
							<div className="scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white-50 w-full overflow-x-hidden overflow-y-auto">
								<div className="relative w-full px-2 py-4">
									<div className="flex flex-row flex-wrap items-stretch w-full">
										{quiz &&
											quiz.slides.map((slide, index) => (
												<div
													className="xl:w-1/4 lg:w-1/3 md:w-1/2 w-full px-2 mb-4"
													key={slide.id}
												>
													<ClassicPreview
														theme="dark"
														showAnswers={true}
														blurQuestion={false}
														blurAnswers={!showAnswers}
														index={index}
														slide={{
															...slide,
															answers:
																slide.type === SLIDE_TYPE_REORDER
																	? slide.answers.map((answer, i, answers) =>
																			answers.find((ans) => ans.pos === i)
																	  )
																	: slide.answers,
														}}
													/>
												</div>
											))}
									</div>
								</div>
							</div>
							<div className="md:hidden flex flex-col justify-center w-full h-12">
								<button className="text-base font-black text-white underline" onClick={onCancel}>
									{trans("Close preview")}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

const translateLanguages = (() => {
	const voicesLanguages = VOICES.map((voice) => [
		voice.lang.split("-")[0],
		languages[voice.lang.split("-")[0]].name,
	]).sort((a, b) => a[1].localeCompare(b[1]));

	//Remove duplicate languages and original language
	const uniqueVoicesLanguages = new Map();
	voicesLanguages.forEach(([voicesLanguage, voicesLanguageName]) => {
		uniqueVoicesLanguages.set(voicesLanguage, voicesLanguageName);
	});

	return [...uniqueVoicesLanguages.entries()];
})();

function TranslateOptions({ languages, originalLanguage, keyPrefix = "" }) {
	if (!languages) {
		return null;
	}

	return languages.map(([langCode, langName]) => (
		<option
			key={keyPrefix ? `${keyPrefix}${langCode}` : langCode}
			value={langCode}
			disabled={originalLanguage && langCode === originalLanguage[0]}
		>
			{langName}
		</option>
	));
}

function Translate({ quiz, onTranslate, onReset, disabled }) {
	const [language, setLanguage] = useState(quiz?.language ?? null);

	const onChange = useCallback((value) => void setLanguage(value), []);

	const originalLanguage = useMemo(() => {
		if (quiz?.language && languages[quiz.language]?.name) {
			return [quiz.language, languages[quiz.language].name];
		}
		return ["", trans("Unknown")];
	}, [quiz?.language]);
	const originalLanguages = useMemo(() => [originalLanguage], [originalLanguage]);

	useEffect(() => {
		if (originalLanguage && originalLanguage[0]) {
			setLanguage(originalLanguage[0]);
		}
	}, [originalLanguage]);

	const onTranslateRef = useRef(onTranslate);
	useEffect(() => void (onTranslateRef.current = onTranslate), [onTranslate]);

	const onResetRef = useRef(onReset);
	useEffect(() => void (onResetRef.current = onReset), [onReset]);

	useEffect(() => {
		if (language && quiz?.language) {
			if (language !== quiz.language) {
				if (onTranslateRef.current) {
					onTranslateRef.current(language);
				}
			} else {
				if (onResetRef.current) {
					onResetRef.current();
				}
			}
		}
	}, [language, quiz?.language]);

	return (
		<div
			className={tailwindCascade("group flex flex-row items-center gap-1", {
				"opacity-50 pointer-events-none": disabled,
			})}
		>
			<GlobeIcon className="fill-white group-hover:opacity-100 w-3 h-3 opacity-50" />
			<FlatSelector
				className="group-hover:text-opacity-100 text-sm text-opacity-50"
				value={language}
				onChange={onChange}
				disabled={disabled}
			>
				<optgroup className="font-bold" label={trans("Original language")}>
					<TranslateOptions languages={originalLanguages} keyPrefix={"original"} />
				</optgroup>
				<optgroup className="font-bold text-white" label={trans("Translate to")}>
					<TranslateOptions
						languages={translateLanguages}
						originalLanguage={originalLanguage}
						keyPrefix={"translate"}
					/>
				</optgroup>
			</FlatSelector>
		</div>
	);
}

const ComingUp = forwardRef(function ComingUp(
	{
		quickMode,
		quiz,
		className,
		query,
		language,
		isHost,
		aiMode,
		aiGenerating,
		user,
		playHoverFeedback,
		onTranslate,
		onReset,
	},
	ref
) {
	const [previewQuiz, setPreviewQuiz] = useState(false);

	const onClickPreview = useCallback(() => setPreviewQuiz(true), []);
	const onCancelPreview = useCallback(() => setPreviewQuiz(false), []);

	const quizInfo = useMemo(() => {
		const quizInfo = {
			title: "",
			subtitle: "",
		};

		if (aiMode) {
			if (quiz && quiz.name) {
				quizInfo.title = quiz.name;
			} else if (query) {
				quizInfo.title = query ? upperFirst(query, language) : "";
			}

			if (quiz && quiz.slides && quiz.slides.length) {
				if (quiz.slides.length === 1) {
					quizInfo.subtitle = trans("1 slide");
				} else {
					quizInfo.subtitle = trans("%d slides", quiz.slides.length);
				}
			} else {
				quizInfo.subtitle = trans(`Generating quiz...`);
			}
		} else if (quickMode) {
			quizInfo.title = trans("Vote mode");
			quizInfo.subtitle = trans("Play 3 rounds with 4 questions each. Vote for which questions to play.");
		} else {
			if (quiz) {
				if (quiz.name) {
					quizInfo.title = quiz.name;
				}
				if (quiz.slides && quiz.slides.length) {
					if (quiz.owner?.name) {
						if (quiz.slides.length === 1) {
							quizInfo.subtitle = trans("1 slide");
						} else {
							quizInfo.subtitle = trans("%d slides", quiz.slides.length);
						}
					} else {
						if (quiz.slides.length === 1) {
							quizInfo.subtitle = trans("1 slide");
						} else {
							quizInfo.subtitle = trans("%d slides", quiz.slides.length);
						}
					}
				}
			}
		}

		return quizInfo;
	}, [aiMode, language, query, quickMode, quiz]);

	const isReady = useMemo(() => {
		if (isHost && aiMode) {
			return !aiGenerating && isUUID(quiz?.id);
		}
		return isHost && isUUID(quiz?.id);
	}, [aiGenerating, aiMode, isHost, quiz?.id]);

	return (
		<div
			ref={ref}
			className={tailwindCascade("bg-petrol-darker", "rounded-2xl", "w-full", "overflow-hidden", className)}
		>
			<div className="flex flex-col items-start justify-start flex-1 h-full gap-4 px-8 py-8 bg-black bg-opacity-25">
				{!quickMode && (
					<div className="w-32 aspect-[4/3] bg-opacity-30 rounded-2xl bg-black flex justify-center items-center">
						{quiz?.media?.source ? (
							<MediaView
								className="w-full h-full"
								media={quiz.media}
								alt={quiz.name}
								width={176}
								height={132}
							/>
						) : aiMode && !(quiz && quiz.slides && quiz.slides.length) ? (
							<Spinner className="border-6 w-12 h-12" backgroundColor={WHITE} color={WHITE} />
						) : (
							<ImagePlaceHolder className="w-14 relative h-full m-auto text-white opacity-50" />
						)}
					</div>
				)}
				<div className="flex flex-col items-start justify-center w-full">
					<h3 className="pb-2 font-sans text-xl font-bold leading-none text-white">{quizInfo.title}</h3>
					<div className="flex flex-row items-center justify-start w-full gap-4">
						<p className="font-sans text-sm leading-tight text-white opacity-50">{quizInfo.subtitle}</p>
						{isReady && (
							<button className="group flex flex-row items-center" onClick={onClickPreview}>
								<SearchIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
								<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-bold leading-none text-center text-white text-opacity-50">
									{trans("Preview")}
								</div>
							</button>
						)}
						{!quickMode && user && (
							<Translate quiz={quiz} disabled={!isReady} onTranslate={onTranslate} onReset={onReset} />
						)}
					</div>
				</div>
			</div>
			{previewQuiz && <PreviewQuiz quiz={quiz} onCancel={onCancelPreview} user={user} />}
		</div>
	);
});

const Lobby = forwardRef(function Lobby(
	{
		quiz,
		roomId,
		onStart,
		quickMode,
		isHost,
		players,
		onLocalJoin = null,
		mute = false,
		showCode = true,
		joinOpen = true,
		mutePlayerNames = false,
		reducedMotion = false,
		aiQuery,
		aiMode,
		aiError,
		aiGenerating,
		aiGeneratingInfo,
		aiProcessTime,
		aiLanguage,
		isSpectating = false,
		progress,
		disableYoutubeSetting = false,
		teamMode = false,
		onTeamEdit,
		teams = [],
		setQuiz,
	},
	ref
) {
	const [mounted, mountedRef] = useRefMounted();

	const boostGuard = useBoostGuard(BOOST_TYPE_TRANSLATE_QUIZ);

	const quizRef = useRef(quiz);
	useEffect(() => void (quizRef.current = quiz), [quiz]);

	const setQuizRef = useRef(setQuiz);
	useEffect(() => void (setQuizRef.current = setQuiz), [setQuiz]);

	const [untranslatedQuiz, setUntranslatedQuiz] = useState(null);
	useEffect(() => {
		if (quiz && !quiz.translated) {
			setUntranslatedQuiz(cloneDeep(quiz));
		}
	}, [quiz]);
	const untranslatedQuizRef = useRef(untranslatedQuiz);
	useEffect(() => void (untranslatedQuizRef.current = untranslatedQuiz), [untranslatedQuiz]);

	const updatePlayer = usePlayStore((state) => state.updatePlayer);

	const newPlaySession = usePlaySessionStore((state) => state.newPlaySession);
	const setNewPlaySession = usePlaySessionStore((state) => state.setNewPlaySession);

	const aiProcessTimeRef = useRef(aiProcessTime);
	useEffect(() => void (aiProcessTimeRef.current = aiProcessTime), [aiProcessTime]);

	const progressRef = useRef(progress);
	useEffect(() => void (progressRef.current = progress), [progress]);

	const onLocalJoinRef = useRef(onLocalJoin);
	useEffect(() => void (onLocalJoinRef.current = onLocalJoin), [onLocalJoin]);
	const onLocalJoinInternal = useCallback(() => {
		if (onLocalJoinRef.current) {
			onLocalJoinRef.current();
		}
	}, []);

	const mutePlayerNamesRef = useRef(mutePlayerNames);
	useEffect(() => void (mutePlayerNamesRef.current = mutePlayerNames), [mutePlayerNames]);

	const [smoothProgress, setSmoothProgress] = useState(progress);
	const smoothProgressRef = useRef(smoothProgress);
	useEffect(() => void (smoothProgressRef.current = smoothProgress), [smoothProgress]);

	const user = useAuthStore((state) => state.user);

	useEffect(() => {
		if (aiMode && aiGenerating) {
			let mounted = true;
			let timeout = null;

			const questionsAverageProcessTime = aiProcessTimeRef?.current?.questions || 120000;
			const mediaAverageProcessTime = aiProcessTimeRef?.current?.media || 7000;

			const questionsStartTimeProgressLength =
				questionsAverageProcessTime / (questionsAverageProcessTime + mediaAverageProcessTime);
			const mediaStartTimeProgressLength =
				mediaAverageProcessTime / (questionsAverageProcessTime + mediaAverageProcessTime);

			let questionsStartTime = null;
			let mediaStartTime = null;

			const update = () => {
				timeout = null;
				if (mounted) {
					let progress = isFinite(progressRef.current) ? progressRef.current : 0;
					if (progress <= questionsStartTimeProgressLength) {
						if (!questionsStartTime) {
							questionsStartTime = Date.now();
						}
						let openAIProgress = Math.min(
							Math.max((Date.now() - questionsStartTime) / questionsAverageProcessTime, 0),
							1
						);
						openAIProgress = Math.round(openAIProgress * questionsStartTimeProgressLength * 0.99 * 100);
						if (openAIProgress > progress) {
							progress = openAIProgress;
						}
					} else if (
						progress > questionsStartTimeProgressLength &&
						progress <= questionsStartTimeProgressLength + mediaStartTimeProgressLength
					) {
						if (!mediaStartTime) {
							mediaStartTime = Date.now();
						}
						let dezgoProgress = Math.min(
							Math.max((Date.now() - mediaStartTime) / mediaAverageProcessTime, 0),
							1
						);
						dezgoProgress = Math.round(
							(questionsStartTimeProgressLength + dezgoProgress * mediaStartTimeProgressLength * 0.99) *
								100
						);
						if (dezgoProgress > progress) {
							progress = dezgoProgress;
						}
					}
					let smoothProgress = isFinite(smoothProgressRef.current) ? smoothProgressRef.current : 0;
					smoothProgress = smoothProgress + Math.ceil((progress - smoothProgress) / 4);
					if (progress >= 100) {
						progress = smoothProgress = 100;
					}

					smoothProgress = Math.max(Math.min(smoothProgress, 100), 0);
					smoothProgress = Math.round(smoothProgress);

					if (!isFinite(smoothProgressRef.current) || smoothProgressRef.current < smoothProgress) {
						setSmoothProgress(smoothProgress);
					}

					if (smoothProgress < 100) {
						timeout = setTimeout(update, 500);
					}
				}
			};

			timeout = setTimeout(update, 100);

			return () => {
				mounted = false;
				if (timeout) {
					clearTimeout(timeout);
				}
			};
		}
	}, [aiGenerating, aiMode]);

	useEffect(() => {
		if (newPlaySession) {
			setNewPlaySession(false);
		}
	}, [newPlaySession, setNewPlaySession]);

	const haveLocalPlayer = usePlayStore((state) => state.haveLocalPlayer);

	const numberOfPlayers = useMemo(() => getConnectedPlayers([...players.values()], false).length, [players]);

	const playHoverFeedback = useCallback(() => void sfx.play("hoverFeedback", false, 0.75), []);

	const quizValid = useMemo(() => isQuizValid(quiz) || quickMode || aiMode, [quickMode, quiz, aiMode]);

	const nrOfSlides = useSettingsStore((state) => state.numRoundsPerGame);

	const [categories] = useImmer([]);

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const onStartInternalLocalJoin = useCallback(() => {
		setShowConfirmDialog(false);
		if (onLocalJoinRef.current) {
			onLocalJoinRef.current(true);
		}
	}, []);

	const onStartInternalForce = useCallback(() => {
		onStart(
			nrOfSlides,
			categories.filter((category) => category.marked).map((category) => category.id)
		);
	}, [categories, nrOfSlides, onStart]);

	const onStartInternal = useCallback(() => {
		if (quizValid) {
			if (numberOfPlayers > 0) {
				onStartInternalForce();
				return;
			}
		}
		onStartInternalLocalJoin();
	}, [quizValid, numberOfPlayers, onStartInternalForce, onStartInternalLocalJoin]);

	const onJoinOpen = useCallback(
		() => void useSettingsStore.getState().set((draft) => void (draft.joinOpen = true)),
		[]
	);

	const onCancelConfirmDialog = useCallback(() => void setShowConfirmDialog(false), []);

	useImperativeHandle(
		ref,
		() => ({
			startIfOnlyLocalPlayer() {
				if (numberOfPlayers === 1 && haveLocalPlayer) {
					onStartInternalForce();
				}
			},
		}),
		[haveLocalPlayer, numberOfPlayers, onStartInternalForce]
	);

	const [playedVoiceClips, setPlayedVoiceClips] = useState({});

	useEffect(() => {
		for (const [, player] of players) {
			if (player && player.voiceClip && player.voiceClip.startsWith("voice-cache/")) {
				updatePlayer(player.connectionId, (player) => {
					player.voiceClip = null;
					player.voiceClipName = null;
				});

				//Only read name if it has changed
				if (player.connectionId in playedVoiceClips) {
					if (playedVoiceClips[player.connectionId] === player.voiceClipName) {
						continue;
					}
				}

				const newVoiceClips = cloneDeep(playedVoiceClips);
				newVoiceClips[player.connectionId] = player.voiceClipName;
				setPlayedVoiceClips(newVoiceClips);

				voicePlayer.load(`${CDN_BASE_URL}/${player.voiceClip}`, (error, sound) => {
					if (!error && mountedRef.current && !mutePlayerNamesRef.current) {
						voicePlayer.play(sound);
					}
				});
			}
		}
	}, [mountedRef, playedVoiceClips, players, updatePlayer]);

	const [aiQuizReadySound, setAIQuizReadySound] = useState(null);
	useEffect(() => {
		if (isHost && aiMode) {
			let mounted = true;

			setAIQuizReadySound(null);

			let loadedSound = null;
			const text = "A.I. generated quiz is ready, let's play!";
			apiPatchAudioClip("Samantha", PLAYER_NAME_TTS_TRANSFORM(text))
				.then((result) => {
					if (mounted) {
						const filename = result?.filename;
						if (filename) {
							voicePlayer.load(`${CDN_BASE_URL}/${filename}`, (error, sound) => {
								if (!error) {
									loadedSound = sound;
									if (mounted) {
										setAIQuizReadySound(sound);
									}
								}
							});
						}
					}
				})
				.catch(console.error);

			return () => {
				mounted = false;
				if (loadedSound) {
					voicePlayer.stop(loadedSound);
					voicePlayer.unload(loadedSound);
				}
			};
		}
	}, [aiMode, isHost]);

	const [aiQuizReadySoundPlay, setAiQuizReadySoundPlay] = useState(false);
	useEffect(() => {
		if (isHost) {
			setAiQuizReadySoundPlay(
				aiMode && !aiError && !aiGenerating && quiz?.id && isUUID(quiz?.id) && progress === 100
			);
		}
	}, [isHost, aiMode, aiError, aiGenerating, quiz, progress]);

	useEffect(() => {
		if (aiQuizReadySoundPlay && aiQuizReadySound) {
			voicePlayer.play(aiQuizReadySound);

			return () => {
				voicePlayer.stop(aiQuizReadySound);
			};
		}
	}, [aiQuizReadySoundPlay, aiQuizReadySound]);

	const [showQRCodeInModal, setShowQRCodeInModal] = useState(false);
	const onShowQRCodeInModal = useCallback(() => setShowQRCodeInModal(true), []);
	const onHideQRCodeInModal = useCallback(() => setShowQRCodeInModal(false), []);

	const [jobId, setJobId] = useState(null);
	const translateLanguageCode = useRef();

	useEffect(() => {
		if (jobId) {
			let socket = null;
			let pingInterval = null;
			let reconnectTimeout = null;
			let progressInterval = null;

			const dispose = () => {
				if (pingInterval) {
					clearInterval(pingInterval);
					pingInterval = null;
				}

				if (reconnectTimeout) {
					clearTimeout(reconnectTimeout);
					reconnectTimeout = null;
				}

				if (progressInterval) {
					clearInterval(progressInterval);
					progressInterval = null;
				}

				if (socket) {
					socket.onopen = socket.onmessage = socket.onerror = socket.onclose = null;
					try {
						socket.close();
					} catch (error) {
						console.error(error);
					}
					socket = null;
				}
			};

			const connect = () => {
				dispose();

				socket = new WebSocket(API_WEB_SOCKET_GATEWAY_URL);
				socket.onopen = () => {
					socket.send(
						JSON.stringify({
							event: "subscribe",
							data: { jobId },
						})
					);

					socket.onmessage = (event) => {
						try {
							const message = JSON.parse(event.data);
							if (message.action === "status") {
								if (message.failed) {
									dispose();

									setTranslateInProgress(false);
									setJobId(null);
								} else if (message.finished) {
									dispose();

									if (message.slides) {
										const translatedQuiz = deepClone(untranslatedQuizRef.current);
										translatedQuiz.translated = true;

										const voiceName = languageCodeToVoiceName(translateLanguageCode.current);

										for (let i = 0; i < translatedQuiz.slides.length; i++) {
											translatedQuiz.slides[i].question = message.slides[i].question;
											if (message.slides[i].answers) {
												translatedQuiz.slides[i].answers = message.slides[i].answers;
											}
											translatedQuiz.slides[i].questionVoice = voiceName;
											translatedQuiz.slides[i].answerVoice = voiceName;
											translatedQuiz.slides[i].funFact = message.slides[i].funFact;
										}

										if (message.quizInfo) {
											translatedQuiz.name = message.quizInfo.name;
											translatedQuiz.description = message.quizInfo.descriptiom;
										}

										if (setQuizRef.current) {
											setQuizRef.current(translatedQuiz);
										}
									}

									setTranslateInProgress(false);
									setJobId(null);
								} else {
									//
								}
							}
						} catch (error) {
							console.error(error);
						}
					};

					pingInterval = setInterval(() => {
						try {
							socket.send(
								JSON.stringify({
									event: "ping",
								})
							);
						} catch (error) {
							console.error(error);
						}
					}, API_WEB_SOCKET_PING_TIMEOUT);

					socket.onclose = socket.onerror = (event) => {
						dispose();
						reconnectTimeout = setTimeout(() => {
							reconnectTimeout = null;
							connect();
						}, 2000);
					};
				};
			};

			connect(); // Initial connect

			return () => dispose();
		}
	}, [jobId]);

	const [translateInProgress, setTranslateInProgress] = useState(false);

	const translateQuiz = useCallback(
		(quiz, langCode) => {
			if (quiz?.slides) {
				const translateQuiz = () => {
					setTranslateInProgress(true);

					translateLanguageCode.current = langCode;

					let slides = quiz.slides;

					const slideTranslate = [];

					slides.forEach((slide) => {
						const { question, answers, funFact } = slide;
						let transSlide;

						if (
							slide.type === SLIDE_TYPE_LOCATION ||
							slide.type === SLIDE_TYPE_RANGE ||
							slide.type === SLIDE_TYPE_PINPOINT
						) {
							transSlide = { question, funFact };
						} else {
							transSlide = { question, answers, funFact };
						}

						slideTranslate.push(transSlide);
					});

					const quizInfo = { id: quiz.id, name: quiz.name, description: quiz.description };

					const translateData = { language: langCode, slides: slideTranslate, quizInfo, saveClone: true };

					apiTranslateQuiz(translateData)
						.then((job) => {
							if (mountedRef.current) {
								const jobId = job?.jobId ?? null;

								setJobId(jobId);

								if (!jobId) {
									console.log("No job id");
									setTranslateInProgress(false);
								}
							}
						})
						.catch((error) => {
							console.log(error);
							if (mountedRef.current) {
								setTranslateInProgress(false);
							}
						});
				};

				boostGuard((status) => {
					if (status === BOOST_STATUS_FREE || status === BOOST_STATUS_SUBSCRIBED) {
						translateQuiz();
					} else {
						setQuiz(quiz); // Force update
					}
				});
			}
		},
		[boostGuard, mountedRef, setQuiz]
	);
	const translateQuizRef = useRef(translateQuiz);
	useEffect(() => void (translateQuizRef.current = translateQuiz), [translateQuiz]);

	const onTranslate = useCallback((language) => {
		if (untranslatedQuizRef.current && translateQuizRef.current) {
			translateQuizRef.current(untranslatedQuizRef.current, language);
		}
	}, []);

	const onReset = useCallback(() => {
		if (quizRef.current && quizRef.current.translated) {
			const untranslatedQuiz = deepClone(untranslatedQuizRef.current);
			setQuiz(untranslatedQuiz);
		}
	}, [setQuiz]);

	const [heightMatchElements, setHeightMatchElements] = useState(new Set());

	const updateHeightMatchElements = useCallback(() => {
		let maxHeight = "auto";
		for (const element of heightMatchElements) {
			if (element) {
				element.style.height = "auto";
				const boundingClientRect = element.getBoundingClientRect();
				if (maxHeight === "auto" || boundingClientRect.height > maxHeight) {
					maxHeight = boundingClientRect.height;
				}
			}
		}

		if (typeof window !== "undefined" && window.visualViewport) {
			const { width } = window.visualViewport;
			if (width >= 1024) {
				for (const element of heightMatchElements) {
					if (element) {
						element.style.height = `${maxHeight}px`;
					}
				}
			}
		}
	}, [heightMatchElements]);
	const updateHeightMatchElementsRef = useRef(updateHeightMatchElements);
	useEffect(
		() => void (updateHeightMatchElementsRef.current = updateHeightMatchElements),
		[updateHeightMatchElements]
	);

	const resizeObserverRef = useRef(
		new ResizeObserver(() => {
			if (updateHeightMatchElementsRef.current) {
				updateHeightMatchElementsRef.current();
			}
		})
	);

	useEffect(() => {
		const resizeObserver = resizeObserverRef.current;
		if (resizeObserver) {
			for (const element of heightMatchElements) {
				resizeObserver.observe(element);
			}
		}

		if (updateHeightMatchElementsRef.current) {
			updateHeightMatchElementsRef.current();
		}

		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
		};
	}, [heightMatchElements]);

	const addHeightMatchElement = useCallback((element) => {
		if (element) {
			element.style.height = "auto";
			setHeightMatchElements((heightMatchElements) => new Set(heightMatchElements.add(element)));
		}
	}, []);

	return (
		<>
			<div className="md:container flex flex-col lg:flex-row w-full h-full gap-8 pt-12 md:pt-20 mb:pb-8 pb-4 lg:px-8 px-4 lg:mx-auto lg:max-h-[60rem]">
				<div className="bg-petrol-darker rounded-2xl xl:w-4/6 lg:w-7/12 flex flex-col justify-center w-full overflow-hidden">
					<div className="flex flex-col items-center w-full h-full">
						<div ref={addHeightMatchElement} className="md:px-16 w-full px-8 py-8 bg-black bg-opacity-25">
							<div className="lg:flex-row lg:gap-0 lg:items-stretch flex flex-col items-center w-full gap-4">
								<JoinAtContainer className="lg:max-w-none md:w-3/4 lg:w-30 xl:w-40 flex-none w-full max-w-sm" />
								<PinCodeContainer
									isHost={isHost}
									roomId={roomId}
									showCode={showCode}
									joinOpen={joinOpen}
									onClickShowQRCode={onShowQRCodeInModal}
								/>
								<QRCodeContainer
									className="xl:block flex-none hidden w-40"
									roomId={roomId}
									showCode={showCode}
									joinOpen={joinOpen}
									onClickShowQRCode={onShowQRCodeInModal}
								/>
							</div>
						</div>

						<div className="md:gap-8 flex flex-col items-center justify-between flex-1 w-full gap-4">
							<div className="flex flex-col items-center justify-center gap-1 md:gap-2 mt-4 md:mt-8 lg:min-h-[4rem]">
								{(numberOfPlayers > 0 || teamMode) && (
									<>
										<Header className="whitespace-nowrap md:text-2xl text-xl font-bold text-white">
											{trans(
												"%s of %s players:",
												numberOfPlayers.toLocaleString(),
												MAX_NR_OF_PLAYERS.toLocaleString()
											)}
										</Header>
										{!isSpectating && (
											<button
												className="group flex flex-row items-center"
												onClick={onLocalJoinInternal}
											>
												<AvatarIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
												<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-normal leading-none text-center text-white text-opacity-50">
													{isHost && !haveLocalPlayer
														? trans("Join on this device")
														: teamMode
														? trans("Edit your name")
														: trans("Edit character")}
												</div>
											</button>
										)}
									</>
								)}
							</div>

							{teamMode ? (
								<LobbyTeams onEditClick={onTeamEdit} players={players} teams={teams} isHost={isHost} />
							) : (
								<LobbyPlayers
									players={players}
									isHost={isHost}
									reducedMotion={false}
									onLocalJoin={onLocalJoinInternal}
								/>
							)}
							<div className="flex flex-col items-center justify-center mb-8">
								{isHost ? (
									aiQuery ? (
										<BarberPole
											className="md:w-92 w-full"
											colors={[GREEN_LIGHT, GREEN, GREEN_DARK, PETROL_DARKER]}
										>
											<div className="relative flex flex-col items-center justify-center w-full h-full">
												<Header className="text-base">
													{aiGeneratingInfo ? aiGeneratingInfo : trans("Generating quiz")}
													<SyncedAnimatedDots />
												</Header>
												{smoothProgress > 0 && (
													<Header className="right-4 absolute text-base">
														{`${Math.round(smoothProgress)}%`}
													</Header>
												)}
											</div>
										</BarberPole>
									) : (
										<Button
											className={tailwindCascade(
												"whitespace-nowrap",
												"md:px-8",
												"w-full",
												"md:w-80",
												"h-12",
												"px-6",
												"py-0",
												"text-base",
												"font-bold",
												{
													"opacity-50": !quizValid,
												}
											)}
											border={3}
											color="green-lighter"
											onClick={
												quizValid ? onStartInternal : () => void setShowConfirmDialog(true)
											}
											onMouseEnter={playHoverFeedback}
										>
											{trans("Start game")}
										</Button>
									)
								) : aiGenerating ? (
									<BarberPole
										className="md:w-92 w-full"
										colors={[GREEN_LIGHT, GREEN, GREEN_DARK, PETROL_DARKER]}
									>
										<Header className="text-base">
											{aiGeneratingInfo ? aiGeneratingInfo : trans("Generating quiz")}
											<SyncedAnimatedDots />
										</Header>
										{smoothProgress > 0 && (
											<Header className="right-4 absolute text-base">
												{`${Math.round(smoothProgress)}%`}
											</Header>
										)}
									</BarberPole>
								) : (
									<div className="flex flex-col gap-6">
										<Button
											className={tailwindCascade(
												"whitespace-nowrap",
												"md:px-8",
												"h-12",
												"px-6",
												"py-0",
												"text-base",
												"font-bold",
												"text-white",
												"pointer-events-none",
												"cursor-default"
											)}
											border={3}
											color="petrol-darker"
										>
											{trans("Waiting for host to start")}
											<SyncedAnimatedDots />
										</Button>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="xl:w-2/6 lg:w-5/12 flex flex-col w-full">
					<ComingUp
						ref={addHeightMatchElement}
						className="rounded-t-2xl rounded-b-none"
						quickMode={quickMode}
						isHost={isHost}
						quiz={quiz}
						query={aiQuery}
						aiMode={aiMode}
						user={user}
						language={aiLanguage}
						playHoverFeedback={playHoverFeedback}
						onTranslate={onTranslate}
						onReset={onReset}
					/>

					<div
						className={tailwindCascade(
							"bg-petrol-darker rounded-b-2xl rounded-t-none l md:mb-0 flex-1 w-full px-4 py-8",
							{
								"order-3 mb-0": isHost,
								"order-2 mb-4": !isHost,
							}
						)}
					>
						<div className="relative w-full h-full">
							<div className="md:overscroll-y-auto scrollbar-thin md:scrollbar-thumb-white-20 md:scrollbar-track-transparent lg:absolute relative inset-0 overflow-x-hidden">
								<Settings
									className="px-4"
									isHost={isHost}
									reset={newPlaySession ? "soft" : undefined}
									mute={mute}
									quickMode={quickMode}
									disableYoutubeSetting={disableYoutubeSetting}
									playHoverFeedback={playHoverFeedback}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{showConfirmDialog && (
				<ConfirmDialog
					text={
						quizValid
							? trans("No players have joined this quiz.")
							: trans("This quiz has no questions with answers.")
					}
					confirmText={trans("Start anyway")}
					extraText={quizValid || !haveLocalPlayer ? trans("Join on this device") : undefined}
					onConfirm={onStartInternalForce}
					onExtra={onStartInternalLocalJoin}
					onCancel={onCancelConfirmDialog}
				/>
			)}

			{showQRCodeInModal && (
				<QRCodeModal roomId={roomId} showCode={showCode} joinOpen={joinOpen} onCancel={onHideQRCodeInModal} />
			)}

			{translateInProgress && (
				<Modal>
					<div className="text-5xl font-bold text-white">
						{trans("Translating")}
						<SyncedAnimatedDots />
					</div>
				</Modal>
			)}
		</>
	);
});

function JoinAtContainer({ className }) {
	return (
		<div className={tailwindCascade("flex flex-col items-center justify-start flex-1 gap-2", className)}>
			<div className="text-base font-bold text-center text-white">{trans("Join at:")}</div>
			<div className="flex items-center justify-center flex-1 w-full">
				<img
					src="/images/logo/quiz-vertical-multicolor.svg"
					width="440"
					height="265"
					alt={trans("Quiz.com")}
					draggable={false}
					className="lg:block hidden object-contain w-40 h-auto"
				/>
				<img
					src="/images/logo/quiz-multicolor.svg"
					width="769"
					height="166"
					alt={trans("Quiz.com")}
					draggable={false}
					className="lg:hidden block object-contain w-full h-auto"
				/>
			</div>
		</div>
	);
}

function PinCodeContainer({ className, roomId, isHost, showCode, joinOpen, onClickShowQRCode }) {
	const setSettingsStore = useSettingsStore((state) => state.set);

	const roomIdSplitted = useMemo(() => {
		const code = showCode ? roomId : HIDDEN_ROOM_ID;
		if (code) {
			return [code.substring(0, 3), code.substring(3, 6)];
		} else {
			return ["", ""];
		}
	}, [roomId, showCode]);

	const clipboardSupported = useMemo(() => {
		// TODO: Implement https://www.electronjs.org/docs/latest/api/clipboar
		if (isApp) {
			return false;
		}
		return typeof window !== "undefined" && window.navigator && !!window.navigator.clipboard;
	}, []);

	const shareSupported = useMemo(() => {
		if (isApp) {
			return false;
		}
		return typeof window !== "undefined" && window.navigator && window.navigator.share;
	}, []);

	const [linkCopied, setLinkCopied] = useState(false);

	const onClickCopyLink = useCallback(() => {
		if (clipboardSupported) {
			navigator.clipboard
				.writeText(sprintf(JOIN_URL, roomId))
				.then(() => setLinkCopied(true))
				.catch(() => setLinkCopied(false));
		}
	}, [clipboardSupported, roomId]);

	const onClickShareLink = useCallback(async () => {
		try {
			const shareData = {
				url: sprintf(JOIN_URL, roomId),
			};
			await navigator.share(shareData);
		} catch (error) {
			console.error(error);
		}
	}, [roomId]);

	const onClickShowHideCode = useCallback(
		() =>
			setSettingsStore((draft) => {
				const showCode = !draft.showCode;
				draft.showCode = showCode;
				if (showCode) {
					draft.joinOpen = true;
				}
			}),
		[setSettingsStore]
	);

	const onClickLockUnlock = useCallback(
		() => setSettingsStore((draft) => void (draft.joinOpen = !draft.joinOpen)),
		[setSettingsStore]
	);

	return (
		<div className={tailwindCascade("flex flex-col items-center justify-center flex-1 gap-2", className)}>
			<div className="text-base font-bold text-center text-white">
				{isHost ? trans("PIN code:") : trans("You have joined with PIN:")}
			</div>

			<div className="flex flex-row items-center justify-center flex-1 w-full">
				{showCode && joinOpen ? (
					<Header className="xl:text-6xl lg:text-5xl text-green-lighter text-6xl font-black leading-none text-center">
						<div className="flex flex-row items-center justify-center flex-1 gap-4">
							<div>{roomIdSplitted[0]}</div>
							<div>{roomIdSplitted[1]}</div>
						</div>
					</Header>
				) : (
					<div className="flex flex-col items-center justify-center xl:min-h-[3.75rem] min-h-[3.75rem] lg:min-h-[3rem]">
						<div className="text-base font-bold text-white">{trans("The code is hidden")}</div>
						{!joinOpen && (
							<div className="text-base font-bold text-white">
								{trans("Joining is no longer avalible")}
							</div>
						)}
						{isHost && (
							<button className="group flex flex-row items-center" onClick={onClickLockUnlock}>
								{joinOpen ? (
									<LockedIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
								) : (
									<UnlockedIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
								)}
								<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-normal leading-none text-center text-white text-opacity-50">
									{joinOpen ? trans("Prevent more players from joining") : trans("Open up again")}
								</div>
							</button>
						)}
					</div>
				)}
			</div>
			<div className="flex flex-row items-center gap-4">
				{showCode && joinOpen && (
					<>
						{shareSupported && isTouchDevice ? (
							<button className="group md:hidden flex flex-row items-center" onClick={onClickShareLink}>
								<ShareIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
								<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-normal leading-none text-center text-white text-opacity-50">
									{trans("Share")}
								</div>
							</button>
						) : clipboardSupported ? (
							<button className="group flex flex-row items-center" onClick={onClickCopyLink}>
								<ShareIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
								<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-normal leading-none text-center text-white text-opacity-50">
									{linkCopied ? trans("Link copied") : trans("Copy")}
								</div>
							</button>
						) : null}
					</>
				)}

				{isHost && (
					<button className="group flex flex-row items-center" onClick={onClickShowHideCode}>
						{showCode ? (
							<HideIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
						) : (
							<ShowIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
						)}
						<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-normal leading-none text-center text-white text-opacity-50">
							{showCode ? trans("Hide") : trans("Show")}
						</div>
					</button>
				)}

				{showCode && joinOpen && (
					<button className="group xl:hidden flex flex-row items-center" onClick={onClickShowQRCode}>
						<QRIcon className="group-hover:opacity-100 w-6 h-6 text-white opacity-50" />
						<div className="group-hover:underline group-hover:text-opacity-100 text-sm font-normal leading-none text-center text-white text-opacity-50">
							{trans("QR Code")}
						</div>
					</button>
				)}
			</div>
		</div>
	);
}

function QRCodeModal({ roomId, showCode, joinOpen, onCancel }) {
	const roomIdSplitted = useMemo(() => {
		const code = showCode ? roomId : HIDDEN_ROOM_ID;
		if (code) {
			return [code.substring(0, 3), code.substring(3, 6)];
		} else {
			return ["", ""];
		}
	}, [roomId, showCode]);

	const showQRCodeInModalButtonRef = useRef(null);
	const resizeQRCodeInModal = useCallback(() => {
		if (showQRCodeInModalButtonRef.current) {
			const element = showQRCodeInModalButtonRef.current;
			element.style.width = null;

			const { offsetWidth, offsetHeight } = element;
			const aspect = offsetHeight / offsetWidth;
			let { innerWidth, innerHeight } = window;

			if (innerWidth > 1536) {
				innerWidth = 1536;
			}
			innerWidth -= innerWidth >= 768 ? 128 : 16; // Mar
			innerHeight -= innerHeight >= 768 ? 128 : 16; // Mar

			let width = innerWidth;
			let height = width * aspect;
			if (height > innerHeight) {
				height = innerHeight;
				width = height / aspect;
			}
			if (width < offsetWidth || height < offsetHeight) {
				width = offsetWidth;
				height = offsetHeight;
			}
			element.style.width = `${width}px`;
		}
	}, []);
	const resizeQRCodeInModalRef = useRef(resizeQRCodeInModal);
	useEffect(() => void (resizeQRCodeInModalRef.current = resizeQRCodeInModal), [resizeQRCodeInModal]);

	const setShowQRCodeInModalButtonRef = useCallback((element) => {
		showQRCodeInModalButtonRef.current = element;
		if (resizeQRCodeInModalRef.current) {
			resizeQRCodeInModalRef.current();
		}
	}, []);

	useEffect(() => {
		resizeQRCodeInModal(); // Initial resiz
		return onWindowResize(resizeQRCodeInModal);
	}, [resizeQRCodeInModal]);

	if (showCode && joinOpen) {
		return (
			<Modal onCancel={onCancel}>
				<button
					ref={setShowQRCodeInModalButtonRef}
					className="bg-petrol-dark rounded-2xl text-sans flex flex-col items-center justify-center px-4 py-8 m-auto space-y-4 text-white cursor-pointer"
					onClick={onCancel}
				>
					<h3 className="md:pt-8 lg:px-8 w-full px-4 pt-4 text-2xl font-bold text-center text-white">
						{trans("Ask participants to aim their camera here:")}
					</h3>

					<div className="lg:px-8 lg:py-8 w-full px-4 py-4">
						{showCode && joinOpen ? (
							<div className="rounded-2xl w-full p-8 bg-white">
								<QRCode
									value={sprintf(JOIN_URL, showCode ? roomId : HIDDEN_ROOM_ID)}
									renderAs="svg"
									bgColor={COLOR_WHITE}
									fgColor={COLOR_PETROL_DARKER}
									style={QRCODE_STYLE}
								/>
							</div>
						) : (
							<div className="bg-opacity-10 rounded-2xl aspect-square w-full p-8 bg-white" />
						)}
					</div>

					<h2 className="text-xl font-bold text-white">{trans("Quiz.com")}</h2>

					<div className="h-11 xl:h-14">
						{showCode && joinOpen && (
							<Header className="xl:text-5xl text-green-lighter text-4xl font-black leading-none text-center">
								<span className="pr-[0.125em]">{roomIdSplitted[0]}</span>
								<span className="pl-[0.125em]">{roomIdSplitted[1]}</span>
							</Header>
						)}
					</div>
				</button>
			</Modal>
		);
	}
}

function QRCodeContainer({ className, roomId, showCode, joinOpen, onClickShowQRCode }) {
	return (
		<div className={tailwindCascade("flex flex-col items-center justify-center flex-1", className)}>
			{showCode && joinOpen ? (
				<button className="p-4 bg-white rounded-md" onClick={onClickShowQRCode}>
					<QRCode
						value={sprintf(JOIN_URL, showCode ? roomId : HIDDEN_ROOM_ID)}
						renderAs="svg"
						bgColor={COLOR_WHITE}
						fgColor={COLOR_PETROL_DARKER}
						style={QRCODE_STYLE}
					/>
				</button>
			) : (
				<div className="bg-opacity-10 aspect-square w-full p-4 bg-white rounded-md" />
			)}
		</div>
	);
}
const MemorizedLobby = memo(Lobby, isEqual);
export default MemorizedLobby;
