import random from "lodash/random";

import {
	AVATAR_LAYER_FEATURE_BODY,
	AVATAR_LAYER_FEATURE_EYE,
	AVATAR_LAYER_FEATURE_HAIR,
	AVATAR_LAYER_FEATURE_HAT,
	AVATAR_LAYER_FEATURE_MOUTH,
	AVATAR_LAYER_LINE,
	AVATAR_LAYER_NONE,
	PLAYER_AVATAR_BODYS,
	PLAYER_AVATAR_EYES,
	PLAYER_AVATAR_HAIRS,
	PLAYER_AVATAR_HATS,
	PLAYER_AVATAR_MOUTHS,
} from "@/constants";

export function getRandomHatFeature() {
	return { feature: AVATAR_LAYER_FEATURE_HAT, index: random(PLAYER_AVATAR_HATS.length - 1) };
}

export default function getRandomAvatarFeatures() {
	const features = [];

	features.push({ feature: AVATAR_LAYER_FEATURE_BODY, index: random(PLAYER_AVATAR_BODYS.length - 1) });

	const r = random(0, 2);
	if (r === 0) {
		features.push({ feature: AVATAR_LAYER_FEATURE_HAIR, index: random(PLAYER_AVATAR_HAIRS.length - 1) });
	} else if (r === 1) {
		features.push(getRandomHatFeature());
	}

	features.push({ feature: AVATAR_LAYER_FEATURE_EYE, index: random(PLAYER_AVATAR_EYES.length - 1) });
	features.push({ feature: AVATAR_LAYER_FEATURE_MOUTH, index: random(PLAYER_AVATAR_MOUTHS.length - 1) });

	return features;
}
