import trans from "@/helpers/trans";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import Modal from "@/components/Modal";

export default function GamePaused({ className, ...props }) {
	return (
		<Modal className={tailwindCascade("bg-black md:bg-opacity-0 bg-opacity-80", "md:mt-11 mt-9", className)}>
			<div className="md:bg-opacity-70 md:px-8 md:py-4 p-0 bg-black bg-opacity-0 rounded-md">
				<div className="whitespace-nowrap text-xl font-black text-white select-none">
					{trans("Game paused")}
				</div>
			</div>
		</Modal>
	);
}
