import React, { useCallback, useMemo } from "react";

import isFinite from "lodash/isFinite";
import { createPortal } from "react-dom";

import Avatar from "@/components/Avatar";

import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import CheckIcon from "@/images/icons/check-bold.svg";
import CorrectIcon from "@/images/icons/icon-correct.svg";
import WrongIcon from "@/images/icons/icon-wrong.svg";
import PrintCorrectIcon from "@/images/icons/print-correct.svg";
import PrintWrongIcon from "@/images/icons/print-wrong.svg";

const TIME_PRECISION = 2;
const TABLE_STYLE = { backgroundColor: "var(--background-color-dark)" };

export function FinalReport({ className, quiz, report, ...props }) {
	const slideNumbers = useMemo(
		() => Array.from({ length: quiz?.slides?.length ?? 0 }, (_value, i) => i),
		[quiz?.slides?.length]
	);

	const onClickDownload = useCallback(async () => {
		const rows = [["Player name", "Score", "Accuracy", ...slideNumbers.map((i) => `Q${i + 1}`)]];

		rows.push(
			...report.map((playerEntry) => [
				playerEntry.name,
				playerEntry.score ?? 0,
				`${Math.round((playerEntry.accuracy ?? 0) * 100)}%`,
				...slideNumbers.map((index) => {
					const history = playerEntry?.history?.[index];
					if (history && history.correctness === 1 && isFinite(history.time)) {
						return `${history.time}ms`;
					}
					return "";
				}),
			])
		);

		const csv = rows.map((row) => row.map((str) => `"${str}"`).join(",")).join("\n");

		const blob = new Blob([csv], {
			type: "text/csv",
		});
		const link = document.createElement("a");
		const url = URL.createObjectURL(blob);
		link.setAttribute("href", url);
		link.setAttribute("download", `quiz.com results for ${quiz.name}.csv`);
		link.click();
		link.remove();
	}, [report, slideNumbers, quiz.name]);

	const onClickPrint = useCallback(() => {
		// Firefox prints the background color by default, so we need to change it to white
		const backgroundColor = document.body.style.backgroundColor;
		document.body.style.backgroundColor = "#ffffff";
		window.print();
		document.body.style.backgroundColor = backgroundColor;
	}, []);

	return (
		report && (
			<div className={tailwindCascade("md:container mx-auto w-full", className)}>
				<div className="w-full overflow-x-auto font-sans text-base font-bold text-white">
					<table
						className="border-spacing-0 whitespace-nowrap text-white border-separate p-8 rounded-xl mx-auto"
						style={TABLE_STYLE}
					>
						<thead>
							<tr>
								<td colSpan={3 + slideNumbers.length}>
									<div className="h-[1px] bg-black-70 w-full mb-2" />
								</td>
							</tr>
							<tr>
								<td>
									<div className="flex flex-row items-center justify-start w-full pl-2 pr-4 text-white text-opacity-50">
										{trans("Player name")}
									</div>
								</td>
								<td>
									<div className="flex flex-row items-center justify-start w-full pr-4 text-white text-opacity-50">
										{trans("Score")}
									</div>
								</td>
								<td>
									<div className="flex flex-row items-center justify-start w-full pr-4 text-white text-opacity-50">
										{trans("Accuracy")}
									</div>
								</td>
								{slideNumbers.map((index) => (
									<td key={index}>
										<div
											className={tailwindCascade(
												"flex flex-row items-center justify-center w-full pr-4 text-white text-opacity-50",
												{
													"pr-2": index === slideNumbers.length - 1,
												}
											)}
										>
											{trans("Q%d", index + 1)}
										</div>
									</td>
								))}
							</tr>
						</thead>
						{report.map((entry, index) => (
							<tbody key={index}>
								<tr>
									<td colSpan={3 + slideNumbers.length}>
										<div className="h-[1px] bg-black-70 w-full my-2" />
									</td>
								</tr>
								<tr>
									<td>
										<div className="flex flex-row items-center justify-start w-full gap-2 pb-6 pl-2 pr-4">
											{entry.avatar && (
												<div className="w-7 h-7 shrink-0 grow-0 bg-petrol-light border-1 relative overflow-hidden border-black rounded-full">
													<Avatar
														className="absolute top-0 left-0 w-full h-full"
														playerAvatar={entry.avatar}
														showShuffle={false}
														showName={false}
														showPoints={false}
														border={false}
														disableBackground={true}
													/>
												</div>
											)}
											<div>{entry.name}</div>
										</div>
									</td>
									<td>
										<div className=" flex flex-row items-center justify-start w-full pb-6 pr-4">
											{entry.score}
										</div>
									</td>
									<td>
										<div className="flex items-center justify-center w-full gap-2 pb-6 pr-4">
											<div className="shrink-0 grow-0 relative w-6 h-6">
												<CheckIcon className="text-opacity-70 w-full h-full text-white" />
											</div>
											<div className="text-opacity-70 font-normal text-white">
												{trans("%d%%", Math.round(100 * entry.accuracy))}
											</div>
										</div>
									</td>
									{slideNumbers.map((index) => (
										<td key={index}>
											<div
												className={tailwindCascade(
													"flex items-center justify-center w-full pr-4",
													{
														"pr-2": index === slideNumbers.length - 1,
													}
												)}
											>
												<CorrectnessView
													correctness={entry.history[index]?.correctness}
													time={entry.history[index]?.time}
												/>
											</div>
										</td>
									))}
								</tr>
							</tbody>
						))}
						<tfoot>
							<tr>
								<td colSpan={3 + slideNumbers.length}>
									<div className="h-[1px] bg-black-70 w-full my-2" />
								</td>
							</tr>
							<tr>
								<td colSpan={3 + slideNumbers.length}>
									<div className="flex flex-row items-center justify-center w-full">
										<div className="flex flex-col items-start justify-center flex-1 pl-2">
											<button
												className="block font-sans text-base font-bold text-white text-opacity-50 underline"
												onClick={onClickDownload}
											>
												{trans("Download CSV")}
											</button>
										</div>
										<div className="flex flex-col items-end justify-center flex-1 pr-2">
											<button
												className="block font-sans text-base font-bold text-white text-opacity-50 underline"
												onClick={onClickPrint}
											>
												{trans("Print")}
											</button>
										</div>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
					<PritableReport slideNumbers={slideNumbers} report={report} quiz={quiz} />
				</div>
			</div>
		)
	);
}

function CorrectnessView({ correctness, time, ...props }) {
	return (
		<div className="flex flex-col items-center justify-start h-full gap-2">
			{correctness === 0 || correctness === undefined ? (
				<>
					<WrongIcon className="w-8 h-8 text-white text-opacity-25" />
					<div className="text-sm text-white text-opacity-50">
						{correctness === 0 ? `${(time * 0.001).toFixed(TIME_PRECISION)}s` : "\xa0"}
					</div>
				</>
			) : (
				<>
					<CorrectIcon className="text-green-lighter w-8 h-8" />
					<div className="text-sm text-white text-opacity-50">{(time * 0.001).toFixed(TIME_PRECISION)}s</div>
				</>
			)}
		</div>
	);
}

function PritableReport({ quiz, report, slideNumbers }) {
	return useMemo(() => {
		const accuracyTotal = report.reduce((accuracy, entry) => accuracy + entry.accuracy, 0);
		const averageAccuracy = accuracyTotal / report.length;

		const questions = quiz?.slides?.map((slide) => slide.question);

		return createPortal(
			<div className="print:block absolute top-0 hidden font-sans font-black text-black bg-[#fff]">
				<h1 className="text-5xl">{quiz.name}</h1>
				<div className="flex flex-row items-center justify-start gap-8 text-xl">
					<h2 className="grow-0 whitespace-nowrap">{new Date().toLocaleString()}</h2>
					<h2 className="grow-0 whitespace-nowrap">
						{slideNumbers.length === 1
							? trans("%d question", 1)
							: trans("%d questions", slideNumbers.length)}
					</h2>
					<h2 className="grow-0 whitespace-nowrap">
						{report.length === 1 ? trans("%d player", 1) : trans("%d players", report.length)}
					</h2>
					<h2 className="grow-0 whitespace-nowrap">
						{trans("%d%% average accuracy", Math.round(averageAccuracy * 100))}
					</h2>
				</div>
				<table className="mt-8 border-collapse">
					<thead>
						<tr className="text-xl font-normal">
							<td className="whitespace-nowrap py-4 pr-4">{trans("Name")}</td>
							<td className="whitespace-nowrap p-4 text-center">{trans("Score")}</td>
							<td className="whitespace-nowrap p-4 text-center">{trans("Accuracy")}</td>
							{slideNumbers.map((index) => (
								<td key={index} className="whitespace-nowrap p-4 text-center">
									{trans("Q%d", index + 1)}
								</td>
							))}
						</tr>
					</thead>
					<tbody>
						{report.map((entry, i) => (
							<tr
								className={tailwindCascade("text-2xl leading-none", {
									"bg-[#e5e5e5]": i % 2 === 0,
								})}
								key={i}
							>
								<td className="whitespace-nowrap p-4">{entry.name}</td>
								<td className="whitespace-nowrap p-4 text-center">{entry.score}</td>
								<td className="whitespace-nowrap p-4 text-center">
									{Math.round(100 * entry.accuracy)}%
								</td>
								{slideNumbers.map((index) => (
									<td key={index} className="pt-6 text-base text-center">
										<div className="flex flex-col items-center justify-start gap-0">
											{entry?.history?.[index]?.correctness === undefined ? (
												<>
													<PrintWrongIcon
														className={tailwindCascade("w-[1.5rem] h-[1.5rem]", {
															"text-[#cecece]": i % 2 === 0,
															"text-[#ebebeb]": i % 2 !== 0,
														})}
													/>
													<div>&nbsp;</div>
												</>
											) : entry?.history?.[index]?.correctness === 0 ? (
												<>
													<PrintWrongIcon
														className={tailwindCascade("w-[1.5rem] h-[1.5rem]", {
															"text-[#cecece]": i % 2 === 0,
															"text-[#ebebeb]": i % 2 !== 0,
														})}
													/>
													<div
														className={tailwindCascade("font-normal whitespace-nowrap", {
															"text-[#cecece]": i % 2 === 0,
															"text-[#ebebeb]": i % 2 !== 0,
														})}
													>
														{(entry?.history?.[index]?.time * 0.001).toFixed(
															TIME_PRECISION
														)}
														s
													</div>
												</>
											) : (
												<>
													<PrintCorrectIcon className="w-[1.5rem] h-[1.5rem]" />
													<div className="whitespace-nowrap font-normal">
														{(entry?.history?.[index]?.time * 0.001).toFixed(
															TIME_PRECISION
														)}
														s
													</div>
												</>
											)}
										</div>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
				<div className="mt-8 text-sm">
					{questions.map((question, index) => (
						<span key={index} className="pr-4 font-normal">
							<span className="pr-1 font-bold">{trans("Q%d", index + 1)}</span>
							{question}
						</span>
					))}
				</div>
			</div>,
			document.getElementById("__next")
		);
	}, [quiz.name, quiz?.slides, report, slideNumbers]);
}
