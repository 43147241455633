import React, { useRef, useEffect, useState, useCallback, useMemo, forwardRef } from "react";
import Modal from "@/components/Modal";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import uniq from "lodash/uniq";
import useRefMounted from "@/hooks/useRefMounted";
import { RateFragments } from "./RateFragments";

export default function RateModal({ canRate, ratings, setRating, quizzes, showRateFragment = false }) {
	const [, mountedRef] = useRefMounted();

	const allQuizzesRated = useMemo(
		() =>
			isObject(ratings) &&
			isArray(quizzes) &&
			quizzes.map(({ id }) => id).every((quizId) => Object.prototype.hasOwnProperty.call(ratings, quizId)),
		[quizzes, ratings]
	);

	const [hideRateFragment, setHideRateFragment] = useState(false);
	useEffect(() => {
		if (allQuizzesRated) {
			sleep(1000).then(() => {
				if (mountedRef.current) {
					setHideRateFragment(true);
				}
			});
		}
	}, [allQuizzesRated, mountedRef]);

	showRateFragment = showRateFragment && canRate && !hideRateFragment;

	function sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	const onCancelRate = useCallback(() => void setHideRateFragment(true), []);

	if (setRating && showRateFragment && isArray(quizzes) && quizzes.length >= 1) {
		return (
			<Modal className="md:p-4 z-60 p-0">
				<RateFragments onCancel={onCancelRate} quizzes={quizzes} ratings={ratings} setRating={setRating} />
			</Modal>
		);
	} else {
		return <></>;
	}
}
