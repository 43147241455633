import create from "zustand";
import { persist } from "zustand/middleware";
import produce from "immer";
import isArray from "lodash/isArray";
import cloneDeep from "lodash/cloneDeep";

import localStorageGuard from "@/helpers/localStorageGuard";
import isObject from "lodash/isObject";

const usePlayerStore = create(
	persist(
		(set) => {
			return {
				name: null,
				safeName: null,
				avatar: null,
				country: null,
				bannedRoomIds: {},

				updateName: (name) => set(produce((draft) => void (draft.name = name))),
				updateSafeName: (safeName) => set(produce((draft) => void (draft.safeName = safeName))),
				updateNameAndSafeName: (name, safeName) =>
					set(
						produce((draft) => {
							draft.name = name;
							draft.safeName = safeName;
						})
					),
				updateAvatar: (avatar) =>
					set(produce((draft) => void (draft.avatar = isArray(avatar) ? cloneDeep(avatar) : null))),
				updateCountry: (country) => set(produce((draft) => void (draft.country = country))),
				setBannedRoomId: (roomId) =>
					set(
						produce((draft) => {
							if (!isObject(draft.bannedRoomIds)) {
								draft.bannedRoomIds = {};
							}
							draft.bannedRoomIds[roomId] = Date.now();
						})
					),
				reset: () =>
					set(
						produce((draft) => {
							draft.name = null;
							draft.safeName = null;
							draft.avatar = null;
							draft.country = null;
							draft.bannedRoomIds = {};
						})
					),
			};
		},
		{
			name: "player",
			getStorage: () => localStorageGuard,
		}
	)
);

export default usePlayerStore;
