import Bowser from "bowser";

let isTouchDevice = false;
if (typeof window !== "undefined") {
	const browser = Bowser.getParser(window.navigator.userAgent);
	const platformType = browser.getPlatformType(true);
	isTouchDevice = platformType === "mobile" || platformType === "tablet";
}

export default isTouchDevice;
